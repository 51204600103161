<template>
  <div class="home">
    <div class="search-bar" :class="showBoxShadow && 'box-shadow'">
      <SearchBar style="width: 1200px;margin: 0 auto; height: 100px;" :licence="true" :keyword="searchKeywords" :belowKeyword="belowKeyword" />
    </div>
    <div style="background: #fff;">
      <allProductClass />
    </div>
    <div class="home-content">
      <div class="content-container">
        <!-- 兼容老数据 -->
        <Menu :data="bannerData?.props?.bannerLeft" :style="{ visibility : data ? 'visible' : 'hidden' }" />
        <Center :data="bannerData" />
        <div class="content-right">
          <User :data="bannerData?.props?.bannerRight || []" />
        </div>
      </div>
      <VPageCouplet v-if="pageCouplet" :options="pageCouplet" />
    </div>
    <VLiftNav class="v-lift-nav-ref" :options="liftNavData?.props" auto-top />
    <div class="cms-config">
      <template v-if="list.length !== 0">
        <template v-for="item in list">
          <VCategoryCard v-if="item.type== 'v-category-card'" ref="v-category-card" :key="item.id" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
          <VPoster v-if="item.type== 'v-poster'" :id="item.id" ref="v-poster" :key="item.id" class="cms-config-item" :options="item.props" :class="setMarginClass(item.props)" @load="handelLoads" />
          <vManyProduct v-if="item.type== 'v-many-product'" ref="v-many-product" :key="item.id" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
          <VProductCard v-if="item.type== 'v-product-card'" ref="v-product-card" :key="item.id" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
          <VFloor v-if="item.type== 'v-floor'" :key="item.id" ref="v-floor" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
          <vHotZone v-if="item.type== 'v-hot-zone'" :id="item.id" ref="v-hot-zone" :key="item.id" class="cms-config-item" :options="item.props" :class="setMarginClass(item.props)" @load="handelLoads" />
          <vTitle v-if="item.type== 'v-title'" :id="item.id" :key="item.id" ref="v-title" class="cms-config-item" :options="item.props" @load="handelLoads" />
          <vManyText v-if="item.type== 'v-many-text'" :id="item.id" ref="v-many-text" :key="item.id" class="cms-config-item" :options="item.props" @load="handelLoads" />
          <vTogetherGroup v-if="item.type== 'v-together-group'" ref="v-together-group" :key="item.id" :cms-id="cmsId" class="cms-config-item" :options="item" @load="handelLoads" />
          <vMarket v-if="item.type== 'v-market'" :key="item.id" ref="v-market" class="cms-config-item" :cms-id="cmsId" :options="item" @load="handelLoads" />
          <vPriceDown v-if="item.type== 'v-price-down'" :key="item.id" ref="v-price-down" :cms-id="cmsId" class="cms-config-item" :options="item" @load="handelLoads" />
        </template>
      </template>
      <template v-else>
        <div class="cms-config-empty">
          <div class="cms-config-wrap">
            <img src="@/assets/images/home/empty.png">
            <p>暂无相关内容</p>
          </div>
        </div>
      </template>
    </div>
    <!-- upLoading -->
    <div v-if="upLoading" class="up-loading">
      <div class="up-loading-content">
        <Spin fix>
          <Icon type="ios-loading" size="30" class="demo-spin-icon-load" />
        </Spin>
      </div>
    </div>
    <div v-if="showFooter" class="home-footer">
      <ShopDescription />
      <HomeHelper />
    </div>
    <CheckQualifications ref="checkQualification" />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHelper from '@/components/home/HomeHelper'
import ShopDescription from '@/components/home/ShopDescription'
import { getDecorateData } from '@/common/service/home'
import SearchBar from '@/components/SearchBar.vue'
import Menu from '@/views/home/coms/menu.vue'
import Center from '@/views/home/coms/center.vue'
import User from '@/views/home/coms/user.vue'
import VCategoryCard from './coms/vCategoryCard/index.vue' // 分类卡片
import VPoster from './coms/vPoster/index.vue' // 海报
import vManyProduct from './coms/vManyProduct/index.vue' // 多商品
import VPageCouplet from './coms/vPageCouplet/index.vue' // 页联
import VLiftNav from './coms/vLiftNav/index.vue' // 电梯导航
import VProductCard from './coms/vProductCard/index.vue' // 电梯导航
import VFloor from './coms/vFloor/index.vue' // 楼层
import vHotZone from './coms/vHotZone/index.vue' // 热区
import vTitle from './coms/vTitle/index.vue' // 标题
import vManyText from './coms/vManyText/index.vue' // 多行文本
import allProductClass from './coms/allProductClass.vue' // 全部商品分类
import vTogetherGroup from './coms/vTogetherGroup/index.vue' // 拼团活动
import vMarket from './coms/vMarket/index.vue' // 拼团活动
import vPriceDown from './coms/vPriceDown/index.vue' // 润划算活动
import CmsDialogMixin from '@/common/mixins/cmsDialog'
import CheckQualifications from './coms/checkQualifications'
import { getToken, getStorageSync, removeStorageSync } from '@/common/utils'
import { getPollingTime } from '@/common/utils/aptitudePolling'
import _ from 'lodash'
import { Spin } from 'view-design'

const loadingKey = [ // CMS动态组件枚举,新增注意添加
  'v-category-card',
  'v-poster',
  'v-many-product',
  'v-product-card',
  'v-floor',
  'v-hot-zone',
  'v-title',
  'v-many-text',
  'v-together-group',
  'v-market',
  'v-price-down'
]
export default {
  name: 'Home',
  components: {
    HomeHelper,
    ShopDescription,
    SearchBar,
    Menu,
    Center,
    CheckQualifications,
    allProductClass,
    User, VCategoryCard, VPoster, vManyProduct, VPageCouplet, VLiftNav, VProductCard, VFloor, vHotZone, vTitle, vManyText,
    vTogetherGroup, vMarket, vPriceDown,
    Spin
  },
  mixins: [CmsDialogMixin],
  data() {
    return {
      data: null,
      cmsId: '',
      cmsDialogId: '', //  装修id，给cms dialog使用
      dialogCode: 'app_home', // 给dialoa使用
      showBoxShadow: false,
      showFooter: false,
      list: [],
      compareList: [],
      upLoading: false
    }
  },
  computed: {
    bannerData() {
      const bannerData = this.data?.items?.find((item) => item.type === 'v-banner')
      return bannerData
    },
    refresh() { return this.$store.state.global.refreshHome },
    liftNavData() { // 电梯导航
      return this.data?.items?.find((item) => item.type === 'v-lift-nav')
    },
    pageCouplet() {
      return this.data?.items?.find((item) => item.type === 'v-page-couplet')?.props
    },
    // cms配置的搜索框数据
    searchKeywords() {
      const searchData = this.data?.items?.find((item) => item.type === 'v-search')?.props
      if (searchData) {
        // eslint-disable-next-line eqeqeq
        if (searchData.styleType == '1') {
          return searchData.searchPlaceholder
        // eslint-disable-next-line eqeqeq
        } else if (searchData.styleType == '2') {
          if (searchData?.keywords?.length > 1) {
            return searchData.keywords
          } else if (searchData?.keywords?.length === 1) {
            return searchData.keywords.at(0)
          }
          return ''
        }
      }
      return ''
    },
    belowKeyword() {
      const searchData = this.data?.items?.find((item) => item.type === 'v-search')?.props
      return searchData?.belowKeyword
    },
    counte() {
      return this.data?.items || []
    }
  },
  watch: {
    refresh() {
      // 执行首页更新回调
      this.getData()
    }
  },
  mounted() {
    this.showBoxShadow = false
    setTimeout(() => {
      this.$bus.$emit('setFooterBar', false)
    }, 100)
  },
  created() {
    this.getData()
    window.addEventListener('scroll', _.throttle(this.scrollHandler, 200))
  },
  destroyed() {
    this.loadPageNum = 0
    window.removeEventListener('scroll', _.throttle(this.scrollHandler, 10))
  },
  methods: {
    // CMS数据请求完毕回调
    handelLoads(row) {
      const rowKey = row.type || ''
      for (let i = 0; i < this.compareList.length; i++) {
        const key = this.compareList[i]
        if (key === rowKey) {
          this.compareList.splice(i, 1)
        }
      }
      console.log(this.compareList)
      if (this.compareList.length === 0) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.upLoading = false
            setTimeout(() => {
              this.screeLoadStart = false
              // 处理弱网情况滚动条直接卡死问题
              const top = document.documentElement.scrollTop
              const countTop = document.body.scrollHeight - document.body.clientHeight - top
              if (countTop <= 10 && this.loadPageNum < this.data?.items?.length) {
                console.log(countTop, 'bc')
                this.screenLoad()
              }
              // 显示页尾
              if (this.loadPageNum >= this.data?.items?.length) {
                this.handelShowFooter()
              }
            }, 100)
          }, 1000)
        })
      }
    },
    async getData() {
      const { data } = await getDecorateData()
      if (data?.items) {
        data.items = data.items.filter(item => item.type != 'children_position')
      }
      this.data = data
      this.cmsId = (data && data.id || '').toString()
      this.cmsDialogId = this.cmsId
      if (getToken()) {
        setTimeout(() => {
          // 如果等于1,代表从登陆页过来的，需要调用校验首页资质过期接口
          if (getStorageSync('isLoginCome') === 1) {
            this.$refs.checkQualification.check()
            removeStorageSync('isLoginCome')
          } else {
            getPollingTime()
          }
        }, 100)
      }
      const items = data.items || []
      const types = []
      for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const type = item.type
        types.push(type)
      }
      this.screenLoad(5)
    },
    // 根据边距设置样式
    setMarginClass(data) {
      let classStr = ''
      const marginType = data.marginType || []
      if (marginType.includes('1')) {
        classStr += ' no-top'
      }
      if (marginType.includes('2')) {
        classStr += ' no-bottom'
      }
      return classStr
    },
    scrollHandler(e) {
      const top = e.target?.scrollingElement?.scrollTop || 0
      if (top > 32) {
        this.showBoxShadow = true
      } else {
        this.showBoxShadow = false
      }
      const start = document.body.scrollHeight - document.body.clientHeight - top
      console.log(start)
      if (document.body.scrollHeight - document.body.clientHeight - top < 547 - 20 && !this.screeLoadStart && this.loadPageNum < this.data?.items?.length) {
        this.screenLoad()
      }
    },
    handelShowFooter() {
      // 显示底部footer
      this.showFooter = true
      setTimeout(() => {
        this.$bus.$emit('setFooterBar', true)
      }, 100)

      // 关闭loading
      this.upLoading = false
    },
    screenLoad(scNum) {
      if (this.screeLoadStart) return
      this.screeLoadStart = true
      this.loadPageNum ??= 0
      this.upLoading = true
      // 初始化传5加载五个覆盖固定版位
      scNum = scNum || 4
      if (this.loadPageNum < this.data?.items?.length) {
        const loadList = (this.data?.items?.slice(this.loadPageNum, this.loadPageNum + scNum) || [])
        console.log(loadList)
        this.list.push(...loadList)
        const compareList = []
        for (let i = 0; i < loadList.length; i++) {
          const item = loadList[i]
          if (loadingKey.includes(item.type)) {
            compareList.push(item.type)
          }
        }
        if (compareList.length === 0) {
          // 没有配置CMS情况
          this.handelShowFooter()
          this.list = []
        }
        // const getNodes = (ids) => {
        //   for (let i = 0; i < ids.length; i++) {
        //     const id = `#nav${ids[i]}`
        //     const node = document.querySelector(id)
        //     if (!node) {
        //       setTimeout(() => {
        //         getNodes(ids)
        //       }, 1)
        //       break
        //     }
        //     node.style.display = 'none'
        //   }
        // }
        // getNodes(ids)
        this.compareList = compareList
        this.loadPageNum = this.loadPageNum + scNum
      }
    }
  }
}
</script>
<style scoped lang="less">
.home-footer {
  background: #fff;
}
.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
  // position: sticky;
  // z-index: 3;
  // top: 36px
  &.box-shadow {
    box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
}
.home-content {
  margin: 0 auto 16px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 0 15px 0px 15px;
  overflow: hidden;
  position: relative;
  .content-container {
    display: flex;
    // align-items: center;
    // justify-content: center;
    width: 1200px;
    // background: #F1F1F1;
    border-radius: 8px;
    margin-top: 16px;
    // height: 552px;
    // padding: 16px;
  }
}
.content-right {
  width: 260px;
  height: max-content;
  // margin-top: 38px;
}
.cms-config {
  position: relative;
  overflow: hidden;
  clear: both;
  font-size: 0;
  padding: 0 calc((100% - 1920px) / 2 + 100px) 32px;
}
.cms-config-item {
  display: inline-block;
  vertical-align: middle;
  min-width: 1200px;
  margin: 16px 0 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  &:first-child {
    margin-top: 0;
  }
  &.no-top {
    margin-top: 0;
  }
  &.no-bottom {
    margin-bottom: 0;
  }
}
.cms-config-empty {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .cms-config-wrap {
    width: 216px;
    height: 216px;
    position: relative;
  }
  img {
    width: 216px;
    height: 216px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
}
// .v-lift-nav-wrap {
  // position: absolute;
  // left: 0;
  // top: 0;
  // width: 100%;
  // height: 100%;
  .v-lift-nav-ref {
    //position: sticky;
    //bottom: 0;
    //right: 20px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 20px;
    margin:auto;
    height: fit-content;
  }
.up-loading {
  width: 100%;
  .up-loading-content {
    margin: 0 auto;
    width: 100px;
    height: 200px;
    position: relative;
    text-align: center;
    font-weight: 600;
  }
}
.demo-spin-col .circular {
        width:25px;
        height:25px;
    }
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    ::v-deep .ivu-spin-fix {
      background: none;
    }
</style>
