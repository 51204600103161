<template>
  <div class="product-item" :class="[size]" @mouseenter="enter" @mouseleave="leave">
    <!-- 异常状态遮罩-->
    <div v-if="maskTip" class="mask" @click.stop="jumpDetail">
      <div class="tip-round">
        <div class="tip">
          <span>{{ maskTip }}</span>
        </div>
      </div>
    </div>
    <!-- 售罄 -->
    <!-- showOutOfStockTip -->
    <div v-if="showOutOfStockTip" class="mask" @click.stop="jumpDetail">
      <div class="tip-round">
        <div class="tip">
          <span>售罄</span>
        </div>
      </div>
    </div>
    <!-- 有效期-->
    <div v-if="expiredDate && !goodsItem.showExpirationWarn" class="expire">有效期至{{ expiredDate }}</div>
    <div v-if="expiredDate && goodsItem.showExpirationWarn" class="nearly-time-label">
      <Icon class="icon" :size="16" color="#F99D33" type="ios-information-circle" />
      <!-- <span class="text">近效期{{ goodsItem.nearEffectDate }}</span> -->
      <span class="text">近效期{{ expiredDate }}</span>
    </div>
    <div class="cover" @click.stop="jumpDetail">
      <Events :row="goodsItem" :shop="shop"> <g-img class="cover" :src="cover" :lazy="true" /></Events>
    </div>
    <Price :goods-item="goodsItem" :is-gift-good="isGiftGood" style="margin-top: 8px" />
    <div class="product-name" :title="productNamePrefix + productName">
      <span
        v-if="!shop && goodsItem.historyLabel && (goodsItem.historyLabel.orderCount > 0 || goodsItem.historyLabel.orgBuyFlag == '1' || goodsItem.historyLabel.orgBuyRelFlag == '1' || goodsItem.historyLabel.orgCollectFlag == '1')"
        class="history-label">
        {{ goodsItem.historyLabel.orderCount > 0 && goodsItem.historyLabel.orderCount < 100 ?
          `买过${goodsItem.historyLabel.orderCount}次` : goodsItem.historyLabel.orderCount > 99 ? '买过99+次' :
            goodsItem.historyLabel.orgBuyFlag == '1' ? '买过的店' : goodsItem.historyLabel.orgBuyRelFlag ? '建采商家' :
              goodsItem.historyLabel.orgCollectFlag ? '关注的店' : ''}}</span>
      <span v-if="shop && goodsItem.historyLabel && goodsItem.historyLabel.orderCount > 0" class="history-label">
        {{ goodsItem.historyLabel.orderCount > 0 && goodsItem.historyLabel.orderCount < 100 ?
          `买过${goodsItem.historyLabel.orderCount}次` : goodsItem.historyLabel.orderCount > 99 ? '买过99+次' : ''}}</span>
      <template v-if="showPlatformTag">
        <img v-if="goodsItem.isColdChain" class="platform-tag" src="@/assets/images/global/cold-chain.png" alt="">
        <img v-if="goodsItem.prescriptionType==0" class="platform-tag" src="@/assets/images/global/rx.png" alt="">
      </template>
      <span v-if="productNamePrefix">{{ productNamePrefix }}</span>
      <span>{{ activityType ? activityType + '|' + productName : productName }}</span>
    </div>
    <!-- 商品标签 -->
    <div v-if="!($route.path == '/' || $route.path == '/home' || $route.path == '/shopHome')">
      <div class="label-box">
        <div class="label-item" v-for="(item, index) in goodsItem?.frontDisplayLabel" :key="index">
          {{ item }}
          <span v-if="index !== goodsItem?.frontDisplayLabel.length - 1">
            <svg xmlns="http://www.w3.org/2000/svg" width="1" height="10" viewBox="0 0 1 10" fill="none">
              <line x1="0.25" y1="1.09278e-08" x2="0.25" y2="10" stroke="#A0703C" stroke-opacity="0.3"
                stroke-width="0.5"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div v-if="isGiftGood" class="product-space">规格：{{ goodsItem.unitSpec }}</div>
    <template v-else>
      <div class="company-name" :title="goodsItem.manufactureCompany">
        {{ goodsItem.manufactureCompany }}
      </div>

      <!--商品活动标签-->
      <!--      <div class="tag-box">-->
      <!--        <ActivityTags-->
      <!--          v-if="showTag"-->
      <!--          :data="goodsItem"-->
      <!--          :activity-type="productActivityType"-->
      <!--          @changeStatus="changeStatus"-->
      <!--        />-->
      <!--      </div>-->
      <ActivityTags :data="goodsItem" @changeStatus="changeStatus" />
    </template>
    <!-- 收藏后降价 -->
    <slot name="collection" />
    <div v-if="showBusiness" class="shop-name">
      {{ shopName }}
    </div>
    <div v-if="isGiftGood && goodsItem.num" class="buy-count">已加购{{ goodsItem.num }}件</div>
    <div class="add-card-box" :class="{ show: flag && !isEdit && showAddCar }">
      <AddCarDiaLog :key="0" :show-popup="flag" :good-item="shoppingData" :total-num="totalNum" :gift-obj="giftObj"
        @computedNum="computedNum" @goDetail="jumpDetail" />
    </div>
    <div v-show="isControl" :key="1" class="prd-control">{{ titMsg }}</div>
    <div
      v-if="!errorState && !isControl && goodsItem?.marketing && [6].includes(goodsItem?.marketing?.activityType) && showAddCar"
      class="show-activity-pop">
      <GroupBuyPop v-if="goodsItem?.marketing?.activityType === 6" ref="groupBuyPop" :data="goodsItem"
        :product-name="productName" @goDetail="jumpDetail" />
    </div>
  </div>
</template>
<script>
import giftDefaultCover from '@/assets/product/gift_default.png'
import productDefaultCover from '@/assets/images/common/good_default.png'
import { ACTIVITY_TYPE, CONTROL_MSG, debounce, getFilePath } from '@/common/utils'
import GImg from '@/components/GImg.vue'
import Price from '@/views/goodsItem/price/index.vue'
import ActivityTags from './activityTag.vue'
// import ActivityTags from '@/views/goodsItem/tages/index.vue'
import AddCarDiaLog from '@/views/goodsItem/AddCarDiaLog/index.vue'
import { prdControlForClient } from '@/common/service'
import GroupBuyPop from '@/components/ProductCards/GroupBuyPop.vue'
import Events from './Events.vue'

export default {
  components: { GroupBuyPop, AddCarDiaLog, ActivityTags, GImg, Price, Events },
  props: {
    border: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal'
    },
    goodsItem: {
      required: true,
      type: Object,
      default: () => { }
    },
    showBusiness: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    showAddCar: {
      // 是否显示加入购物车
      type: Boolean,
      default: true
    },
    totalNum: {
      type: Number,
      default: 0
    },
    giftObj: {
      type: Object,
      default: () => { }
    },
    card: {
      type: Boolean,
      default: false
    },
    activityCode: {
      type: String,
      default: ''
    },
    activityId: {
      type: String,
      default: ''
    },
    activityType: {
      type: String,
      default: ''
    },
    disAdd: {
      type: String,
      default: ''
    },
    click: {
      type: Function,
      default: () => null
    },
    shop: { // 商家首页 选择全部商品变量
      type: Boolean,
      default: false
    },
    showPlatformTag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showActivityPop: false,
      countTimeStatus: null,
      flag: false,
      shoppingData: {},
      isControl: false,
      titMsg: '',
      isInster: '',
      isControlState: true, // 是否冻结
      isCertState: true, // 是否资质到期
      ACTIVITY_TYPE,
      // 控销信息缓存
      reasonCache: null
    }
  },
  computed: {
    expiredDate() {
      const { goodsItem } = this
      // console.log(goodsItem)
      // 近效期活动 返回过期时间
      if (+goodsItem?.marketing?.activityType === 5 && goodsItem?.marketing?.nearEffectDate) {
        return goodsItem?.marketing?.nearEffectDate
      }
      // 普通商品 返回过期时间
      return goodsItem?.nearEffectDate || ''
    },
    cover() {
      const { fileId, picture, mainPicUrl } = this.goodsItem
      const path = fileId || picture || mainPicUrl
      if (path) {
        return getFilePath('PS_01', path)
      }
      return this.isGiftGood ? giftDefaultCover : productDefaultCover
    },
    productNamePrefix() {
      const { marketing, productUnit } = this.goodsItem
      if (marketing?.activityType === 6) {
        return `${marketing.minPurchaseAmountIdentity}${productUnit}包邮`
      }
      return ''
    },
    // eslint-disable-next-line vue/return-in-computed-property
    productName() {
      const product = this.goodsItem
      if (!product) {
        return
      }
      const { nameDisplayType, brandName, generalChineseName, unitSpec, productName } = product

      if (nameDisplayType + '' === '1') {
        // 品牌名+' '+商品名+' '+通用名+包装规格
        return `${brandName || ''} ${productName || ''} ${generalChineseName} ${unitSpec}`
      }
      if (nameDisplayType + '' === '0') {
        return generalChineseName || productName
      }
      if (this.isGiftGood) {
        return productName
      }
      return generalChineseName
    },
    shopName() {
      const { goodsItem } = this
      return this.isGiftGood ? goodsItem.manufactureCompany : goodsItem.orgAbbr
    },
    showTag() {
      const routeActivityType = this.$route.query.activityType
      const superimposedActivity = this.goodsItem.superimposedActivity
      return !(routeActivityType === '99' || (routeActivityType === '98' && !superimposedActivity))
    },
    productActivityType() {
      return this.$route.query.activityType || this.goodsItem?.marketing?.activityType
    },
    maskTip() {
      const { goodsItem } = this
      if (goodsItem.collect || (goodsItem.invalidGoods && goodsItem.invalidGoods.includes(goodsItem.id)) || goodsItem.flag) {
        return '失效'
      }
      return ''
    },
    showOutOfStockTip() {
      const { goodsItem } = this
      let flag = false
      if (goodsItem.availableStock === 0 || goodsItem.state === 0) {
        flag = true
      }
      return flag
    },
    errorState() {
      const { controlState, certState, custState } = this.goodsItem
      if (controlState === 'frozen') return '解冻后可见'
      if (certState === 'expire') return '更新资质后可见'
      if (custState !== 'auth') return '认证后可见'
      return ''
    },
    isGiftGood() {
      if (!this.giftObj) return false
      // 是否是赠品
      const { activityType, gift } = this.giftObj
      return gift && [ACTIVITY_TYPE.GIFT_MANUAL, ACTIVITY_TYPE.GIFT_AUTO].includes(activityType)
    }
  },
  methods: {
    // 1活动预热前 2活动预热中 3活动进行中 4活动结束
    changeStatus(status) {
      this.countTimeStatus = status
    },
    // 跳转商品详情
    jumpDetail() {
      const goodsItem = this.goodsItem
      if (goodsItem.collect) {
        this.$message.warning('活动已结束或商品下架啦~')
        return
      }
      if (this.isEdit || goodsItem.flag) return
      // if (this.maskTip) return
      if (this.isGiftGood) {
        const { giftObj } = this
        const query = {
          activityId: giftObj.activityId,
          activityType: giftObj.activityType,
          id: goodsItem.productId
        }
        const routeData = this.$router.resolve({ path: '/giftDetail', query })
        window.open(routeData.href, '_blank')
        return
      }
      const query = {
        activityId: goodsItem.activityId || 0,
        activityType: goodsItem.activityType || 0,
        productId: goodsItem.productId
      }

      if (this.$route.query.activityType === '99') {
        // 起购限购换购品 取exchangeMarketing
        query.activityId = this.goodsItem.exchangeMarketing[0].activityId
        query.isReplace = true
        query.minPurchaseAmountIdentity = this.goodsItem.exchangeMarketing[0]?.minPurchaseAmountIdentity
        query.maxPurchaseAmountIdentity = this.goodsItem.exchangeMarketing[0]?.maxPurchaseAmountIdentity
        // 一口价活动 跳转详情不携带 活动ID
      // eslint-disable-next-line eqeqeq
      } else if (this.goodsItem.marketing?.activityId && this.goodsItem.marketing?.activityType != 8) {
        // 特价
        query.activityId = this.goodsItem.marketing.activityId
        query.activityType = this.goodsItem.marketing.activityType
      }
      // 商品详情浮层组件使用缓存数据
      sessionStorage.setItem('Events_goodsItem', JSON.stringify(goodsItem))
      const routeData = this.$router.resolve({ path: '/detail', query })
      window.open(routeData.href, '_blank')
      // const id = goodsItem.productId
      // const activityId = goodsItem?.marketing?.activityId || '0'
      // const activityType = goodsItem?.marketing?.activityType || '0'
      // window.open(`/detail?productId=${id}&activityId=${activityId}&activityType=${activityType}`, '_blank')
    },
    computedNum(val) {
      this.$emit('changeVal', val, this.goodsItem)
    },
    enter: debounce(async function() {
      if (!this.showAddCar) return
      this.mouseEnter = true
      if (this.isGiftGood) {
        if (this.goodsItem.availableStock === 0) {
          return
        } else {
          this.sidebarOpen()
          return
        }
      }
      if (!this.getInster()) {
        this.isControl = true
        return
      } else if (this.disAdd === '1') {
        // this.titMsg = '被换购商品，满足活动条件后可在购物车内加购'
        // this.isControl = true
        return
      }

      // 润划算商品，起购数和限购数从marketing取
      // let startBuyNum = 0
      // if (this.goodsItem.marketing) {
      //   startBuyNum = this.goodsItem?.marketing?.minPurchaseAmountIdentity
      // } else {
      //   startBuyNum = this.goodsItem.minPurchaseAmountIdentity
      //   if (this.$route.query.activityType == 99) {
      //     startBuyNum = this.goodsItem?.exchangeMarketing?.[0]?.minPurchaseAmountIdentity
      //   }
      // }
      const obj = {
        products: [
          {
            activeId: '',
            activityType: '',
            productId: this.goodsItem.productId
            // buyNum: startBuyNum
          }
        ]
      }
      // if (this.goodsItem.marketing == 2) {
      // if (!(JSON.stringify(this.goodsItem.marketing) == "{}")) {
      if (this.goodsItem.marketing) {
        obj.products[0].activeId = this.goodsItem.marketing.activityId
        obj.products[0].activityType = this.goodsItem.marketing.activityType
      }
      const { data } = await this.getPrdControlReason(obj)
      if (!this.mouseEnter) return
      if (data?.length > 0 && data[0].reason !== 2) {
        this.isControl = true
        this.titMsg = CONTROL_MSG[data[0].reason]
      } else {
        this.sidebarOpen()
      }
    }, 10),
    getInster() {
      const { controlState, certState, custState } = this.goodsItem
      // 认证状态
      if (custState !== 'auth') {
        this.isInster = false
        this.titMsg = '认证后可见'
        return false
      } else if (controlState === 'frozen') {
        this.isControlState = false
        this.titMsg = '解冻后可见'
        return false
      } else if (certState === 'expire') {
        this.isCertState = false
        this.titMsg = '更新资质后可见'
        return false
      }
      this.isInster = true
      return true
    },
    // 获取控销信息
    async getPrdControlReason(params) {
      if (this.reasonCache !== null) {
        return Promise.resolve(this.reasonCache)
      }
      this.reasonCache = []
      return new Promise((resolve) => {
        prdControlForClient(params)
          .then((res) => {
            this.reasonCache = res
            resolve(res)
          })
          .catch(() => {
            this.reasonCache = null
          })
      })
    },
    sidebarOpen() {
      if (!this.reasonCache) {
        return
      }
      // 拼团活动不展示加购
      const { marketing } = this.goodsItem
      if (+marketing?.activityType === 6) {
        this.showActivityPop = true
        return
      }
      const item = this.goodsItem
      // 商品原价
      const currentPrice = item?.customerTypePrice?.[0]?.price || ''
      // 获取活动价
      let activityPrice = item?.marketing?.customerTypePrice?.[0]?.price || ''

      // 起购数
      let startBuyNum = item.marketing?.minPurchaseAmountIdentity || item.minPurchaseAmountIdentity || 1
      // 限购数
      let singleLimitNum = item?.marketing?.maxPurchaseAmountIdentity || item.maxPurchaseAmountIdentity
      // 加购数
      const addBuyNum = item?.marketing?.addBuyNum || item.addBuyNum || 1

      // 换购品的起购加购限购信息
      if (this.$route.query.activityType === '99') {
        startBuyNum = item?.exchangeMarketing?.[0]?.minPurchaseAmountIdentity || 1
        activityPrice = item?.exchangeMarketing?.[0]?.exchangePrice || ''
        singleLimitNum = item?.exchangeMarketing?.[0]?.maxPurchaseAmountIdentity
      }

      let displayName = item.generalChineseName || item.productName
      if (item.nameDisplayType === '1') {
        // 品牌名+' '+商品名+' '+通用名+包装规格
        displayName = `${item.brandName || ''} ${item.productName || ''} ${item.generalChineseName || ''}${item.unitSpec || ''}`
      }
      // 加购参数
      this.shoppingData = {
        orgId: item.orgId, // 商家id,
        id: item.productId, // 商品id
        activityId: this.activityId || item?.marketing?.activityId || 0, // 活动id
        activityType: this.activityType || item?.marketing?.activityType || 0, // 活动类型
        addBuyNum: addBuyNum || 1, // 每次加购数量
        startBuyNum,
        // availableStock: stock, // 库存
        singleLimitNum: singleLimitNum, // 限购数量
        isSingleLimit: item?.exchangeMarketing?.at(0)?.maxPurchaseAmountIdentity ? 1 : 0, // 是否限购 //0不限购 1限购
        currentPrice: currentPrice, //
        activityPrice: activityPrice, // 商品活动价商品原价
        brand: item?.brandName ? item.brandName : '', // 商品品牌
        displayName: displayName, // 加购弹窗展示商品名称
        generalChineseName: item.productName || item.generalChineseName, // 商品名称
        productSpec: item.unitSpec, // 包装规格
        mainPicUrl: item.picture, // 商品主图
        productUnit: item.productUnit, // 商品单位
        marketing: item.marketing,
        availableStock: item.availableStock,
        collect: item.collect || false,
        estimatePrice: item.estimatePrice, // 折后约
        packageNum: item.packageNum, // 中包装
        packageUnitNum: item.packageUnitNum, // 件包装
        allowRetail: item.allowRetail, // 是否可拆零，1是，2否
        orgName: this.shopName,
        manufactureCompany: this.goodsItem.manufactureCompany
        // expandMap:{present:true}
      }
      if (+this.activityCode === 99) {
        this.shoppingData = {
          ...this.shoppingData,
          ...{ expandMap: { present: true }}
        }
      }
      if (this.isGiftGood) {
        this.shoppingData = {
          ...this.shoppingData,
          ...{
            expandMap: { present: true },
            activityId: this.giftObj.activityId,
            activityType: this.giftObj.activityType,
            addBuyNum: item.singleExchangeNum || 1,
            startBuyNum: item.minPurchaseNum || 1,
            singleLimitNum: item.singleExchangeNum || -1,
            isSingleLimit: 0,
            currentPrice: item.price,
            activityPrice: item.activityPrice,
            mainPicUrl: item.mainPicUrl,
            num: item.num
          }
        }
      }

      this.flag = true
    },
    leave() {
      // 延迟清除弹窗
      this.showActivityPop = false
      this.mouseEnter = false
      if (this.flag) {
        this.flag = !this.flag
      }
      if (this.isControl) {
        this.isControl = !this.isControl
      }
    }
  },
  watch: {
    // 解决 输入框隐藏后
    showActivityPop(n) {
      if (!n) {
        this.$refs.groupBuyPop?.leave()
      } else {
        this.$refs.groupBuyPop?.show()
      }
    }
  }
}
</script>

<style scoped lang="less">
.product-item {
  border-radius: 8px;
  background: #fff;
  padding: 24px 16px 16px 16px;
  //box-shadow: 0 0 10px #ccc;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;

  //border: 0.5px solid var(--gray-3, #E7E7E7);
  &.border {
     border: 1px solid transparent;
  }

  &.border:hover {
    border: 1px solid var(--brand-6, #f99d33);
  }

  &.card {
    border-radius: 0;
    border: 0;
  }

  .cover {
    width: 100%;
    margin: 0 auto;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }

    overflow: hidden;
  }

  .product-name {
    width: 100%;
    color: var(--font-black-1, rgba(0, 0, 0, 0.9));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 40px;
    word-break: break-all;
    // 显示两行文字
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    span{
      vertical-align: middle;
    }
    .history-label {
      display: inline-block;
      height: 18px;
      border-radius: 4px;
      background: #FBF5E8;
      color: #A0703C;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      padding: 0 4px;
      margin-right: 4px;
    }
  }

  .cover,.mask {
    width: 180px;
    height: 180px;
  }

  &.normal {
    width: 228px;
    height: 372px;
  }

  // 四张卡片
  &.c120 {
    width: 198px;
    //height: 288px;
    border-radius: 0;

    .cover, .mask {
      width: 120px;
      height: 120px;
    }
  }

  &.c148 {
    width: 228px;
    height: 324px;
    border-radius: 0;

    .cover, .mask  {
      width: 148px;
      height: 148px;
    }
  }

  &.c149 {
    width: 234px;
    height: 324px;
    border-radius: 0;

    .cover, .mask  {
      width: 148px;
      height: 148px;
    }
  }

  &.c160 {
    width: 300px;
    height: 316px;
    border-radius: 0;

    .cover, .mask {
      width: 160px;
      height: 160px;
    }

    .product-name {
      height: 20px;
      -webkit-line-clamp: 1;
      white-space: nowrap;
    }
  }

  &.c198x292 {
    width: 198px;
    height: 292px;
    border-radius: 0;

    .cover, .mask {
      width: 120px;
      height: 120px;
    }

    :deep(.price_container) {
      .activity-price {
        line-height: 24px;
      }

      .through-price {
        line-height: 24px;
      }
    }
  }

  &.c204x292 {
    width: 204px;
    height: 292px;

    .cover, .mask  {
      width: 120px;
      height: 120px;
    }

    :deep(.price_container) {
      .activity-price {
        line-height: 24px;
      }

      .through-price {
        line-height: 24px;
      }
    }
  }

  &.c220x356 {
    width: 220px;
    height: 372px;
  }

  &.market1 {
    width: 198px;

    .cover, .mask {
      width: 120px;
      height: 120px;
    }

  }

  &.market2 {
    width: 197px;

    .cover, .mask {
      width: 120px;
      height: 120px;
    }
  }
}

.expire {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 22px 8px 0 22px;
  background: #f5f5f5;
  padding: 0 8px;
  line-height: 24px;
}

.nearly-time-label {
  position: absolute;
  right: -1px;
  top: -1px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #e67d00;
  border-radius: 22px 8px 0 22px;
  background: #fff8e8;
  padding: 0 8px;
  line-height: 24px;

  .icon {
    margin-right: 2px;
    transform: rotate(180deg);
  }
}

.product-space,
.company-name {
  color: var(--font-black-2, rgba(0, 0, 0, 0.6));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
}

.shop-name {
  padding-left: 18px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: url('../../assets/product/shop-bag.png') no-repeat left center;
  background-size: 14px 14px;
  margin-top: 6px;
}

.tag-box {
  height: 18px;
  margin-top: 2px;
}

.mask {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 24px;
  width: 180px;
  height: 180px;
  background: rgba(255, 255, 255, 0.4);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .tip-round {
    width: 48.88%;
    aspect-ratio: 1;
    height: fit-content;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tip {
    span{
      line-height: 20px;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
  }
}

.add-card-box {
  position: absolute;
  left: 0;
  bottom: -1000px;
  transition: 0.3s ease;
  background: pink;
  width: 100%;
  height: fit-content;

  &.show {
    bottom: 0;
  }
}

.prd-control {
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 52px;
  background: #fff;
  width: 100%;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  line-height: 45px;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.05);
  font-size: 12px;
}

.show-activity-pop {
  position: absolute;
  z-index: 9;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #e7e7e7;
  bottom: -300px;
  transition: 0.2s ease-in-out;
}

.product-item:hover .show-activity-pop {
  bottom: 0;
}

.label-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 16px;
  line-height: 16px;
  // flex-wrap: wrap;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 17px;
    height: 16px;
    position: absolute;
    right: 0px;
    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }

  .label-item {
    color: #a0703c;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;

    span {
      display: inline-block;
      margin: 0 3px;
      line-height: 10px;
    }
  }
}
:deep(.cover .g-img){
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}

.platform-tag{
  height: 18px;
  width: auto;
  margin-right: 4px;
  vertical-align: middle;
}
</style>
