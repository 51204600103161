<template>
  <div v-if="isShowCoupon" class="notice1">
    <div class="notice">
      <div class="list">
        <p class="item line-clamp-1" v-for="item in list" @click="detail(item.id)" :key="item.id">{{ item.noticeTitle }}
        </p>
      </div>
    </div>
  </div>
  <div v-else class="notice2">
    <div class="notice">
      <div class="notice-title">
        商城公告
        <div class="notic-icon" @click="noticListHandler">
          更多
          <Icon :size="16" type="ios-arrow-forward" />
        </div>
      </div>
      <div class="list">
        <p class="item line-clamp-1" v-for="item in list" @click="detail(item.id)" :key="item.id">{{ item.noticeTitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecent } from '@/common/service'
export default {
  name: 'notice',
  props: {
    isShowCoupon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: []
    }
  },
  components: {
  },
  created() {
    this.getRecent()
  },
  methods: {
    async getRecent() {
      const { data } = await getRecent({ size: 3 })
      this.list = data
    },
    detail(id) {
      this.$router.push("/notice/detail?id=" + id)
    },
    noticListHandler() {
      this.$router.push("/notice")
    }
  },
}
</script>
<style scoped lang="less">
.notice1 {
  .notice {
    height: 20px;
    background: #FFFFFF;
    // border-radius: 8px;
    overflow: hidden;
    margin-top: 8px;
  }

  .notice-title {
    width: 100%;
    height: 34px;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.90);

    .notic-icon {
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-left: auto;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      color: rgba(0, 0, 0, 0.60);
    }

    .ivu-icon {
      margin-top: -2px;
      color: rgba(0, 0, 0, 0.60);
    }
  }

  .list {
    padding: 0px 8px 0;

    .item {
      margin: 0 0 4px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.9);
      cursor: pointer;

      &:hover {
        color: #CE7720;
      }
    }
  }
}

.notice2 {
  .notice {
    height: 112px;
    background: #FFFFFF;
    // border-radius: 8px;
    overflow: hidden;
    margin-top: 12px;
  }

  .notice-title {
    width: 100%;
    height: 34px;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.90);

    .notic-icon {
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-left: auto;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      color: rgba(0, 0, 0, 0.60);
    }

    .ivu-icon {
      margin-top: -2px;
      color: rgba(0, 0, 0, 0.60);
    }
  }

  .list {
    padding: 8px 16px;

    .item {
      margin: 4px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.9);
      cursor: pointer;

      &:hover {
        color: #CE7720;
      }
    }
  }
}</style>
