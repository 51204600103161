import { get } from '@/common/utils'
import { api } from '@/common/service/config'

/**
 * 企业入驻
 */

export const getConfigByCode = (code) => get({
  url: `${api.baseUrl}/wx/config/getConfigByCode?code=${code}`
})

export const getHelperList = () => get({
  url: `${api.baseUrl}/wx/config/getHelpCenter?pageTypeCode=pc_help_home&clientTypeCode=pc_help`
})
