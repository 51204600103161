// 所商品
<template>
  <div class="v-together-group" v-if="list?.length > 0" :id="'nav' + options.id">
    <p class="ttitle">{{ options.props.title }}</p>
    <div class="more" @click="more">
      更多
      <Icon style="margin-left: -2px;" type="ios-arrow-forward" />
    </div>
    <div class="list">
      <div v-for="(item, index) in list" class="item">
        <img :src="formatPath(item) || defaultPath" alt="" class="img">
        <div class="content">
          <div class="pieces-num">
            {{ item?.marketing?.minPurchaseAmountIdentity }}件起拼
          </div>
          <div class="price">
            <span class="activity-price">¥ {{ item.activityPrice }}</span>
            <span class="activity-unit">/{{ item.productUnit }}</span>
            <span class="sell-price">{{ item.currentPrice == null || item.currentPrice == undefined ? '未定价' : '¥' +
              item.currentPrice }}</span>
          </div>
          <p class="product-name line-clamp-2" style="color:rgba(0, 0, 0, 0.90)">{{ item.generalChineseName ||
            item.displayName }}</p>
          <div class="btn" @click="detaiHandler(item, '1')">去拼团
            <svg-icon name="arrow" class="icon"></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink, getData, detaiHandler } from '@/views/home/index'
import ContentTitle from '@/components/ContentTitle'
import ProductCard from '@/components/ProductCards/index.vue'
import { getProductByIdS, getProductByEs } from '@/common/service'
import { transformEsData } from '@/common/utils'
import defaultPath from '@/assets/images/common/good_default.png'
export default {
  name: "v-together-group",
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      cmsLink: cmsLink.bind(this),
      list: [],
      defaultPath
    };
  },
  components: {
    ContentTitle, ProductCard
  },
  created() {
    if (this.options.props.data.type == '1') {
      this.getActivityByEs()
    } else {
      this.getActivityData()
    }
  },
  methods: {
    handelLoad() {
      this.$emit('load', this.options)
    },
    more(data) {
      let query = this.options.props.data.type == '1' ? {} : {
        cmsModelId: `${this.cmsId}_${this.options.id}`,
        cmsCustom: true
      }
      this.$router.push({ path: `/activity/groupCenter`, query })
    },
    async getActivityData() {
      let { data } = await getProductByIdS({
        pageSize: 5,
        startIndex: 0,
        // searchPrescription: this.$route.name  == 'Home' || this.$route.name  == 'shopHome' ? false : true,
        cmsModelId: `${this.cmsId}_${this.options.id}`
      })
      data.records?.forEach(item => {
        if (item.custState == 'auth') {
          this.isInster = true
        } else {
          this.isInster = false
        }
        transformEsData(item)
        item._uid = item.id + item.activityId
      })
      this.list = data.records
      this.handelLoad()
    },
    async getActivityByEs() {
      const data = {
        // searchPrescription: this.$route.name  == 'Home' || this.$route.name  == 'shopHome' ? false : true,
        activityType: '6',
        page: {
          pageNumber: 1,
          pageSize: 5,
          sort: "marketing.startTime",
          order: "desc"
        },
      }
      const { data: datasource } = await getProductByEs(data)
      datasource.records?.forEach(item => {
        if (item.custState == 'auth') {
          this.isInster = true
        } else {
          this.isInster = false
        }
        transformEsData(item)
        item._uid = item.id + item.activityId
      })
      this.list = datasource.records
      this.handelLoad()
    },
    formatPath(item) {
      let fileId = item?.fileId || item?.picture || item?.mainPicUrl
      return getFilePath('PS_01', fileId)
    }
  },
  computed: {
  },
  watch: {
    list: {
      handler(val) {
        if (val.length > 0)
          this.$store.commit('setHomeNav', this.options.id)
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="less">
.v-together-group {
  width: 1200px;
  height: 374px;
  background-image: url('~@/assets/images/home/pc-together-group.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 42px;
  padding-bottom: 12px;

  .ttitle {
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    margin: 12px auto;
    text-align: center;
  }

  .list {
    display: flex;
    margin-top: 18px;
  }

  .item {
    width: 204px;
    height: 284px;
    border-radius: 8px;
    border: 2px solid #F76560;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }

    .img {
      width: 140px;
      height: 140px;
      margin-top: 8px;
      margin-bottom: 18px;
    }

    .pieces-num {
      background-image: url('~@/assets/images/home/pc-pieces-num.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 152px;
      height: 45px;
      color: #FFEFB7;
      font-size: 16px;
      font-weight: 500;
      // padding-left: 36px;
      text-align: center;
      padding-top: 15px;
      position: absolute;
      left: 18px;
      top: 125px;
    }

    .activity-price {
      color: #CB272D;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }

    .activity-unit {
      color: rgba(0, 0, 0, 0.60);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-right: 2px;
    }

    .sell-price {
      color: rgba(0, 0, 0, 0.60);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration: line-through;
    }

    .product-name {
      color: rgba(0, 0, 0, 0.90);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      height: 40px;
    }

    .btn {
      width: 98px;
      height: 36px;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      border-radius: 8px;
      border: 1px solid #CB272D;
      background: #F53F3F;
      margin-top: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 2px;
      cursor: pointer;

      &:hover {
        color: #FFF59E;
      }

      .icon {
        margin-left: 4px;
      }
    }

    .content {
      width: 172px;
    }
  }

  .more {
    border-radius: 4px;
    background: #FA3343;
    width: 48px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 20px;
    color: #FFF;
    color: #FFF;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

::v-deep .root {
  margin-top: 0;
}
</style>
