import { get, post } from '@/common/utils'
import { api } from '@/common/service/config'
/**
 * 订单列表
 */
export async function getOrderList(data, cb) {
  return post({
    url: `${api.baseUrl}/order/cust/orderList`,
    data,
    isLoading: true,
    callback(requestTask) {
      cb(requestTask)
    }
  })
}

/**
 * 订单确认收货
 */
export async function getOrderDetial(data) {
  return post({
    url: `${api.baseUrl}/order/cust/orderDetail`,
    data,
    isLoading: true
  })
}

/**
 * 取消订单
 */
export async function orderCancel(data) {
  return post({
    url: `${api.baseUrl}/order/cust/orderCancel`,
    data,
    isLoading: true
  })
}

/**
 * 订单详情
 */
export async function orderDetailApi(data) {
  return post({
    url: `${api.baseUrl}/order/cust/orderDetail`,
    data,
    isLoading: true
  })
}
/**
 * 订单待支付链接取支付状态刷新页面
 */
export async function orderQueryPayState(data) {
  return get({
    url: `${api.baseUrl}/order/cust/queryPayState`,
    data
  })
}
/**
 * 订单详情
 */
export async function getOrderFLowLogApi(data) {
  return post({
    url: `${api.baseUrl}/order/cust/orderOperList`,
    data,
    isLoading: true
  })
}
/**
 * 查看配送信息
 */
export async function getExpressInfo(data) {
  return post({
    url: `${api.baseUrl}/order/cust/getExpressInfo`,
    data,
    isLoading: true
  })
}
/**
 * 订单确认收货
 */
export async function confirmRevieceApi(data) {
  return post({
    url: `${api.baseUrl}/order/cust/receiveConfirm`,
    data,
    isLoading: true
  })
}

/**
 * 再次购买
 */
export async function buyAgainApi(orgOrderId) {
  return get({
    url: `${api.baseUrl}/order/cust/addProductAgain?orgOrderId=${orgOrderId}`,
    isLoading: true
  })
}
/**
 * 查看发票信息
 */
export async function getInvoiceInfo(data) {
  return post({
    url: `${api.baseUrl}/order/orderInvoice/getOrderInvoiceByOrderId`,
    isLoading: true,
    data
  })
}

/**
 * 提交评价
 */
export async function createCommentApi(data) {
  return post({
    url: `${api.baseUrl}/order/orderComment/createComment`,
    isLoading: true,
    data
  })
}
/**
 * 评价列表
 */
export async function getEvaluateListApi(data) {
  return post({
    url: `${api.baseUrl}/order/orderComment/list`,
    isLoading: true,
    data
  })
}

// 售后分页查询
export const getAfterList = (data) =>
  post({
    url: `${api.baseUrl}/order/orderReturn/page`,
    data,
    isLoading: true
  })

// 缺货登记分页查询
export const getOuutOfStockRegList = (data) =>
  post({
    url: `${api.baseUrl}/behavior/ubuserprdregister/list`,
    data,
    isLoading: true
  })
//   取消缺货登记
export const cancelOuutOfStockReg = (data) =>
  get({
    url: `${api.baseUrl}/behavior/ubuserprdregister/cancel`,
    data
  })

//   删除缺货登记
export const delOuutOfStockReg = (data) =>
  get({
    url: `${api.baseUrl}/behavior/ubuserprdregister/delById`,
    data
  })

// 申请退款  获取退款信息接口
export const getRefundInfo = (data) =>
  post({
    url: `${api.baseUrl}/order/orderReturn/init`,
    isLoading: true,
    data
  })

// 提交退款申请
export const submitRefundInfo = (data) =>
  post({
    url: `${api.baseUrl}/order/orderReturn/create`,
    data,
    isLoading: true
  })

// 售后单详情页面
export const getAfterSaleDetail = (id) =>
  get({
    url: `${api.baseUrl}/order/orderReturn/detail?returnId=${id}`,
    isLoading: true
  })

// 获取订单信息
export const getorderStats = () =>
  post({
    url: `${api.baseUrl}/order/cust/orderStats`
    // isLoading: true
  })

// 撤销退款
export const cancelRefundApi = (returnId) =>
  get({
    url: `${api.baseUrl}/order/orderReturn/cancel?returnId=${returnId}`,
    isLoading: true
  })

// 撤销退款
export const returnGoodsApi = (data) =>
  post({
    url: `${api.baseUrl}/order/orderReturn/returnGoods`,
    isLoading: true,
    data
  })
// 获取商家退款声明
export const getOrgIntroduceApi = (orgId) =>
  get({
    url: `${api.baseUrl}/org/info/salesReturn?orgId=${orgId}`,
    isLoading: true
  })

// 寄回退货回显信息
export const getReturnGoodsInitApi = (returnId) =>
  get({
    url: `${api.baseUrl}/order/orderReturn/returnGoodsInit?returnId=${returnId}`,
    isLoading: true
  })
// 寄回退货物流信息
export const getReturnExpressApi = (data) =>
  post({
    url: `${api.baseUrl}/order/orderReturn/getSaleAfterExpressInfo`,
    data
  })
// 获取退货地址
export const getSalesReturnInfoApi = (orgId) =>
  get({
    url: `${api.baseUrl}/org/info/salesReturn/detail?orgId=${orgId}`,
    isLoading: true
  })

// 首营资质列表接口
export const findFirstOperateList = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/findFirstOperateList`,
    data,
    isLoading: true
  })
// 首营资质详情
export const findFirstOperateDetail = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/getFirstOperateImages`,
    data,
    isLoading: true
  })
// 首营资质下载
export const downLoadFindFirstOperateList = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/downLoadFindFirstOperateList`,
    data,
    isLoading: true
  })
// 药检报告列表接口
export const findDrugReportList = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/findDrugReportList`,
    data,
    isLoading: true
  })
// 药检报告详情
export const findDrugReportDetail = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/getDrugReport`,
    data,
    isLoading: true
  })
// 药检报告下载
export const downLoadFindDrugReportList = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/downLoadFindDrugReportList`,
    data,
    isLoading: true
  })
// 下载图片
export const downloadPath = (url) =>
  get({
    url,
    config: { responseType: 'blob' }
  })

//   获取商家列表
export const getShopList = (data) =>
  post({
    url: `${api.baseUrl}/org/info/searchOrg2FileDownLoad`,
    data,
    isLoading: true
  })

//   获取/设置默认商家
export const getDefaultOrg = (data) =>
  get({
    url: `${api.baseUrl}/order/orderDownLoad/getDefaultOrg`,
    data
  })

//   获取药检报告列表
export const getDrugReportList = (data) =>
  post({
    url: `${api.baseUrl}/order/orderDownLoad/findDrugReportList`,
    data,
    isLoading: true
  })

//   获取首营资料列表
export const getFirstOperateList = (data) =>
  post({
    url: `${api.baseUrl}/order/orderDownLoad/findFirstOperateList`,
    data,
    isLoading: true
  })

//   获取电子发票列表
export const getOrderInvoiceList = (data) =>
  post({
    url: `${api.baseUrl}/order/orderDownLoad/findOrderInvoiceList`,
    data,
    isLoading: true
  })

//   下载首营资料图片
export const downLoadImg = (data) =>
  post({
    url: `${api.baseUrl}/order/orderDownLoad/downLoadImg`,
    data,
    config: { responseType: 'blob' }
  })

//   下载电子发票
export const getInvoiceBody = (data) =>
  post({
    url: `${api.baseUrl}/order/orderDownLoad/getInvoiceBody`,
    data,
    config: { responseType: 'blob' }
  })

/**
 * 多渠道订单列表
 */
export async function getMultiChannelOrderList(data, cb) {
  return post({
    url: `${api.baseUrl}/order/cust/multiChannel/queryPage`,
    data,
    isLoading: true,
    callback(requestTask) {
      cb(requestTask)
    }
  })
}

/**
 * 多渠道订单详情
 */
export async function getMultiChannelOrderDetail(data, cb) {
  return post({
    url: `${api.baseUrl}/order/cust/multiChannel/queryDetail`,
    data,
    isLoading: true,
    callback(requestTask) {
      cb(requestTask)
    }
  })
}

// 多渠道订单导出商品明细
export const exportMultiChannelOrderFile = (data) =>
  post({
    url: `${api.baseUrl}/order/cust/multiChannel/exportOrderLine`,
    data,
    config: { responseType: 'blob' }
  })

  /**
 * 多渠道订单详情
 */
export async function getAllOrderInvoiceList(data) {
  return post({
    url: `${api.baseUrl}/order/cust/multiChannel/getOrderInvoiceList`,
    data,
    isLoading: true
  })
}

  /**
 * 多渠道订单详情
 */
  export async function queryOrderLine(data) {
    return post({
      url: `${api.baseUrl}/order/cust/multiChannel/queryOrderLine`,
      data,
      isLoading: true
    })
  }

  /**
 * 缺货登记增加提醒配置
 */
  export async function remindConfigAdd(data) {
    return post({
      url: `${api.baseUrl}/behavior/remindConfig/add`,
      data,
      isLoading: true
    })
  }

    /**
 * 缺货登记增加提醒配置
 */
    export async function getRemindConfig(data) {
      return post({
        url: `${api.baseUrl}/behavior/remindConfig/get`,
        data,
        isLoading: true
      })
    }

       /**
 * 缺货登记增加提醒配置
 */
  export async function remindConfigUpdate(data) {
  return post({
    url: `${api.baseUrl}/behavior/remindConfig/update`,
    data,
    isLoading: true
  })
}