<template>
    <div class="cart-btn" @click="checkChange">
        <!-- 
            购物车按钮
            购物车角标数量显示，超过99 显示 99+
        -->
        <span class="cart-num" v-if="carTextNum> 0" :class="carTextNum< 10 ? 'indivdual' : carTextNum< 100 ? 'ten' : 'hundred'">
            <span class="num">{{ carTextNum > 99 ? '99+' :  carTextNum}}</span>
        </span>
        <img class="cart-icon" width="16px" height="16px" src="@/assets/images/common/cart.png">
        <span class="cart-text">我的购物车</span>
        <!-- <img class="icon-direction" width="6px" height="10px" src="@/assets/images/common/icon-direction.png"> -->
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'CartBtn',
    data() {
        return {
            cartData: {},
        }
    },
    computed: {
        ...mapGetters({
            carTextNum: 'car/getCarTextNum',
            disassociationState: 'disassociationState'
        })
    },
    created() {
        this.$store.dispatch('car/updateCartNum')
    },
    methods: {
        checkChange() {
            if( !this.disassociationState ) {
                this.toDisassociation()
            } else {
                this.$router.push({
                    path: '/car'
                })
            }
        },
        // 去关联
        toDisassociation() {
            this.$Modal.confirm({
                title: '提示',
                content: '<p>请先关联采购主体（药店/医院/诊所/企业）。未关联前无法获取商品全部内容以及采购下单</p>',
                okText: '去关联',
                cancelText: '暂不',
                onOk: () => {
                    this.$router.push('/affiliated')
                },
                onCancel: () => {
                // this.$message.info('Clicked cancel')
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.cart-btn {
    width: 126px;
    height: 40px;
    border-radius: 4px;
    background-color: #ffffff;
    position: relative;
    padding: 0 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #EEE;
    .icon-direction, .cart-num {
        position: absolute;
    }
    .cart-num {
        width: 28px;
        height: 15px;
        line-height: 15px;
        top: -10px;
        right: 6px;
        text-align: center;
        background-size: 100% 100%;
        .num {
            font-size: 12px;
            color: #ffffff;
        }
        &.indivdual {
            width: 16px;
            height: 16px;
            background-image: url(../assets/images/common/car-num-indivdual.png);
        }
        &.ten {
            background-image: url(../assets/images/common/car-num-ten.png);
            width: 24px;
            height: 16px;
        }
        &.hundred {
            width: 32px;
            height: 16px;   
            background-image: url(../assets/images/common/car-num-hundred.png);
        }
    }
    .cart-icon {
    }
    .cart-text {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        font-weight: 400;
        margin-left: 8px;
    }
    .icon-direction {
        top: 15px;
        right: 23px;
    }
}
</style>