import { getCartNum } from "@/common/service";
import { api } from "@/common/service/config";
import { getToken } from "@/common/utils";
import dayjs from 'dayjs'
// 更新购物车tabbar角标
export const updateCartNum = () => {
  return new Promise((resolve, reject) => {
    getCartNum()
      .then((res) => {
        // getApp().globalData.carTextNum = res?.data;
        resolve(res.data);
      })
      .catch((error) => {
        // 如果当前登录用户必要信息缺失600 用户未绑定客户提示：601
        if (
          error?.data?.code == "600" ||
          error?.data?.code == "601" ||
          error?.data?.code == "602" ||
          error?.data?.code == "603"
        ) {
          return;
        }
        // Message.error(error?.data?.message)
        reject(error);
      });
  });
};
// 防抖函数
export function debounce(func, delay) {
  let timer; // 定时器
  return function () {
    const context = this;
    const args = arguments;
    const isImmediately = !timer;
    timer ? clearTimeout(timer) : null;
    timer = setTimeout(() => {
      timer = null;
    }, delay);
    // isImmediately 为 true 则 执行函数(即首次触发事件)
    isImmediately ? func.apply(context, args) : null;
  };
}
export function  isValidDate(date){
  try {
    const curDate = new Date(dayjs(date).format('YYYY-MM-DD'))
    const isTure = curDate instanceof Date && !isNaN(curDate.getTime())
    return isTure && curDate.getTime() > new Date('1971-01-01').getTime()
  } catch (error) {
    return false
  }
}
/**
 * @desc 函数节流
 * @param func 回调函数
 * @param limit 时间限制
 */
export function throttle(func, limit) {
  let inThrottle;
  return function () {
    const context = this;
    const args = arguments;
    console.log("inThrottle", inThrottle);
    // 跳出时间限制
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      // 开启定时器计时
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}
/*
 *前端分页函数
 * 入参:
 * list(Array): 商品id, 活动id, 活动类型 的数组
 * num(Number): 每次加载几条商品
 *
 * 返回:
 * ids: 为 Array 继续加载, 为 null 数据加载完毕
 * list: 每次调用完重新给传入的数组赋值
 */
export const pagingLoad = (list, num) => {
  let ids = [];
  if (list.length > num) {
    ids = list.splice(0, num);
  } else if (list.length) {
    ids = list.splice(0, list.length);
  }
  return {
    ids,
    list,
  };
};

// 完整的URL的组成
// {协议域名路径} / {尺寸标识thumb} / {fileId}
const filePreviewHttp = api.filePreviewHttp;
export const getFilePath = (thumb, fileId) => {
  if (!fileId) return "";
  return filePreviewHttp + "/" + thumb + "/" + fileId;
};

/**
 * 上传文件
 */
const baseFileUrl = api.baseFileUrl;
export const uploadFile = ({ filePath, scene }) => {
  return new Promise((resolve, reject) => {
    uni.uploadFile({
      url: `${baseFileUrl}/upload`,
      filePath,
      name: "file",
      formData: {
        scene: scene,
      },
      header: {
        Authorization: getToken(),
      },
      success(res) {
        resolve(res);
      },
      fail(err) {
        reject(err);
      },
    });
  });
};
export function getUuid () {
  if (typeof crypto === 'object') {
    if (typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID();
    }
    if (typeof crypto.getRandomValues === 'function' && typeof Uint8Array === 'function') {
      const callback = (c) => {
        const num = Number(c);
        return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(16);
      };
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, callback);
    }
  }
  let timestamp = new Date().getTime();
  let perforNow = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let random = Math.random() * 16;
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0;
      timestamp = Math.floor(timestamp / 16);
    } else {
      random = (perforNow + random) % 16 | 0;
      perforNow = Math.floor(perforNow / 16);
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
  });
};
