<template>
  <!-- TODO: 价格格式化 检索列表 -->
  <div class="price_container">
    <!--客户异常状态显示 -->
    <div v-if="errorState" class="error-state">
      {{ errorState }}
    </div>
    <div v-else class="show_price">
      <!--      <div class="activity-price">-->
      <!--        &lt;!&ndash; 活动价 &ndash;&gt;-->
      <!--        <template v-if="goodsItem.estimatePrice">-->
      <!--          <span>折后约</span>¥ {{ returnFloat(goodsItem.estimatePrice, originPrize) }}-->
      <!--        </template>-->
      <!--        <template v-else-if="activityPrice">-->
      <!--          ¥{{ returnFloat(redPrice, originPrize) }}-->
      <!--        </template>-->
      <!--        &lt;!&ndash; 商品原价 &ndash;&gt;-->
      <!--        <template v-else-if="originPrize">-->
      <!--          ¥{{ originPrize }}-->
      <!--        </template>-->
      <!--        <div v-else class="error-state">未定价</div>-->
      <!--      </div>-->
      <div class="activity-price">
        <span v-if="goodsItem.estimatePrice">折后约</span>
        <template v-if="redPrice">
          ¥{{ redPrice }}
        </template>
        <template v-else>未定价</template>
      </div>
      <div v-if="activityPrice || goodsItem.estimatePrice" class="through-price">
        {{ originPrize ? '¥' + originPrize : '未定价' }}
      </div>
    </div>
  </div>
</template>

<script>
import { returnFloat } from '@/common/utils/format'
export default {
  name: 'PriceArea',
  components: {
  },
  props: {
    goodsItem: {
      type: Object,
      default: () => ({})
    },
    isGiftGood: {
      type: Boolean
    }
  },
  data() {
    return {
      message: '',
      returnFloat
    }
  },
  computed: {
    // 客户状态异常信息
    errorState() {
      if (this.isGiftGood) {
        return ' '
      }
      const { controlState, certState, custState } = this.goodsItem
      if (controlState === 'frozen') return '解冻后可见'
      if (certState === 'expire') return '更新资质后可见'
      if (custState !== 'auth') return '认证后可见'
      return ''
    },
    isBeiExchangeGoods() {
      return this.$route.query?.activityType === '99'
    },
    // // 换购品价格
    // exchangePrice() {
    //   return returnFloat(this.goodsItem?.exchangeMarketing?.[0]?.exchangePrice)
    // },
    // 活动价
    activityPrice() {
      // 是否是 复制类型的活动(润划算、近效期、拼团)
      // 如果是 拼团、润滑算、拼团 或者换购 显示活动价格
      if (this.isBeiExchangeGoods) {
        // 换购品价格不可能为空，添加判空 排除后端接口返回的BUG
        if (this.goodsItem?.exchangeMarketing?.[0]?.exchangePrice === null) {
          return ''
        }
        // 换购品活动价
        return this.goodsItem?.exchangeMarketing?.[0]?.exchangePrice
      }
      if ([2, 5, 6, 8].includes(this.goodsItem.marketing?.activityType)) {
        // 活动价格不可能为空，添加判空 排除后端接口返回的BUG
        if (this.goodsItem?.marketing?.customerTypePrice?.[0]?.price === null) {
          return ''
        }
        // 活动价格
        return this.goodsItem?.marketing?.customerTypePrice?.[0]?.price || ''
      }
      return ''
    },
    // 商品原价
    originPrize() {
      const customerTypePrice = this.goodsItem?.customerTypePrice ?? []
      return returnFloat(customerTypePrice?.[0]?.price ?? '')
    },
    redPrice() {
      /*
      * V1.37
      *  有预估到手价，优先展示预估到手价
      *  如果没有预估到手价
      *     如果有 活动价 没有原价 返回活动价
      *     如果有活动价和原价 取最小值返回
      *     如果没有活动价 返回原价
      * */
      const estimatePrice = this.goodsItem?.estimatePrice ?? ''
      const originPrize = this.originPrize
      const activityPrice = this.activityPrice
      //  有预估到手价，优先展示预估到手价
      if (estimatePrice) {
        return returnFloat(estimatePrice, originPrize)
      }
      /**
       * 如果有 有活动价 和原价 取最小值返回
       * */
      if (activityPrice && originPrize) {
        return returnFloat(Math.min(Number(activityPrice), Number(originPrize)), originPrize)
      }
      // 如果有活动价，没有原价 返回活动价
      if (activityPrice && !originPrize) {
        return returnFloat(activityPrice)
      }
      // 如果没有活动价，返回原价
      return returnFloat(originPrize)
    }
  }
}

</script>

<style lang="less" scoped>
.price_container {
  .show_price {
    height: 28px;
    overflow: hidden;
    .activity-price {
      vertical-align: middle;
      display: inline-block;
      height: 28px;
      white-space: nowrap;
      overflow: hidden;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #CB272D;
      margin-right: 8px;
      //text-overflow: ellipsis;
      max-width: 100%;
    }
    .through-price {
      display: inline-block;
      vertical-align: middle;
      height: 28px;
      text-decoration-line: line-through;
      font-weight: 400;
      font-size: 14px;
      //line-height: 28px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 7px;
    }
  }
}

.error-state{
  color: var(--error-7, #CB272D);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  height: 28px;
}
.estimated-price{
  border-radius: 8px;
  background: #FBEBE7;
  line-height: 24px;
  padding: 0 8px;
  color: #D4380D;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  display: inline-block;
  height: 22px;
}

.activity-price span{
  font-size: 12px;
  line-height: 28px;
  vertical-align: middle;
}
</style>
