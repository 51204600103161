// 资质证照
<template>
  <div class="licence">
    <div class="head">
      <div class="search-bar" :class="showBoxShadow && 'box-shadow'">
        <SearchBar style="width: 1200px;margin: 0 auto;" :licence="true" />
      </div>
      <div style="background: #fff;">
        <allProductClass />
      </div>
      <!-- <Tabs :list="tabList" /> -->
      <div class="breadcrumb">
        <div class="breadcrumb-warp">
          <span class="text">您所在的位置</span>
          <Breadcrumb separator=">">
            <BreadcrumbItem to="/">首页</BreadcrumbItem>
            <BreadcrumbItem to="/licence">资质证照列表</BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
    </div>
    <div class="notice-content">
      <div v-for="item in listArr" :key="item.id" class="info_item" @click="toDetail(item)">
        <span class="title">{{ item.fileShowName }}</span>
        <span class="content line-clamp-1">{{ item.noticeContent }}</span>
        <span class="time">{{ item.issueTime }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getFilePath } from '@/common/utils/base'
import { getAgreementList } from '@/common/service'
import { getPcTabData } from '@/common/service/home'
import SearchBar from '@/components/SearchBar.vue'
import Tabs from '@/components/Tabs'
import Pagination from '@/components/pagination/Pagination'
import allProductClass from './coms/allProductClass.vue' // 全部商品分类

export default {
  name: '',
  components: {
    allProductClass,
    SearchBar, Tabs, Pagination, 
  },
  data() {
    return {
      getFilePath,
      listArr: [],
      pageNum: 1,
      isReq: true,
      tabList: [],
      total: 0,
      searchValue: '',
      pageData: {},
      showPageNum: true,
      showBoxShadow: false

    }
  },
  mounted() {
    this.showBoxShadow = false
  },
  created() {
    this.getList()
    this.getPcTabData()
  },
  methods: {
    scrollHandler(e) {
      const top = e.target?.scrollingElement?.scrollTop || 0
      if (top > 32) {
        this.showBoxShadow = true
      } else {
        this.showBoxShadow = false
      }
    },
    async getPcTabData() {
      const { data } = await getPcTabData()
      this.tabList = [...data.configContent.defaultList, ...data.configContent.list]
    },
    toDetail(item) {
      // uni.navigateTo({
      //   url: `/pages/explain/notice/component/noticeDetail?id=${id}`
      // })
      sessionStorage.setItem('content', item.fileContent)
      window.open('/user/cmsFileDetail?fileType=' + item.fileType)
    },
    async getList() {
      // let param = {
      //   condition: {
      //     noticeStatus: "issue",
      //     keywords: this.searchValue
      //   },
      //   descs: [
      //     "sorted",
      //     "create_time"
      //   ],
      //   pageNum: this.pageNum,
      //   pageSize: 10
      // }
      const { data } = await getAgreementList(['pc_version_declare',
        'pc_sys_safe_protect',
        'pc_icp_record',
        'pc_tele_business_license',
        'pc_user_agreement',
        'pc_medical_three_license',
        'pc_privacy_policy',
        'pc_net_device',
        'pc_quality_safe',
        'pc_service_line',
        'pc_business_license'])
      this.listArr = []
      if (data.length > 0) {
        this.listArr.push(...data)
        return
      }
      this.isReq = false
    },
    searchHandler() {
      this.getList()
    }
  }
}
</script>
<style scoped lang='less'>
.search-bar {
  background-color: #fff;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 3;
  // position: sticky;
  // z-index: 3;
  // top: 36px
  &.box-shadow {
    box-shadow: 4px 0px 20px 0px rgba(0, 0, 0, 0.08);
  }
}
  .licence {
    height: 100%;
    // overflow: hidden;
    .info_item {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-style: solid;
      border-color: #E7E7E7;
      border-width: 0 0 1px 0;
      cursor: pointer;
      &:last-child {
        border-bottom-width: 0;
        padding-bottom: 0;
      }
      &:first-child {
        padding-top: 0;
      }
      .title {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
      }
      .content {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.9);
        flex: 1;
      }
      .time {
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.4);
        margin-left: auto;
      }
    }
  }
  .notice-content {
    width: 1200px;
    margin: 16px auto 0;
    background: #FFFFFF;
    padding: 16px 32px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  .search-warp {
    width: 1200px;
    margin: 40px auto 0;
    display: flex;
    justify-content: flex-end;
  }
  .search {
    // float: right;
    margin-left: auto;
  }
  .pagination {
    width: 1200px;
    margin: 32px auto 0;
  }
  .breadcrumb {
    background: #eee;
    padding: 4px 0;
    .breadcrumb-warp {
      width: 1200px;
      margin: 0px auto 0;
      display: flex;
      align-items: center;
    }
    .text {
      margin-right: 32px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
    }
  }
:deep(.ivu-breadcrumb-item-separator) {
    width: 24px;
    height: 24px;
    padding: 6px 8px;
  }
</style>

<!-- pages/explain/notice/index -->