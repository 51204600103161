<template>
  <div class="more-list">
    <div class="head">
        <SearchBar style="width: 1200px;margin: 0 auto;" :licence="true"/>
        <ContentTitle  style="width: 1200px;"  :title="'商品列表'"></ContentTitle>
    </div>
    <div class="status_bar">
      <!-- 这里是状态栏 -->
    </div>
    <!-- <c-head type="text" title="更多" @change="backToIndex" :showCard="true" :cartNum="cartNum"></c-head> -->
    <div class="product-list">
      <ProductCard :goodsItem = "item" v-for="(item, index) in productsArr" class="list_item" :key="index"></ProductCard>
    </div>
    <!-- <c-purchased-car :showPopup.sync="isShowPopup" :goodItem="shoppingData" @addProduct="addProduct" /> -->
    <Pagination v-if="type == 'tags'" :total="total" :page-size-opts="[10, 20, 50, 100]" :size="pageSize" :num="pageNum" @change="handleChange" />
  </div>
</template>

<script>

import {
  getProductByIdS,  //获取商品type 1
  getStandandByIdS, //获取标品
} from "@/common/service/home.js"
import { productListByEs } from '@/common/service/product'
import { transformEsData } from '@/common/utils'
// import CList from '@/components/c-list/index'
import SearchBar from '@/components/SearchBar.vue'
import Tabs from '@/components/Tabs'
import ContentTitle from '@/components/ContentTitle'
import ProductCard from '@/components/ProductCards/index.vue'

export default {
  name: 'more',
  props: {
	
  },
  // onLoad(options) {
  //   this.cmsModelId = options.cmsModelId
  // },
  
  components: {
    SearchBar,Tabs,ContentTitle,ProductCard
  },
	
  data() {
    return {
			cartNum: 0, // getApp().globalData.carTextNum,
      isShowPopup: false, // 是否显示加购组件
      isJsHe: true,
      shoppingData: {},
      lists: [],
      productsArr: [],
      productType: '',  //type：1 商品，type：2 标品
      isInster: false,  //是否认证
      cmsModelId: '',
      pageSize: 10,
      pageNum: 1,
      total:0,
      type:''
    }
  },
  created() {
    let query = this.$route.query
    if( !query || !query.type ) return
    this.type = query.type
    this.cmsModelId = query.cmsModelId
    if (this.type == 1) {
      this.getProductSp()
    } else if (this.type == 2) {
      this.getProductBp()
    } else if (this.type == 'tags') {
      this.getTagsProducts()
    }
  },
  methods: {
		// 加购组件回调
		addProduct(num) {
			this.cartNum = 0 // getApp().globalData.carTextNum
		},
    addProductCart(val) {
      this.shoppingData = {
        orgId: val.orgId,//商家id,
        id: val.productId,//商品id
        activityId: val.activityId,//活动id
        activityType: val.activityType,//活动类型
        addBuyNum: val.addBuyNum,//每次加购数量
        startBuyNum: val.startBuyNum,//起购数量
        availableStock: val.availableStock,//库存
        singleLimitNum: val.singleLimitNum,//限购数量
        isSingleLimit: val.isSingleLimit,//是否限购 //0不限购 1限购
        currentPrice: val.currentPrice,//商品原价
        activityPrice: val.activityPrice,//商品活动价
        brand: val.brand,//商品品牌
        generalChineseName: val.displayName,//商品名称
        productSpec: val.productSpec,//包装规格
        mainPicUrl: val.mainPicVo?val.mainPicVo.picUrl: '',//商品主图
        productUnit: val.productUnit,  //单位
        displayName: val.displayName
      }
      this.isShowPopup = true
    },
    go() {
      console.log('tiaozhuan')
      uni.navigateTo({
        url: '/pages/product/search/index'
      })
    },
    backToIndex() {
      uni.navigateBack({
        delta: 1
      })
    },
    async getProductSp() {
      const { data } = await getProductByIdS({
        	pageSize: 200,
          startIndex: 0,
          cmsModelId: this.cmsModelId
      })
      data.records.forEach((item)=> {
        transformEsData(item)
      })
      this.productsArr = data.records
      this.getInster()
    },
    async getProductBp() {
      const { data } = await getStandandByIdS({
        pageSize: 200,
        startIndex: 0,
        cmsModelId: this.cmsModelId
      })
      data.records.forEach((item)=> {
        transformEsData(item)
      })
      this.productsArr = data.records
      // this.getInster()
      // if (data[0].custState === 'auth') {
      //   this.isInster = true
      //   return
      // }
      // this.isInster = false
    },
    async getInster() {
      if (this.productsArr?.at(0)?.custState === 'auth') {
        this.isInster = true
        return
      }
      this.isInster = false
    },
    handleChange(o) {
      const { pageNumber, pageSize } = o
      this.pageNum = pageNumber
      this.pageSize = pageSize
      this.getTagsProducts()
    },
    async getTagsProducts() {
      let query = this.$route.query
      let labels = query.labels.split(',')
      const params = {
        productLabelIds:labels,
        orgId: [query.orgId],
        page: {
          pageSize: this.pageSize,
          pageNumber: this.pageNum
        }
      }
      const data = await productListByEs(params)
      if (data.code === 200) {
        this.productsArr = data.data.records
        this.total = data.data.total
      }
    }
  }
}
</script>

<style lang="less" scoped>
.status_bar {
  height: var(--status-bar-height);
  width: 100%;
  background: #fff;
}
.more-list {
  overflow: hidden;
  position: relative;
  min-height: 100%;
  width: 1200px;
  margin: auto;
}
.list_item {
  margin: 0 15px 15px 0;
  &:nth-child(5n) {
    margin-right: 0;
  }
}
.product-list {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 73px;
}
::v-deep .u-tag__text {
  font-size: 20rpx !important;
}
::v-deep .u-tag {
  height: 30rpx !important;
}
::v-deep .acti_tag {
  .line-clamp-1 {
    max-width: 400rpx;
    text-overflow : ellipsis;
    white-space : nowrap;
    overflow : hidden;
  }
}
// @media screen and (min-height:800px){
//   .more-list {
//     .list_item:nth-child(3) {
//         margin-top: 154rpx;
//     }
//   }
// }
</style>
