<template>
  <Modal v-model="show" title="导入文件" @on-cancel="cancel">
    <div class="upload-section">
      <div v-if="failDetailPath" class="fail-content">
        <span>
          <span style="color: red;">*</span>
          成功导入
          <span>{{ successNum }}</span>
          条,失败
          <span style="color: red;">{{ failNum }}</span>
          条
        </span>
        <a v-if="failDetailPath" :href="failDetailPath" target="downloadFile" class="down-load export">
          <svg-icon name="red-download" />
          下载失败文件.xlsx
        </a>
      </div>
      <Upload
        ref="upload"
        action="/apis/rym-file/uploadTempFile"
        type="drag"
        :show-upload-list="false"
        :format="['xlsx', 'xls']"
        :on-format-error="handleFormatError"
        :on-success="handleSuccess"
        :on-error="handleError"
        :before-upload="handleBeforeUpload"
        :headers="headers"
      >
        <div class="upload-btn">
          <!-- <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon> -->
          <svg-icon name="upload" class="upload-icon" />
          <span class="upload-text">将文件拖到此处，或<a>点击上传</a></span>
          <span style="font-size: 12px;color: rgba(0, 0, 0, 0.4);">文件支持xlsx格式，大小不超过10M</span>
        </div>
      </Upload>
      <ul class="upload-list">
        <li v-for="item in uploadFileList" :key="item.uid" class="file-item">
          <template v-if="item.status === 'finished'">
            <span class="file-name">{{ item.response.data.originalFileName }}</span>
            <img class="del-icon" src="@/assets/images/order/del-icon.png" alt="" @click="handleRemove(item)">
          </template>
          <template v-else>
            <Progress v-if="item.showProgress" :percent="item.percentage" hide-info />
          </template>
        </li>
      </ul>

    </div>
    <template #footer>
      <div class="fail-modal-footer">
        <span class="tip">
          <a href="/xlstemplate/批量导入采购商品.xlsx" target="downloadFile" class="down-load">
            <svg-icon name="download" class="download-icon" />
            模版下载
          </a>
        </span>
        <Button type="default" @click="cancel">取消</Button>
        <Button
          type="primary"
          :loading="createPlanLoading"
          :disabled="uploadFileList?.length <= 0"
          @click="ok"
        >确认</Button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { getToken } from '@/common/utils'
import { speedOrderImportFile } from '@/common/service'
export default {
  name: 'UploadFile',
  props: {
    btnDisabled: {
      type: Boolean,
      default: false
    },
    modalShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      createPlanLoading: false,
      failDetailPath: '',
      uploadFileList: [],
      successNum: 0,
      failNum: 0,
      headers: {
        Authorization: getToken()
      }
    }
  },
  computed: {
  },
  watch: {
    modalShow(val) {
      if (val) {
        this.show = val
        this.uploadFileList = []
        this.$refs.upload.fileList = []
        this.failDetailPath = ''
      }
    }
  },
  methods: {

    handleFormatError(file) {
      this.$Message.warning('文件 ' + file.name + ' 格式不正确，请上传.xls,.xlsx文件。')
    },
    handleSuccess(res, file) {
      console.log(file, this.$refs.upload.fileList)
      // this.$Message.success("数据导入成功！")
      this.uploadFileList = this.$refs.upload.fileList
    },
    handleError(error, file) {
      try {
        // 检查是否有错误发生
        if (error) {
          this.$Message.error(`文件 ${file.name} 导入失败！错误详情: ${error.message}`)
        } else {
          // 如果没有错误，更新文件列表
          this.uploadFileList = this.$refs.upload.fileList
        }
      } catch (e) {
        // 捕获并处理异常
        this.$Message.error(`处理文件时发生未知错误: ${e.message}`)
      }
    },
    handleRemove(file) {
      const ids = this.$refs.upload.fileList.map(item => item.response.data.fileId) || []
      this.$refs.upload.fileList.splice(ids.indexOf(file.response.data.fileId), 1)
      this.uploadFileList = this.$refs.upload.fileList
    },
    handleBeforeUpload() {
      const check = this.$refs.upload.fileList.length < 1
      if (!check) {
        this.$Message.warning('最多上传1个文件。')
      }
      return check
    },
    async ok() {
      this.orgId = parseInt(localStorage.getItem('selectOrgId')) || 0
      const params = {
        fileId: this.uploadFileList[0]?.response?.data.fileId,
        orgId: this.orgId
      }
      this.createPlanLoading = true
      try {
        const res = await speedOrderImportFile(params)
        this.createPlanLoading = false
        if (res.code === 200) {
          this.$message.success(res.data?.des)
          this.show = false
          this.$emit('uploadSuccess')
          this.$emit('update:modalShow', false)
        } else {
          // 导入失败 下载失败明细
        }
      } catch (error) {
        console.log(error, 'error')
        if (error?.data?.code === 500) {
          this.failDetailPath = error?.data?.data?.fileVo.path
          this.failNum = error?.data?.data?.failSize
          this.successNum = error?.data?.data?.successSize
          this.$emit('uploadSuccess')
        }
        this.createPlanLoading = false
        this.$message.error(error?.data?.message)
      }
    },
    cancel() {
      this.$nextTick(() => {
        this.show = false
        this.$emit('update:modalShow', false)
      })
    }
  }
}
</script>
<style scoped lang="less">
:deep(.ivu-modal-header) {
  border-bottom: none;
  padding: 20px 16px 0;
}

:deep(.ivu-modal-footer) {
  border-top: none;

  .tip {
    position: absolute;
    left: 16px;
    bottom: 16px;
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .download-icon {
      width: 20px;
      height: 20px;
    }

    .down-load {
      color: var(--hbrand-6, #3853E2);

      &:hover {
        cursor: pointer;
      }
    }

    .export {
      margin-left: 10px;
    }
  }

  .ivu-btn-primary[disabled] {
    background: var(--brand-3, #FDDA9F);
    color: #fff;
    border-color: #fdda9f;
  }

}

.upload-section {
  .fail-content {
    height: 32px;
    background: rgba(255, 236, 232, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px;

    svg {
      width: 16px;
      height: 16px;
    }

    a {
      color: rgba(56, 83, 226, 1);
    }

    .export {
      color: rgba(203, 39, 45, 1);
    }
  }

  .plan-name-input {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .ivu-input-wrapper {
      margin-left: 16px;
      width: 410px;
    }
  }

  .ivu-upload {
    margin-top: 16px;
    background: rgba(250, 250, 250, 1) !important
  }

  .upload-btn {
    padding: 32px;
    height: 160px;

    &:hover {
      cursor: pointer;
    }

    .upload-icon {
      width: 40px;
      height: 40px;
    }

    .upload-text {
      color: var(--font-black-1, rgba(0, 0, 0, 0.60));
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      display: block;
    }
  }

  .upload-list {
    .file-item {
      margin-top: 12px;
      // width: 324px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .file-name {
        color: var(--font-black-2, rgba(0, 0, 0, 0.60));
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }

      .del-icon {
        width: 14px;
        height: 14px;
      }

      &:hover {
        background: var(--gray-15, #F5F5F5);
      }
    }
  }
}
</style>
