import CenterIndex from '@/views/personnal/center'
import CenterInfo from '@/views/personnal/center/info/info.vue'
import CenterEnterprise from '@/views/personnal/center/enterprise/enterprise.vue'
import Affiliated from '../../views/personnal/affiliated'
import Association from '../../views/personnal/association'
import RouterContanst from '@/router/router-contanst'
export default [
  {
    path: '/center/index',
    name: 'CenterIndex',
    component: CenterIndex,
    redirect: '/center/info',
    children: [
      {
        path: '/center/info',
        name: 'CenterInfo',
        component: CenterInfo,
        meta: {
          title: '用户中心'
        }
      },
      {
        path: '/center/enterprise',
        name: 'CenterEnterprise',
        component: CenterEnterprise,
        meta: {
          title: '我的企业'
        }
      },
      {
        path: '/center/enterprise/change',
        name: 'EnterpriseChange',
        component: () => import('@/views/personnal/center/enterprise/change/change.vue'),
        meta: {
          title: '信息项变更'
        }
      },
      {
        path: '/center/certification',
        name: 'CenterCertification',
        component: () => import('@/views/personnal/center/certification/certification.vue'),
        meta: {
          title: '资质管理'
        }
      },
      {
        path: '/center/enterprise/batch',
        name: 'CenterBatch',
        component: () => import('@/views/personnal/center/components/Batch.vue'),
        meta: {
          title: '批量修改'
        }
      },
      {
        path: '/center/enterprise/addMustCustCert',
        name: 'AddMustCustCert',
        component: () => import('@/views/personnal/center/components/AddMustCustCert.vue'),
        meta: {
          title: '补充资质'
        }
      },
      {
        path: '/center/enterprise/custTypeChange',
        name: 'CustTypeChange',
        component: () => import('@/views/personnal/center/components/CustTypeChange.vue'),
        meta: {
          title: '客户类型变更'
        }
      },
      {
        path: '/center/merchant',
        name: 'CenterMerchant',
        component: () => import('@/views/personnal/center/merchant/index.vue'),
        meta: {
          title: '我的商家'
        }
      },
      {
        path: '/center/collection',
        name: 'CenterCollection',
        component: () => import('@/views/personnal/center/collection/index.vue'),
        meta: {
          title: '我的收藏'
        }
      },
      {
        path: '/center/footmark',
        name: 'CenterFootmark',
        component: () => import('@/views/personnal/center/footmark/index.vue'),
        meta: {
          title: '我的足迹'
        }
      },
      {
        path: '/center/purchaseList',
        name: 'CenterPurchaseList',
        component: () => import('@/views/personnal/center/purchaseList/index.vue'),
        meta: {
          title: '常购清单'
        }
      },
      {
        path: '/center/order/list',
        name: 'orderList',
        meta: {
          title: '订单详情'
        },
        component: () => import('@/views/order/order/index.vue')
      },
      {
        path: '/center/modifyMobile',
        name: RouterContanst.USER.MODIFY_MOBILE,
        component: () => import('@/views/personnal/modifyMobile')
      },
      {
        path: '/center/forgetPwd',
        name: 'orderList',
        component: () => import('@/views/personnal/forgetPwd/index.vue')
      },
      {
        path: '/msg/detail',
        name: 'MsgDetail',
        component: () => import('@/views/personnal/msg/detail.vue')
      },
      {
        path: '/center/modifyPwd',
        name: 'modifyPwd',
        component: () => import('@/views/personnal/modifyPwd/index.vue')
      },
      {
        path: '/lottery/index',
        name: 'Lottery',
        component: () => import('@/views/personnal/lottery/index.vue')
      },
      {
        path: '/lottery/squared',
        name: 'Squared',
        component: () => import('@/views/personnal/lottery/squared.vue')
      },
      {
        path: '/redEnvelope/index',
        name: 'RedEnvelope',
        component: () => import('@/views/personnal/redEnvelope/index.vue')
      },
      {
        path: '/feedback/index',
        name: 'Feedback',
        component: () => import('@/views/personnal/feedback/index.vue'),
        meta: {
          title: '意见反馈'
        }
      }
    ]
  },
  {
    path: '/affiliated',
    name: 'Affiliated',
    component: Affiliated
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import('@/views/personnal/information/index.vue')
  },
  {
    path: '/association',
    name: 'Association',
    component: Association
  },
  {
    path: '/login/modifyPwd',
    name: 'loginModifyPwd',
    component: () => import('@/views/personnal/modifyPwd/index.vue')
  },
  {
    path: '/download/preview',
    name: 'DownloadPreview',
    component: () => import('@/views/personnal/download/preview.vue'),
    meta: {
      title: '资料预览'
    }
  },
  {
    path: '/download/report',
    name: 'DrugReportDonload',
    component: () => import('@/views/personnal/download/drugReport.vue'),
    meta: {
      title: '药检报告'
    }
  },
  {
    path: '/download/document',
    name: 'DocumentDownload',
    component: () => import('@/views/personnal/download/document.vue'),
    meta: {
      title: '首营资料'
    }
  },
  {
    path: '/download/invoice',
    name: 'InvoiceDownload',
    component: () => import('@/views/personnal/download/invoice.vue'),
    meta: {
      title: '电子发票'
    }
  }

]
