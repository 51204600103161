<template>
  <div class="v-market" :id="'nav' + options.id">
    <div class="card-list" :style="{
      gap:options?.props?.selectBy == '1'?'12px':'8px'
    }">
      <div class="card" v-for="item in data" :key="item.id">
        <div class="head" :style="{background: item.color}" >
          <span class="title">{{item.title}}</span>
          <div class="more" @click.stop="more(item)">
            更多
            <Icon style="margin-left: 4px;" type="ios-arrow-forward" />
          </div>
        </div>
        <div class="card-wrap">
          <ProductCard
          :goodsItem = "item"
          :isOrgAbbr = "false"
          v-for="(item, index) in item.records"
          :imgLazy="true"
          class="list_item"
          :size="options?.props?.selectBy == '1' ? 'market1' : 'market2' "
          :show-add-car="false"
          :key="index"></ProductCard>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink, getData , detaiHandler} from '@/views/home/index'
import ContentTitle from '@/components/ContentTitle'
// import ProductCard from '@/views/goodsItem'
import ProductCard from '@/components/ProductCards/index.vue'
export default {
  name: "v-market",
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      cmsLink: cmsLink.bind(this),
      show: false,
      data: []
    };
  },
  components: {
    ContentTitle,ProductCard
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      let list = this.options?.props?.productsList
      list?.forEach((item)=> {
        getData.bind(this)(this.options?.props?.selectBy == '1' ? 3 : 2, item.id, '1').then((data)=> {
          if( data?.length> 0 ) {
            item.records = data
            if( !this.show ) {
              this.$store.commit('setHomeNav', this.options.id)
              this.show = true
            }
            this.setData()
          }
          setTimeout(() => {
            this.$emit('load', this.options)
          }, 100)
        }).catch(() => {
          setTimeout(() => {
            this.$emit('load', this.options)
          }, 100)
        })
      })
    },
    setData() {
      this.data = this.options?.props?.productsList?.filter((item)=> item.records?.length> 0) || []
    },
    // 跳更多页
    more(data) {
      let cmsModelId = data.id
      let type = data.type
      this.$router.push(`/more?type=1&cmsModelId=${cmsModelId}`)
    }
  },
  computed: {
    // 获取一图地址
    path() {
      return getFilePath("PS_05",  this.options?.props?.fileId)
    },
    styleType() {
      return this.options?.props?.styleType
    }
  },
};
</script>
<style scoped lang="less">
@import url('../../product.less');
.v-market {
  width: 1200px;
}
.card-list {
  display: flex;
  //gap: 12px;
}
.card {
  flex: 1;
}
.card-wrap {
  display: flex;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}
.list_item {
  // width: auto !important;
  border-radius: 0;
  border-right: none !important;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    background-color: #EEE;
    width: 1px;
    transform: scale(.5, 1);
    height: 100%;
    top: 0;
    right: 0;
  }
  &:last-child::after {
    display: none;
  }
  &.three-col {
    //width: 228px;
  }
  &.two-col {
    //width: 196px;
  }
}
.head {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #EEE;
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    color: #181818;
  }
  .more {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.60);
    cursor: pointer;
  }
}
::v-deep .term_of_validity {
  left: 50% !important;
  transform: translateX(-50%);
}
</style>
