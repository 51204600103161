
<template>
  <div class="tags-row">
    <!-- <div v-if="activityType === 5 " class="activity-item">
      近效期
    </div> -->
    <div v-if="activityType === 5 " class="nearly-time">
      近效期
    </div>
    <!-- 一口价活动 -->
    <div v-if="activityType === 8" class="fixed-price">
      <svg-icon name="fixed-price" style="width: 44px;height: 18px" />
    </div>
    <template v-if="activityType === 2 ">
      <div v-if="data.couponMark" class="tag-item">
        券
      </div>
      <div class="time-special green" :class="{green:specialKeyword==='开始',orange:specialKeyword==='结束'}">
        <span>润划算 </span>
        <span v-if="data.marketing.preTime && specialKeyword==='开始'">距开始</span>
        <span v-if="data.marketing.displayEndTimeCountDown && specialKeyword==='结束'">距结束</span>
        <CountTime v-if="activityType === 2" :res-obj="data.marketing" @showCountTime="showCountTime" @changeStatus="changeStatus" />
      </div>
    </template>
    <template v-if="activityType!== 2">
      <div
        v-for="(item,index) in couponList"
        :key="index"
        class="tag-item"
      >
        {{ item?.couponLabel || '优惠券' }}
      </div>
      <div
        v-for="item in promotionList"
        :key="item.activityId"
        class="activity-item"
        :data-id="item.activityId"
        :data-type="item.activityType"
      >
        {{ item.shortLabal || '促销活动' }}
      </div>
    </template>
    <!-- 拼团组件 -->
    <div v-if="activityType == 6" class="group-buy-tag">
      <img src="../../assets/productCards/group-buy-icon.png" alt="" class="group-buy-icon">
      <div class="group-buy-inner">
        <span>
          已拼{{ data.marketing.soldOutProductNum || 0 }}{{ data.productUnit }}
        </span>
        <span class="split" />
        <span>
          {{ data.marketing.minPurchaseAmountIdentity }}{{ data.productUnit }}起拼
        </span>
      </div>
    </div>
    <img class="tag-mask" src="../../assets/productCards/tag-mask.png" alt="">
  </div>
</template>
<script>
/*
* 商品卡片 活动标签组件
* 近效期活动名称在前 优惠券在后
* 其他活动优惠券标签在前 活动名称在后
* 润划算 2
* 近效期 5
* 拼团 6
* */
import CountTime from '@/views/goodsItem/countTime/index.vue'

export default {
  name: 'ActivityTag',
  components: { CountTime },
  props: {
    // 优惠券列表数据
    data: {
      type: Object,
      default() {
        return {
          couponList: [],
          promotionMarketing: [],
          marketing: {}
        }
      }
    }
  },
  data() {
    return {
      specialKeyword: ''
    }
  },
  computed: {
    activityType() {
      return this.data?.marketing?.activityType || null
    },
    // 优惠券列表
    couponList() {
      const couponList = (this.data?.couponList || []).filter(item => item.couponLabel)
      // 添加判空
      if (!couponList.length) {
        return []
      }
      const list = [...couponList].reverse()
      // 如果 没有促销活动 只有优惠券  平铺这些优惠券
      if (!this.data?.promotionMarketing?.length) {
        /*
       * V1.35 只有优惠券时，对优惠券标签的文案去重
       * */
        const _list = []
        list.forEach(item => {
          if (!_list.some(u => u.couponLabel === item.couponLabel)) {
            _list.push(item)
          }
        })
        return _list
      } else {
        // 如果有多个促销活动，只返回第一张优惠券
        return [list[0]]
      }
    },
    // 普通促销活动列表
    promotionList() {
      const promotionList = (this.data?.promotionMarketing || []).filter(item => item.shortLabal)
      // 添加判空
      if (!promotionList.length) {
        return []
      }
      const list = [...promotionList].reverse()
      // 如果所有活动类型都是一样的，且 没有优惠券  则平铺显示这些活动
      if (list.every(item => item.type === list[0].type) && !this.data?.couponList?.length) {
        /*
        * V1.35 只有活动时，对活动标签的文案去重
        * */
        const _list = []
        list.forEach(item => {
          if (!_list.some(u => u.shortLabal === item.shortLabal)) {
            _list.push(item)
          }
        })
        return _list
      }
      // 如果有优惠券 或者 多种活动类型 则根据 活动类型大类去重
      const unique = []
      list.forEach(item => {
        const type = (item.activityType + '').substring(0, 1)
        item.type = type
        if (!unique.some(u => u.type === type)) {
          unique.push(item)
        }
      })
      return unique
    }
  },
  methods: {
    getCouponDesc(type = '') {
      const map = {
        coupon_full_decrement: '满减券',
        coupon_full_discount: '满折券',
        coupon_commodity: '商品券',
        coupon_full_rebate: '满返券',
        coupon_new_customer: '新客券',
        coupon_postage: '运费券'
      }
      return Reflect.get(map, type) || type
    },
    showCountTime(val) {
      this.specialKeyword = val
    },
    changeStatus(status) {
      this.$emit('changeStatus', status)
    }
  }
}
</script>

<style scoped lang="less">
.tags-row{
  white-space: nowrap;
  overflow: hidden;
  background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
  position: relative;
  font-size: 0;
  margin-top: 2px;
  min-height: 19px;
}
.tag-item{
  display: inline-block;
  font-size: 12px;
  color:#F53F3F;
  border-radius:4px;
  padding:0 3.5px;
  border: 0.5px solid #F53F3F;
  margin-right: 4px;
  line-height: 17px;
  //line-height: 1;
  height: 18px;
  box-sizing: border-box;
}
.tag-mask{
  position: absolute;
  height: 19px;
  width: auto;
  right: 0;
  top: 0;
}
.activity-item{
  display: inline-block;
  font-size: 12px;
  color:#F53F3F;
  border-radius:4px;
  padding:0 3.5px;
  border: 0.5px solid #F53F3F;
  margin-right: 4px;
  line-height: 17px;
  height: 18px;
  background: rgba(245, 63, 63, 0.05);
}
.nearly-time{
  display: inline-block;
  font-size: 12px;
  color:#F53F3F;
  background:url("~@/assets/images/notic/nearly-time-bg.png") no-repeat;
  background-size: cover;
  width:60px;
  height: 18px;
  line-height: 18px;
  margin-right: 4px;
  padding-left: 21px;
//   background-size:auto 18px;
}

.group-buy-tag{
  color: #F53F3F;
  font-size: 12px;
  height: 18px;
  box-sizing: border-box;
  padding-left: 22px;
  position: relative;
  border:0.5px solid #FB6654;
  border-radius: 4px;
  display: inline-block;
  padding-right: 3px;
  line-height: 17px;
  overflow: hidden;
  .split{
    height: 12px;
    width: 0.5px;
    margin:0 4px;
    background:#F53F3F;
    display: inline-block;
  }
}
.group-buy-icon{
  height: 18px;
  width: auto;
  position: absolute;
  top: 0;
  left: -1px;
}
.group-buy-inner{
  display: flex;
  align-items: center;
}

.coupon-mark {
  border: 0.5px solid #FF4C77;
  box-sizing: border-box;
  border-radius: 4px;
  color: #E72A57;
  text-align: center;
  background: #FFEEF2;
  height: 18px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  span{
    font-size: 12px;
    line-height: 14px;
  }
}
// 润滑算标签
.time-special{
  padding-left: 21px;
  padding-right: 3px;
  border:0.5px solid;
  height: 18px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  margin-right: 4px;
  display: inline-flex;
  &.green{
    border-color: #4DAC39;
    color:#086453;
    background:#F6FBF5 url("~@/assets/images/notic/time-special-green.png") no-repeat left center;
    background-size:auto 18px;
  }
  &.orange{
    border-color: #FC9C57;
    color:#A63D11;
    background:#FEFAF5 url("~@/assets/images/notic/time-special-orange.png") no-repeat left center;
    background-size:auto 18px;
  }
}
.fixed-price{
  display: inline-block;
  width: 44px;
  height: 18px;
  margin-right: 4px;
  line-height: 18px;
  vertical-align: top;
}
</style>
