<template>
  <div class="home">
     <!-- <div class="head" id="navtop">
        <SearchBar style="width: 1200px;margin: 0 auto;" :licence="true"/>
    </div>
    <div class="home-content">
      <Menu></Menu>
      <Center :data="bannerData"></Center>
      <div class="content-right">
      </div>
    </div>
    <VLiftNav class="v-lift-nav-ref" :options="liftNavData?.props"></VLiftNav> -->
    <div class="cms-config">
      <template v-for="item in list">
        <VCategoryCard :key="item.id" class="cms-config-item" :cmsId="cmsId" :options="item" v-if="item.type== 'v-category-card'"></VCategoryCard>
        <VPoster :key="item.id" class="cms-config-item" :id="item.id" :options="item.props" v-if="item.type== 'v-poster'" :class="setMarginClass(item.props)"></VPoster>
        <vManyProduct :key="item.id" class="cms-config-item" :cmsId="cmsId" :options="item" v-if="item.type== 'v-many-product'"></vManyProduct>
        <VPageCouplet :key="item.id" :options="item.props" v-if="item.type== 'v-page-couplet'"></VPageCouplet>
        <VProductCard class="cms-config-item" :key="item.id" :cmsId="cmsId" :options="item" v-if="item.type== 'v-product-card'"></VProductCard>
        <VFloor class="cms-config-item" :key="item.id" :cmsId="cmsId" :options="item" v-if="item.type== 'v-floor'"></VFloor>
        <vHotZone class="cms-config-item" :key="item.id" :id="item.id" :options="item.props" v-if="item.type== 'v-hot-zone'" :class="setMarginClass(item.props)"></vHotZone>
        <vTitle class="cms-config-item" :key="item.id" :id="item.id" :options="item.props" v-if="item.type== 'v-title'"></vTitle>
        <vManyText class="cms-config-item" :key="item.id" :id="item.id" :options="item.props" v-if="item.type== 'v-many-text'"></vManyText>
        <vTogetherGroup class="cms-config-item" :cms-id="cmsId" :key="item.id"  :options="item" v-if="item.type== 'v-together-group'"/>
        <vPriceDown v-if="item.type== 'v-price-down'" :cms-id="cmsId" :key="item.id" class="cms-config-item" :options="item" />
      </template>
      <!-- <div class="v-lift-nav-wrap"> -->
      <!-- </div> -->
      <div v-if="this.data && !this.data.items" class="cms-config-empty">
        <div class="cms-config-wrap">
          <img src="@/assets/images/home/empty.png"/>
          <p>暂无相关内容</p>
        </div>
      </div>
    </div>
    <!-- <ShopDescription />
    <HomeHelper /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHelper from '@/components/home/HomeHelper'
import ShopDescription from '@/components/home/ShopDescription'
import { getDecorateData, getWxTabsDetails } from '@/common/service/home'
import SearchBar from '@/components/SearchBar.vue'
import Menu from '@/views/home/coms/menu.vue'
import Center from '@/views/home/coms/center.vue'
import User from '@/views/home/coms/user.vue'
import Notice from '@/views/home/coms/notice.vue'
import VCategoryCard from './coms/vCategoryCard/index.vue' // 分类卡片
import VPoster from './coms/vPoster/index.vue' // 海报
import vManyProduct from './coms/vManyProduct/index.vue' // 多商品
import VPageCouplet from './coms/vPageCouplet/index.vue' // 页联
import VLiftNav from './coms/vLiftNav/index.vue' // 电梯导航
import VProductCard from './coms/vProductCard/index.vue' // 电梯导航
import VFloor from './coms/vFloor/index.vue' // 楼层
import vHotZone from './coms/vHotZone/index.vue' // 热区
import vTitle from './coms/vTitle/index.vue' // 标题
import vManyText from './coms/vManyText/index.vue' // 多行文本
import vTogetherGroup from './coms/vTogetherGroup/index.vue' // 拼团活动
import vPriceDown from './coms/vPriceDown/index.vue' // 润划算活动
import CmsDialogMixin from '@/common/mixins/cmsDialog'
export default {
  name: 'Home',
  mixins: [CmsDialogMixin],
  data() {
    return {
      data: null,
      cmsId: '',
      cmsDialogId: '', //  装修id，给cms dialog使用
      dialogCode: 'plat_activity_home', // 给dialoa使用
    }
  },
  components: {
    HomeHelper,
    ShopDescription,
    SearchBar,
    Menu,
    Center,
    User,Notice,VCategoryCard,VPoster,vManyProduct,VPageCouplet, VLiftNav,VProductCard,VFloor,vHotZone,vTitle,vManyText,vTogetherGroup,vPriceDown
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      // const { data } = await getDecorateData()
      let pageId = this.$route.query?.pageId
      const { data } = await getWxTabsDetails(pageId)
      this.data = data
      this.cmsId = (data && data.id || '').toString()
      this.cmsDialogId = this.cmsId
    },
    // 根据边距设置样式
    setMarginClass(data) {
      let classStr = ''
      let marginType = data.marginType || []
      if( marginType.includes('1') ) {
        classStr += ' no-top'
      }
      if( marginType.includes('2') ) {
        classStr += ' no-bottom'
      }
      return classStr
    }
  },
  computed: {
    bannerData() {
      return this.data?.items?.find((item)=> item.type === 'v-banner')
    },
    list() {
      return this.data?.items || []
    },
    refresh() { return this.$store.state.global.refreshHome },
    liftNavData () {
      return this.data?.items?.find((item)=> item.type === 'v-lift-nav')
    }
  },
  activated() {
    console.log('路由进入home页');
  }, 
  watch: {
    refresh () {
      this.getData()
    }
  }
}
</script>
<style scoped lang="less">
.home-content {
  margin: 0 auto 16px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding: 0 15px 0px 15px;
  overflow: hidden;
  position: relative;
}
.content-right {
  width: 236px;
  height: max-content;
  // margin-top: 38px;
}
.cms-config {
  padding-bottom: 16px;
  position: relative;
  overflow: hidden;
  clear: both;
  font-size: 0;
}
.cms-config-item {
  display: block;
  vertical-align: middle;
  min-width: 1200px;
  margin: 16px 0 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  &:first-child {
    margin-top: 0;
  }
  &.no-top {
    margin-top: 0;
  }
  &.no-bottom {
    margin-bottom: 0;
  }
}
.cms-config-empty {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .cms-config-wrap {
    width: 216px;
    height: 216px;
    position: relative;
  }
  img {
    width: 216px;
    height: 216px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
}
// .v-lift-nav-wrap {
  // position: absolute;
  // left: 0;
  // top: 0;
  // width: 100%;
  // height: 100%;
  .v-lift-nav-ref {
    position: sticky;
    bottom: 0;
    right: 20px;
  }
// }
</style>
