import { getPublicStatus, getProductByIdS, getStandandByIdS, customerSignUp } from '@/common/service'
import { transformEsData } from '@/common/utils'
let signLoading = false
async function getPublicStatusFun(data) {
  const {data: datasource} = await getPublicStatus(data)
  if( datasource == 'issue'  )
    return true
}
async function _detaiHandler(item, type) {
  // type： 1 代表商品，跳转到商品详情页
  if( type == '1' ) {
    let activityId = item.activityId || 0
    let activityType = item.activityType || 0
    // if( activityType != '2' ) {
    //   activityId = 0
    //   activityType= 0
    // }
    window.open(location.origin +  `/detail?productId=${item.productId || item.id}&activityId=${activityId}&activityType=${activityType}`)
  } else {
    // type： 2 代表标品，跳转到搜索结果页
    let baseProductId = JSON.stringify([item.id])
    // uni.navigateTo({
    //   url: `/pages/product/search/result?baseProductId=${baseProductId}`
    // })
    this.$router.push(`/pages/product/search/result?baseProductId=${baseProductId}`)
  }
}
async function signHandler(id) {
  signLoading = true
  const data = await customerSignUp({signUpId:id})
  signLoading = false
  if(data.code == 200) {
    this.$message.success(data.data)
  } else {
    this.$message.error(data.message)
  }
}
export const detaiHandler = _detaiHandler
export async function cmsLink(data) {
  if( !data ) return
  if( data.key == "product" ) { // 商品
    let value =  data.value && data.value.at(0)
    // 商品链接
    value && _detaiHandler.bind(this)(value, '1')
  } else if( data.key == "cattegoried"  ) { // 商品分类
    // 商品分类
    let value =  data.value
    value && console.log(`/pages/product/search/result?categoryId=${value}`)
    this.$router.push(`/search/list?categoryId=${value}`)
    // uni.navigateTo({
    //   url: `/pages/product/search/result?categoryId=${value}`
    // })
  } else if (data.key == "other") { // 其它
    let value =  data.value && data.value.at(0)
    // other, 这地比较奇葩，产品要求配置一个小程序全链接，可以直接跳转。
    // value && uni.navigateTo({
    //   url: value
    // })
    value && window.open(value)
  } else if(data.key == "activityTheme" ) { // 活动专题
    const pageId = data?.value?.at(0)?.id
    if( pageId ) {
      let status = await getPublicStatusFun({id: pageId, type: 'plat_activity'})
      if( status ) {
        window.open(location.origin +  `/activity?pageId=${pageId}`)
        // this.$router.push(`/activity?pageId=${pageId}`)
      } else {
        // 未上架跳转到默认页面
        // uni.navigateTo({
        //   url: `/pages/common/ActivityOver`
        // })
        window.open(location.origin +  `/pages/common/ActivityOver`)
        // this.$router.push('/pages/common/ActivityOver')
      }
    }
  } else if( data.key == 'platformFile' ) { // 文件
    const id = data?.value?.at(0)?.id
    if( id ) {
      let status = await getPublicStatusFun({id, type: 'plat_file'})
      if( status ) {
        // uni.navigateTo({
        //   url: `/pages/explain/notice/component/certificatesInfoDetail?id=` + id
        // })
        this.$router.push(`/user/cmsFileDetail?id=${id}`)
      } else {
        console.log(this)
        this.$parent.$refs.toast?.showToast({
          toastTitle: '跳转链接无效',
          toastIcon: '/static/tabBar/home/info.png',
          showStatus: true,
        })
        // uni.showToast({
        // 	title: '跳转链接无效1',
        // 	icon: 'none',
        // })
      }
    }
  } else if( data.key == 'announce' ) { // 公告
    const id = data?.value?.at(0)?.id
    console.log(id, 'id')
    if( id ) {
      let status = await getPublicStatusFun({id, type: 'notice_list'})
      if( status ) {
        // uni.navigateTo({
        //   url: `/pages/explain/notice/component/noticeDetail?id=` + id
        // })
        this.$router.push(`/notice/detail?id=${id}`)
      } else {
        this.$parent.$refs.toast?.showToast({
          toastTitle: '跳转链接无效',
          toastIcon: '/static/tabBar/home/info.png',
          showStatus: true,
        })
      }
    }
  } else if ( data.key == 'help' ){ // 帮助中心
    const id = data?.value?.at(0)?.id
    this.$router.push('/helperCenter?id=' + id)
  } else if ( data.key == 'business' ){ // 商家
    const id = data?.value?.at(0)?.id
    this.$router.push('/shopHome?orgId=' + id)
  } else if ( data.key == 'lottery' ){ // 抽奖活动
    const id = data?.value?.at(0)?.id
    window.open(location.origin +  `/lottery/squared?id=${id}`)
  } else if( data.key == 'sign' ) { // 报名活动
    const id = data?.value?.[0]?.id
    if( id && !signLoading ) {
      signHandler.bind(this)(id)
    }
  }
}
export async function getData(num, id, type) {
  let _list = []
  if( this.options ) {
    let _type = type || this.options.props.type
    if( _type == '1' ) { // 商品
      let {data} = await getProductByIdS({
        pageSize: this.options.props.num || num || 4,
        startIndex: 0,
        // searchPrescription: this.$route.name  == 'Home' || this.$route.name  == 'shopHome' ? false : true,
        cmsModelId: id || `${this.cmsId}_${this.options.id}`
      })
      data?.records?.forEach(item=> {
        if( item.custState == 'auth' ) {
          this.isInster = true
        } else {
          this.isInster = false
        }
        transformEsData(item)
        item._uid = item.id + item.activityId
      })
      this.list = _list = data?.records || []
    } else if( _type == '2' ) { // 标品
      let {data} = await getStandandByIdS({
        pageSize: this.options.props.num,
        startIndex: 0,
        cmsModelId: id || `${this.cmsId}_${this.options.id}`
      })
      data?.records?.forEach(item=> {
        if( item.custState == 'auth' ) {
          this.isInster = true
        } else {
          this.isInster = false
        }
        transformEsData(item)
        item._uid = item.id
      })
      this.list = _list = data?.records || []
    }
  }
  return _list
}
