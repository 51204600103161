// 分类卡片
<template>
  <div class="v-category-card" v-if="list?.length> 0" :id="'nav' + options.id">
    <!-- <ContentTitle :title="options?.props?.title"></ContentTitle> -->
    <!-- 一图四商品 -->
    <div v-if="styleType == '4'" class="four-product-wrap">
      <div class="four-product-cover">
        <img :src="path" alt="" class="four-product" @click="cmsLink(options.props.link)">
      </div>
      <img src="@/assets/images/home/swiper-left.png" class="swiper-img four-img-right" v-if="list.length > 4"  @click="slideToLeft(4)"/>
      <img src="@/assets/images/home/swiper-right.png" class="swiper-img four-img-left" v-if="list.length > 4"  @click="slideToRight(4)"/>
      <swiper
        class="four-product-right"
        :options="swiperOption"
        ref="mySwiperFour"
      >
        <swiper-slide v-for="(item, index) in list" :key="index" style="width:228px">
          <ProductCard
            :key="index"
            :goods-item="item"
            :is-org-abbr="false"
            :class="['list_item','c148']"
            :img-lazy="true"
            :show-add-car="false"
          />
        </swiper-slide>
      </swiper>
    </div>
    <!-- 一图五商品 -->
    <div v-if="styleType == '5'" class="five-product-wrap" style="height: 292px">
      <div class="five-product-cover">
        <img :src="path" alt="" class="five-product" @click="cmsLink(options.props.link)">
      </div>
      <img src="@/assets/images/home/swiper-left.png" class="swiper-img five-img-right" v-if="list.length > 4"  @click="slideToLeft(4)"/>
      <img src="@/assets/images/home/swiper-right.png" class="swiper-img five-img-left" v-if="list.length > 4"  @click="slideToRight(4)"/>
      <swiper
        class="five-product-right"
        :options="swiperOption"
        ref="mySwiperFour"
      >
        <swiper-slide class="five-item" v-for="(item, index) in list" :key="index">
          <ProductCard
          :goodsItem = "item"
          :isOrgAbbr = "false"
          :class="['list_item','c198x292']"
          :imgLazy="true"
          :showAddCar="false"
          :key="index">
        </ProductCard>
        </swiper-slide>
      </swiper>
    </div>
    <!-- 6图8商品 -->
    <!-- <div v-if="styleType == '8'" class="eight-product-wrap">
      <div class="eight-img-wrap">
         <img :src="path" alt="" v-for="(item, index) in pathList" :key="index" class="eight-img">
      </div>
      <div class="eight-prudoct-wrap">
        <ProductCard :goodsItem = "item" v-for="(item, index) in list.slice(0, 8)" class="list_item" :key="index"></ProductCard>
      </div>
    </div> -->
  </div>
</template>

<script>
/**
 * styleType  一图四商品: 4 一图五商品: 5 六图八商品:8
 */
import { getFilePath } from '@/common/utils/base'
import { cmsLink, getData, detaiHandler} from '@/views/home/index'
import ContentTitle from '@/components/ContentTitle'
// import ProductCard from '@/views/goodsItem'
import ProductCard from '@/components/ProductCards/index.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: "v-category-card",
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      cmsLink: cmsLink.bind(this),
      list: [],
      tmpList: [],
      timer:null,
      swiperOption: {
        // 分页器设置
        pagination: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        notNextTick: true,
        // 循环
        loop: false,
        slidesPerView: 'auto',
        // loopedSlides: 7,
        slidesPerGroup:  this.options?.props?.styleType==4?4:5,
        // loopFillGroupWithBlank: true,
        // 设定初始化时slide的索引
        initialSlide: 0,
        // 自动播放，等同下面autoplay设置
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true
        // },
        spaceBetween:0,
        // 设置轮播切换效果
        effect: 'slide',
        // 轮播切换动速度
        speed: 3000,
        // 轮播滑动方向
        direction: 'horizontal',
        // 小手掌抓取滑动
        grabCursor : true,
        // 滑动之后回调函数
        on: {
          slideChangeTransitionEnd: function() {
            // console.log(this.activeIndex);//切换结束时，告诉我现在是第几个slide
          }
        }

      },
    };
  },
  components: {
    ContentTitle,
    ProductCard,
    swiper,
    swiperSlide
  },
  created() {
    // 因为要做轮播，需要一次性加载出来所有商品，传参由this.options?.props?.styleType修改为最大值20
    getData.bind(this)(20).then(() => {
      this.$emit('load', this.options)
    }).catch(() => {
      this.$emit('load', this.options)
    })
  },
  methods: {
    // 该数据处理方法暂时保留 数据处理，定时轮询4/5个商品,如果小于4/5不进行轮询
    dataHandle(){
      const num = this.options?.props?.styleType
      const length = this.list?.length || 0
      if(length > num) {
        // 计算 4/5的整数、余数
        const intNum = Math.floor(length/num)
        const modNum = length%num
        // 每隔3秒 轮询新的数据
        let total = 0
        this.timer = setInterval(()=>{
          if(total < intNum){
            this.tmpList = this.list.slice(num*total,num*total+num)   
            total++; 
          } else {
            // 存在不足4/5的时候，进行数据补齐
            if(modNum>0){
              let tmp = this.list.slice(0,num-modNum)
              let tmp1 = this.list.slice(length-modNum,length)
              this.tmpList = tmp1.concat(tmp)
              total++; 
            } 
            total = 0
          }          
        },10000)
      } else {
        this.tmpList = this.list
      }
    },
    slideToLeft(num) {
      if (num == 4) {
        this.$refs.mySwiperFour.swiper.slidePrev()
      } else {
        this.$refs.mySwiperFive.swiper.slidePrev()
      }
    },
    slideToRight(num) {     
      if (num == 4) {
        this.$refs.mySwiperFour.swiper.slideNext()
      } else {
        this.$refs.mySwiperFive.swiper.slideNext()
      }
    }
  },
  computed: {
    // product() {
    //   return this.list
    // },
    // 获取一图地址
    path() {
      return getFilePath("PS_05",  this.options?.props?.fileId)
    },
    // pathList() {
    //   return this.options?.props?.list?.map((item)=> getFilePath("PS_05",  item?.fileId)) || []
    // },
    styleType() {
      return this.options?.props?.styleType
    }
  },
  watch: {
    list: {
      handler(val){
        if( val.length> 0 )
          this.$store.commit('setHomeNav', this.options.id)
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="less">
@import url('../../product.less');
.v-category-card {
  width: 1200px;
}

.four-product-wrap {
  height: 324px;
  display: flex;
  /deep/ .prd_control{
    height: 56px;
  }
  .four-product-cover {
    width: 288px;
    .four-product {
      width: 100%;
      height: 100%;
    }
  }
  .four-product-right {
    flex: 1;
  }
}

.five-product-wrap {
  height: 320px;
  display: flex;
  /deep/ .prd_control{
    height: 56px;
  }
  .five-product-cover {
    width: 210px;
    .five-product {
      width: 100%;
      height: 100%;
    }
  }
  .five-product-right {
    flex: 1;
    .five-item {
      overflow: hidden;
      box-sizing: border-box;
      border-right: 0.5px solid #eee;
      width: 200px;
    }
  }
}
// .five-product-wrap {
//   height: 320px;
//   display: flex;
//   /deep/ .prd_control{
//     height: 56px;
//   }
//   .five-product-cover {
//     border: 1px solid red;
//     width: 288px;
//     .four-product {
//       width: 100%;
//       height: 100%;
//     }
//   }
//   .five-product-right {
//     flex: 1;
//     border: 1px solid red;
//   }
// }
// .five-product-wrap {
//   display: flex;
//   .product-item {
//     border-left:0.5px solid #eee;
//   }
// }
// .five-product {
//   width: 210px;
//   height: auto;
// }

.swiper-img {
  width: 24px;
  height: 32px;
  position: absolute;
  top: 45%;
  z-index: 999;
}
.four-img-right {
  left: 288px;
}
.four-img-left {
  right: 0;
}
.five-img-right{
  left: 210px;
}
.five-img-left {
  right: 0;
}

.eight-product-wrap {
  display: flex;
  .eight-img-wrap {
    display: flex;
    flex-direction: column;
    .eight-img {
      width: 245px;
      height: 80px;
    }
  }
  .eight-prudoct-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
