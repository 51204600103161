import {
  productClassify,
  getListOrgCategoryIdByEs,
  productBusiness,
  productFactory,
  productSpe,
  productLabels,
  productListByEs,
  cancelPrice,
  cancelStock,
  getEstimatePrice,
  cancelEstimatePrice,
  fetchPrice,
  fetchStock
} from '@/common/service/product'
import { Message } from 'view-design'

const state = {
  searchStatus: false,
  sortName: null, // 排序下拉列表选中项
  state: null, // 仅看有货 1
  activityState: null, // 有促销 1
  keyword: '', // 搜索关键词
  categoryIndex: null, // 记录最后点击是的哪一层分类 'first' | 'second' | 'third'
  categoryId: null, // 分类id
  categoryName: null, // 分类名
  orgId: null, // 店铺Id
  hasSelect: [], // 已选择的商家，厂家，包装规格
  searchData: [], // 搜索结果列表
  categoryData: [], // 分类数据
  shopClassify: [], // 搜索结果商家聚合分类
  businessData: [], // 商家数据
  factoryData: [], // 厂家数据
  ruleData: [], // 包装规格
  labelData: [], // 标签数据
  selectBusiness: [], // 选中的商家
  selectFactory: [], // 选中的厂家
  selectWrap: [], // 选中的包装规格
  selectLabel: [], // 选中的标签
  showShopClassify: false, // 商家点击商品分类列表显示
  orgBuyFlag: null, // 买过的店
  prdBuyFlag: null // 买过的品
}

const mutations = {
  SET_SEARCH_STATUS(state, data) {
    state.searchStatus = data
  },
  SET_SORT_NAME(state, data) {
    state.sortName = data
  },
  SET_KEYWORD(state, data) {
    state.keyword = data
  },
  SET_HAS_SELECT(state, data) {
    state.hasSelect = data
  },
  SET_STATE(state, data) {
    state.state = data
  },
  SET_ACTIVITY_STATE(state, data) {
    state.activityState = data
  },
  SET_CATEGORY_ID(state, data) {
    if (data) state.categoryId = [data].flat(3)
    else state.categoryId = null
  },
  SET_CATEGORY_NAME(state, data) {
    state.categoryName = data
  },
  SET_ORG_ID(state, data) {
    state.orgId = data
  },
  SET_CATEGORY(state, data) {
    state.categoryData = data
  },
  SET_BUSINESS(state, data) {
    state.businessData = data
  },
  SET_FACTORY(state, data) {
    state.factoryData = data
  },
  SET_RULE(state, data) {
    state.ruleData = data
  },
  SET_LABELS(state, data) {
    state.labelData = data
  },
  SET_SEARCH(state, data) {
    state.searchData = data
  },
  SET_CATEGORY_INDEX(state, data) {
    state.categoryIndex = data
  },
  SET_SELECT_BUSINESS(state, data) {
    state.selectBusiness = data
  },
  SET_SELECT_FACTORY(state, data) {
    state.selectFactory = data
  },
  SET_SELECT_WRAP(state, data) {
    state.selectWrap = data
  },
  SET_SELECT_LABEL(state, data) {
    state.selectLabel = data
  },
  SET_SHOW_SHOP_CLASSIFY(state, data) {
    state.showShopClassify = data
  },
  SET_SHOP_CLASSIFY_DATA(state, data) {
    state.shopClassify = data
  },
  SET_BUY_SHOP_STATE(state, data) {
    state.orgBuyFlag = data
  },
  SET_BUY_GOODS_STATE(state, data) {
    state.prdBuyFlag = data
  },
  SET_COLD_CHAIN_STATE(state, data) {
    state.isColdChain = data
  },
  SET_RX_STATE(state, data) {
    state.prescriptionType = data
  }
}

const actions = {
  // 获取分类
  getCategoryData({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      productClassify(params).then((res) => {
        commit('SET_CATEGORY', res.data)
        resolve(res.data)
      })
    })
  },
  // 搜索结果页获取商家聚合分类
  getShopClassify({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      getListOrgCategoryIdByEs(params).then((res) => {
        commit('SET_SHOP_CLASSIFY_DATA', res.data)
        resolve(res.data)
      })
    })
  },
  // 商家
  getProductBusiness({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      productBusiness(params)
        .then((res) => {
          commit('SET_BUSINESS', res.data)
          resolve(res.data)
        })
        .catch((e) => {
          resolve([])
        })
    })
  },
  // 厂家
  getProductFactory({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      productFactory(params)
        .then((res) => {
          commit('SET_FACTORY', res.data)
          resolve(res.data)
        })
        .catch((e) => {
          resolve([])
        })
    })
  },
  // 包装规格
  getProductSpe({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      productSpe(params)
        .then((res) => {
          commit('SET_RULE', res.data)
          resolve(res.data)
        })
        .catch((e) => {
          resolve([])
        })
    })
  },
  // 标签搜索
  getProductLabels({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      productLabels(params)
        .then((res) => {
          commit('SET_LABELS', res.data)
          resolve(res.data)
        })
        .catch((e) => {
          resolve([])
        })
    })
  },
  // 搜索结果列表
  // 开启多线程进行搜索速度优化
  // 搜索接口优化，拆分接口，此方法先暂时弃用
  /* getProductListByEs({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      const page = params.page
      page.pageNumber = page.pageNumber - 1
      const req = [
        productListByEs({ ...params, page: { pageNumber: page.pageNumber * 5 + 1, pageSize: 10 }}),
        productListByEs({ ...params, page: { pageNumber: page.pageNumber * 5 + 2, pageSize: 10 }}),
        productListByEs({ ...params, page: { pageNumber: page.pageNumber * 5 + 3, pageSize: 10 }}),
        productListByEs({ ...params, page: { pageNumber: page.pageNumber * 5 + 4, pageSize: 10 }}),
        productListByEs({ ...params, page: { pageNumber: page.pageNumber * 5 + 5, pageSize: 10 }})
      ]
      Promise.all(req).then((res) => {
        let allData = []
        let endData = {}
        res.forEach(item => {
          if (item.data?.records) {
            allData = allData.concat(item.data?.records)
          }
          if (page.pageNumber * 5 + 5 === item.data.pageNumber) {
            endData = JSON.parse(JSON.stringify(item))
          }
        })
        endData.data.pageNumber = page.pageNumber + 1
        endData.data.pageSize = page.pageSize
        endData.data.records = allData
        commit('SET_SEARCH', endData.data.records)
        resolve(endData.data)
      })
    })
  }, */

  //   getProductListByEs({ state, commit }, params) {
  //     return new Promise((resolve, reject) => {
  //       productListByEs(params)
  //         .then((res) => {
  //           commit('SET_SEARCH', res.data.records)
  //           resolve(res.data)
  //           const sellPriceVos = res.data?.extra?.sellPriceVos || []
  //           if (sellPriceVos && sellPriceVos.length > 0) {
  //             fetchPriceAndStock(sellPriceVos).then((res2) => {
  //               const data = res2.data || []
  //               const esData = []
  //               res.data.records.forEach((item) => {
  //                 data.forEach((item2) => {
  //                   if (item.id == item2.id) {
  //                     item.customerTypePrice.push({ price: item2.currentPrice })
  //                     item.availableStock = item2.availableStock
  //                     item.nearEffectDate = item2.nearEffectDate
  //                     item.showExpirationWarn = item2.showExpirationWarn
  //                     item.state = (item2.availableStock && item2.availableStock) > 0 ? 1 : 0
  //                     const activityType = item.marketing?.activityType
  //                     esData.push({
  //                       id: item.id,
  //                       orgId: item.orgId,
  //                       productId: item.productId,
  //                       activityId: item.marketing?.activityId || 0,
  //                       activityType: activityType || 0,
  //                       sellPrice: item2.sellPrice
  //                     })
  //                   }
  //                 })
  //               })
  //               commit('SET_SEARCH', res.data.records)
  //               getEstimatePrice(esData).then((esRes) => {
  //                 const _listData = res.data.records.map((item) => {
  //                   const target = esRes.data.find((esItem) => esItem.id == item.id)
  //                   return {
  //                     ...item,
  //                     estimatePrice: target.estimatePrice
  //                   }
  //                 })
  //                 commit('SET_SEARCH', _listData)
  //               })
  //             })
  //           }
  //         })
  //         .catch((error) => {
  //           resolve(error)
  //         })
  //     })
  //   },

  getProductListByEs({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      cancelPrice()
      cancelStock()
      cancelEstimatePrice()
      productListByEs(params)
        .then((res) => {
          if (['仅显示最近买过的500个品种', '仅显示最近买过的200个店铺'].includes(res?.message)) {
            Message.warning(res?.message)
          }
          const recordList = res.data?.records || [] // 商品列表
          const sellPriceVos = res.data?.extra?.sellPriceVos || []
          commit('SET_SEARCH', recordList)
          resolve(res.data)
          if (sellPriceVos && sellPriceVos.length > 0) {
            fetchPrice(sellPriceVos).then((res2) => {
              const priceAndStockList = res2.data || []
              const estimatePriceParams = []
              recordList.forEach((item) => {
                const obj = priceAndStockList.find((i) => i.id === item.id) || {}
                item.customerTypePrice.push({ price: obj.currentPrice })
                estimatePriceParams.push({
                  id: item.id,
                  orgId: item.orgId,
                  productId: item.productId,
                  activityId: item.marketing?.activityId || 0,
                  activityType: item.marketing?.activityType || 0,
                  sellPrice: obj.sellPrice
                })
              })
              commit('SET_SEARCH', recordList)
              getEstimatePrice(estimatePriceParams).then((esRes) => {
                const finalData = recordList.map((item) => {
                  const target = esRes.data?.find((esItem) => esItem.id === item.id)
                  return {
                    ...item,
                    estimatePrice: target.estimatePrice
                  }
                })
                commit('SET_SEARCH', finalData)
              })
            })
            fetchStock(sellPriceVos).then((res3) => {
              const stockList = res3.data || []
              recordList.forEach((item) => {
                const obj = stockList.find((i) => i.id === item.id) || {}
                item.availableStock = obj.availableStock
                item.state = obj.availableStock && obj.availableStock > 0 ? 1 : 0
                item.nearEffectDate = obj.nearEffectDate
                item.farEffectDate = obj.farEffectDate
                item.showExpirationWarn = obj.showExpirationWarn
              })
              commit('SET_SEARCH', recordList)
            })
          }
        })
        .catch((error) => {
          resolve(error)
        })
    })
  },

  // 搜索关键词列表--为了搜索关键词列表不跟着改变
  getSuggestList({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      productListByEs(params).then((res) => {
        resolve(res.data)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
