<template>
  <div class="box change_ender_box">
    <div v-if="scene === 'info' && qualificationList?.num" class="tips margin-bottom-20">
      您有<strong>{{ qualificationList?.num }}</strong>个资质已到期，请及时更新
      <router-link to="/center/certification">
        <strong>去更新</strong>
      </router-link>
    </div>
    <div v-if="scene === 'enterprise' && refuseNum > 1" class="tips margin-bottom-20">
      部分信息/资质未通过，请修改后重新提交
    </div>
    <div v-if="customerInfo?.warnState === 1 || customerInfo?.warnState === 2" class="tips margin-bottom-20">
      您有<strong>{{ customerInfo?.lackRequiredVoList.length }}</strong>个资质需补充，请尽快提交
      <router-link :to="{name: 'AddMustCustCert',query: {name: 'AddMustCustCert'}}">
        <strong>去提交</strong>
      </router-link>
    </div>
    <div v-if="enterprises.length > 0" class="change-company text-14">
      <div class="lable">切换企业：</div>
      <Select v-model="customerId" :transfer="true" placement="bottom-end" :disabled="enterprises.length == 1" class="select-company" @on-change="changeCompany">
        <Option
          v-for="item in enterprises"
          :key="item.value"
          class="opt_fejs"
          :value="item.value"
        >
          {{ item.label }}
          <!-- <span class="company-status">已认证</span> -->
        </Option>
        <template #prefix>
          <CustomerTag :color="authenticationState" :name="formatState(authenticationState)" />
        </template>
      </Select>
    </div>
    <div class="margin-bottom-20 text-14">
      <Dropdown v-if="addresses?.length" @on-click="handlerClick">
        <span style="color: rgba(0, 0, 0, 0.6)">
          <!-- <Icon type="ios-pin-outline" color="#777" /> -->
          <img class="local_icon" src="@/assets/images/detail/map-pin.png" alt="" srcset="">
          {{ currentAddress }}
          <Icon v-if="addresses?.length > 1" type="md-arrow-dropdown" />
        </span>
        <DropdownMenu v-if="addresses?.length > 1" slot="list">
          <DropdownItem v-for="item in addresses" :key="item.receiveAddrId" :disabled="addresses?.length == 1" :name="item.receiveAddrId">
            <Icon v-if="item.isCheck === 1" type="md-radio-button-on" color="#F99D33" size="20" />
            <Icon v-else type="md-radio-button-off" color="#DCDCDC" size="20" />
            <span>&nbsp;{{ item.label }}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <router-link to="/affiliated">
      <Button class="relevancy" type="warning">
        <img class="local_icon" src="@/assets/images/detail/link.png" alt="" srcset="">
        关联企业
      </Button>
    </router-link>
  </div>
</template>
<script>
import { obtainCustomerListAPI, switchUserIdentityAPI, getMyCustomerInfoAPI, qualificationMy, getAddrListByCustomerId, switchReceiveAdd } from '@/common/service'
import { setStorageSync } from '@/common/utils/storage'
import { Option } from 'view-design'
import CustomerTag from './CustomerTag.vue'
export default {
  name: 'CHangeEnterprise',
  components: {
    Option,
    CustomerTag
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    scene: {
      type: String,
      default: 'info'
    }
  },
  data() {
    return {
      enterprises: [],
      customerId: '',
      addresses: [],
      currentAddress: '',
      qualificationList: null,
      refuseNum: 0,
      authenticationState: null,
      customerInfo: null
    }
  },
  watch: {
    'currentAddress'(newValue) {
      const info = this.addresses?.find(item => item.label === this.currentAddress)
      if (info) {
        // this.setReceiveAddress(info.receiveAddrId)
      }
    }
  },
  created() {
    this.getCustomerInfo()
    this.getObtainCustomerList()
    this.getQualification()
  },
  mounted() {
    this.customerId = this.id
  },
  methods: {
    formatState(val) {
      const enumObj = {
        'under_auth': '认证中',
        'refuse_auth': '未通过',
        'auth': '已认证'
      }
      return enumObj[val]
    },
    // 切换企业
    async changeCompany(val) {
      console.log('val', val)
      const { code } = await switchUserIdentityAPI(val)
      if (code === 200) {
        this.getCustomerInfo()
        this.$router.go(0)
      }
      this.getQualification()
      this.$emit('changeCompany', val)
      // setStorageSync('customerInfo', this.enterprises.filter(item => {
      //   return item.value === val
      // })[0])
    },
    handlerClick(val) {
      this.addresses?.forEach(item => {
        item.isCheck = 0
        if (item.receiveAddrId === val) {
          item.isCheck = 1
        }
      })
      const info = this.addresses?.find(item => item.receiveAddrId === val)
      if (info) {
        this.currentAddress = info.label
        this.setReceiveAddress(info.receiveAddrId)
      }
      console.log('handlerClick', val, info)
    },
    // 设置默认地址
    async setReceiveAddress(addressId) {
      const { code } = await switchReceiveAdd(addressId)
      if (code === 200) {
        this.$message.success('切换地址成功~')
        location.reload()
      }
    },
    // 获取企业资质信息
    async getQualification() {
      const { code, data } = await qualificationMy()
      if (code === 200 && data?.length > 0) {
        this.qualificationList = data?.[0].qualificationExpiredModel
      } else {
        this.qualificationList = null
      }
    },
    // 获取企业信息
    async getCustomerInfo() {
      const res = await getMyCustomerInfoAPI()
      const { code, data } = res
      if (code === 200) {
        this.authenticationState = data.state
        this.customerInfo = data
        // this.customerInfo.warnState = 1
        // this.customerInfo.lackRequiredVoList = [
        //   {
        //     certificateName: '营业执照正或副本',
        //     certificateCode: null,
        //     category: 'cust',
        //     sortBy: 1,
        //     isRequire: 1,
        //     custOrgTypeId: '5004609819317284',
        //     certificateId: '5004609819317241',
        //     diagram: '20230130/78392e45-c2f3-42e2-bbe6-bf3a7de13063.png',
        //     showState: null,
        //     id: 5004609819317241,
        //     tips: '这是提示语666'
        //   },
        //   {
        //     certificateName: '药品经营许可证',
        //     certificateCode: null,
        //     category: 'cust',
        //     sortBy: 3,
        //     isRequire: 1,
        //     custOrgTypeId: '5004609819317284',
        //     certificateId: '5004609819317243',
        //     diagram: '',
        //     showState: null,
        //     id: 5004609819317243,
        //     tips: null
        //   },
        //   {
        //     certificateName: '法人委托书',
        //     certificateCode: null,
        //     category: 'cust_agent',
        //     sortBy: 8,
        //     isRequire: 1,
        //     custOrgTypeId: '5004609819317284',
        //     certificateId: '5004609819317248',
        //     diagram: 'rym_pub/20231127/c0bcc175-8902-4a35-a228-9142823c9dd2.jpg',
        //     showState: null,
        //     id: 5004609819317248,
        //     tips: '这是提示语dev-请上传法人委托书'
        //   }
        // ]
        this.$set(this, 'customerId', data.customerId)
        this.addresses = data.receiveAddrList?.map(item => {
          item.label = item.receiveDistrict?.provinceName + item.receiveDistrict?.cityName + item.receiveDistrict?.districtName + item?.receiveAddr
          return {
            ...item
          }
        })
        this.addresses = this.addresses?.filter(item => {
          return item.state === 'auth'
        })
        this.refuseNum = data.refuseNum
        this.currentAddress = this.addresses?.filter(item => {
          return item.isCheck === 1
        })[0].label
        setStorageSync('customerInfo', data)
      }
    },
    // 获取企业列表
    async getObtainCustomerList() {
      const { code, data } = await obtainCustomerListAPI()
      if (code === 200) {
        this.enterprises = data.map((item) => {
          item.value = item.customerId
          item.label = item.customName
          return {
            ...item
          }
        })
      }
    },
    toPage(url, param) {
      const { name, path } = this.$router.history.current
      if (![name, path].includes(url)) {
        this.$router.push({
          path: url,
          query: { param }
        })
      }
    }
  }
}
</script>
<style lang="less">
.tips {
  display: flex;
  width:100%;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  background: var(--brand-1, #FFF8E8);

  color: var(--font-black-1, rgba(0, 0, 0, 0.90));
  /* regular/14 */
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  strong {
    color: var(--brand-8, #A35511);
    /* regular/14 */
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
.change-company {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
  .lable{
    color: var(--font_black-1, rgba(0, 0, 0, 0.90));
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    margin-right: 8px;
  }

  .select-company {
    flex: 1;
    .ivu-select-arrow{
      right: inherit;
      color: var(--font_black-1, rgba(0, 0, 0, 0.90)) !important;
    }
    .ivu-select-selection{
      display: flex;
      align-items: center;
      border: none;
    }

    .ivu-select-prefix{
      margin-top: 0px !important;
    }
  }
  .ivu-select-selected-value{
    margin-right: 8px;
    font-size: 14px;
    height: 20px !important;
    line-height: 20px !important;
    margin-top: 1px;

    color: var(--font_black-1, rgba(0, 0, 0, 0.90)) !important;
  }
  .company-status {
    display: inline-block;
    margin-left: 5px;
    padding: 2px 4px;
    color: #a35511;
    font-size: 10px;
    background-color: #fdda9f;
    border-radius: 4px;
  }
}
.relevancy {
  font-size: 14px !important;
  padding:  4px  8px!important;

  .ivu-icon-ios-infinite {
    transform: rotate(-50deg);
  }
}

.ivu-select-prefix {
    float: left;
    margin-top: 5px;
  }
.fejs {
  display: block;
  font-size: 10px;
  padding: 0 4px;
  border: 1px solid #349425;
  border-radius: 4px;
  background-color: rgba(52, 148, 37, 0.08);
  color: #1F7C15;
  margin-right: 8px;
}
.auth_style {
  background: rgba(77, 172, 57, 0.1);
  color: #034D00;
}
.unauth_style {
  background: rgba(249, 157, 51, 0.15);
  color: #A35511
}
.mi_auth_style {
  background: rgba(56, 83, 226, 0.1);
  color: #2438BC;
  border: 1px solid #3853E2;
}
.caption-right:hover{
  color: #3853E2 !important;
  cursor: pointer;
}
.ivu-select-single .ivu-select-selection .ivu-select-head-with-prefix.ivu-select-selected-value {
  float: left;
  margin-left: 8px;
  padding-right: 0px;
}

.local_icon {
  width: 12px;
  transform: translateY(1.5px);
}
</style>
<style>
.change_ender_box{
  .ivu-select-dropdown{
    left: 420px !important;
  }
}

</style>
