<!-- <div class="item_drag" v-show="flag">
</div> -->
<template>
  <!-- 加购组件 -->
  <div class="purchase-wrap">
    <div v-show="show" class="item_drag">
      <div class="content" :style="marginBottom" :class="isGiftGood ? 'gift-content' : ''">
        <div class="container" v-if="!isGroupBuyGood && !isGiftGood" @click.stop="handleDetail">
          <div class="good-section">
            <!-- <image class="good-img" :src="goodObj.mainPicUrl?getFilePath('PS_01',goodObj.mainPicUrl):'/static/images@/assets/images/common/good_default.png'" lazy-load="true" mode="aspectFill" /> -->
            <div class="right-content">
              <div class="price">
                <div class="real-price">
                  <template v-if="goodObj.estimatePrice">
                    <span style="font-size: 12px;vertical-align: middle;margin-right: 4px">折后约</span>
                    <span>¥</span>{{ returnFloat(goodObj.estimatePrice, goodObj.currentPrice) }}
                  </template>
                  <template v-else-if="goodPrice">
                    <span>¥</span>
                    <span>{{ returnFloat(goodPrice) }}</span>
                  </template>
                  <span class="no-price" v-else>未定价</span>
                </div>
                <div v-if="isShowOriginPrice" class="origin-price">
                  <template v-if="goodObj.currentPrice">
                    <span class="tip">¥</span>
                    {{ returnFloat(goodObj.currentPrice) }}
                  </template>
                  <span class="no-price" v-else>未定价</span>
                </div>
                <!-- <div v-if="goodObj.activityType==ACTIVITY_TYPE.SPECIAL||isFullExchangeGood" class="origin-price"><span class="tip">¥</span>{{ returnFloat(goodObj.marketing.customerTypePrice[0].price) }}</div> -->
              </div>
              <h4 class="title line-clamp-2">
                {{ goodObj.displayName ? goodObj.displayName : '' }}
              </h4>
              <div style="display: flex; margin-top: 2px; flex-wrap: wrap">
                <div class="stock">
                  库存：{{ showStock }}
                  <template v-if="goodObj.availableStock">{{ goodObj.productUnit ? goodObj.productUnit : '件' }}</template>
                </div>
                <div class="tip dis_start_end" v-if="goodObj.startBuyNum">
                  起购{{ goodObj.startBuyNum }}{{ goodObj.productUnit ? goodObj.productUnit : '件' }}
                  <div v-if="goodObj.isSingleLimit > 0" class="limit">
                    限购{{ goodObj.singleLimitNum }}{{ goodObj.productUnit ? goodObj.productUnit : '件' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-if="goodObj.expireDate" class="expireDate">
            <div class="label">有效期至</div>
            <div class="txt">{{ goodObj.expireDate.substring(0,11) }}</div>
          </div> -->
          <div class="good-total" v-if="totalPrice">
            <div class="label">单品合计：</div>
            <div class="price">¥{{ returnFloat(totalPrice) }}</div>
          </div>
          <!-- 中包装 开始 -->
          <div class="good-total">
            <div class="label">中/件包装：</div>
            <div class="price">
              {{ goodObj.packageNum ? goodObj.packageNum : '-' }}/{{ goodObj.packageUnitNum ? goodObj.packageUnitNum : '-' }}
              {{ goodObj.allowRetail == 1 ? '可拆零' : '不可拆零' }}
            </div>
          </div>
          <!-- 中包装 结束 -->
          <div class="heig_min">
            <div
              v-if="Object.keys(deliveryFreightInfo).length > 0"
              class="store-tip"
              :class="deliveryFreightInfo.amount >= deliveryFreightInfo.postage ? 'reached-tip' : ''">
              <div v-if="deliveryFreightInfo.amount < deliveryFreightInfo.saleAmount && deliveryFreightInfo.allowed" class="tip-txt">
                满{{ deliveryFreightInfo.saleAmount ? deliveryFreightInfo.saleAmount : 0 }}元起配，还差
                <span class="need-money">{{ deliveryFreightInfo.difference ? returnFloat(deliveryFreightInfo.difference, 0.01) : '0.00' }}元</span>
              </div>
              <div v-else-if="deliveryFreightInfo.amount < deliveryFreightInfo.noPostage" class="tip-txt">
                店铺{{ deliveryFreightInfo.noPostage }}元包邮,距包邮还差
                <span class="need-money">{{ deliveryFreightInfo.difference ? returnFloat(deliveryFreightInfo.difference, 0.01) : '0.00' }}元</span>
              </div>
              <div v-else class="tip-txt">店铺{{ deliveryFreightInfo.noPostage }}元包邮,已满足包邮</div>
            </div>
          </div>
        </div>

        <div class="buy-num" v-if="!disAdd" @click.stop="iptClick()">
          <!-- <div class="label">购买数量</div> -->

          <div class="number-box">
            <div class="minus" @click.stop="minus(count)">
              <svg-icon name="minus" style="width: 10px; height: 10px"></svg-icon>
              <!--              <img class="deal_icon" mode="aspectFill" src="@/assets/product/minus.png">-->
            </div>
            <input
              v-model="count"
              maxlength="7"
              :adjust-position="false"
              type="number"
              class="input"
              :disabled="isNotReady"
              @focus="inputfocus"
              @blur="inputBlur"
              @keyup.enter="inputEnter"
              @change="changeNum(count)" />
            <div class="plus" @click.stop="plus(count)">
              <svg-icon name="plus" style="width: 10px; height: 10px"></svg-icon>
            </div>
          </div>
          <div class="confirm-btn" @click.stop="addGiftCart" v-if="isGiftGood">
            <div class="btn">选择</div>
          </div>
          <div v-if="showOutOfStockRegBtn" class="confirm-btn" @click.stop="handleOutOfStockReg">
            <div class="btn btn2">缺货登记</div>
          </div>
          <div v-else-if="!isGiftGood && !showOutOfStockRegBtn">
            <template v-if="goodObj?.marketing?.activityType == ACTIVITY_TYPE.SPECIAL">
              <button
                v-if="!(isAddCarStatus || isLoading || !originalPrice || goodObj.collect)"
                :class="{ disabled: isDisabled || isNotReady, 'special-btn': true }"
                @click.stop="checkOrgJcStatus">
                立即抢购
              </button>
              <button v-else :class="{ disabled: isDisabled || isNotReady, 'special-btn': true }" @click.stop="nothClick">立即抢购</button>
            </template>
            <template v-else>
              <div class="confirm-btn" @click.stop="checkOrgJcStatus" v-if="!(isAddCarStatus || isLoading || !originalPrice || goodObj.collect)">
                <div class="btn" :class="isDisabled ? 'disabledBtn' : ''" :disabled="isDisabled">
                  <span>加购物车</span>
                  <div class="fixed-price-bg" v-if="goodObj.activityType == ACTIVITY_TYPE.FIXED_PRICE">
                    <svg width="72" height="36" viewBox="0 0 72 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_8009_161749)">
                        <rect width="72" height="36" rx="4" fill="#FF0027" />
                        <path d="M72 0L0 36H72V0Z" fill="#FF4547" />
                      </g>
                      <defs>
                        <clipPath id="clip0_8009_161749">
                          <rect width="72" height="36" rx="4" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="confirm-btn" @click.stop="nothClick" v-else>
                <div class="btn" :class="isDisabled ? 'disabledBtn' : ''" :disabled="isDisabled">加购物车</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- </u-popup> -->
    </div>
    <!-- 检查商家建采状态 开始 -->
    <CheckOrgJcStatus ref="checkOrgJcStatus" @ok="addCart"></CheckOrgJcStatus>
    <!-- 检查商家建采状态 结束 -->
    <!-- 缺货登记 -->
    <OutOfStockReg ref="outOfStockReg" />
  </div>
</template>
<script>
import CheckOrgJcStatus from '@/components/CheckOrgJcStatus/index.vue'
import OutOfStockReg from '@/components/OutOfStockReg.vue'
import {
  addProduct,
  getProductLeftStock,
  getValidPurchaseNum,
  prdControlForClient,
  getProduct,
  getPromotionComputed,
  getByCustomerId,
  getGoodLimit
} from '@/common/service'
import { returnFloat, mul, updateCartNum, debounce, ACTIVITY_TYPE, CONTROL_MSG, getFilePath, setStorage } from '@/common/utils'
import { mapState, mapMutations, mapActions } from 'vuex'
import { Message } from 'view-design'

export default {
  name: 'Purchased',
  components: { CheckOrgJcStatus, OutOfStockReg },
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    goodItem: {
      type: Object,
      default: () => {}
    },
    changeStatus: {
      type: String,
      default: ''
    },
    giftObj: {
      type: Object,
      default: () => {}
    },
    totalNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show: false,
      count: null,
      returnFloat: returnFloat,
      marginBottom: 0,
      isAddCarStatus: false,
      goodObj: {},
      isLoading: true,
      purchasedNum: 0, // 购物车已采购数量
      cartSelectedProductList: [],
      deliveryFreightInfo: '', // 起配、包邮提示文案
      ACTIVITY_TYPE: ACTIVITY_TYPE,
      getFilePath,
      customerObj: {},
      beiExchangeList: [],
      disAdd: 0,
      isEnter: false, // 是否回车键
      stockReady: false
    }
  },
  created() {
    this.disAdd = this.$route.query.disAdd
  },
  watch: {
    showPopup(newVal) {
      this.stockReady = false
      this.close()
      if (newVal) {
        // 71自动满赠 72手动满赠
        if (this.goodItem.activityType == ACTIVITY_TYPE.GIFT_MANUAL && this.goodItem.expandMap?.present == true) {
          this.show = newVal
          this.goodObj = this.goodItem
          this.count = this.goodItem.num
        } else {
          this.checkPurchase()
            .then((res) => {
              if (res) {
                this.show = newVal
                this.isLoading = true
                this.isAddCarStatus = false
                this.goodObj = { ...this.goodItem, availableStock: '' }
                this.getProductStock()
                if (this.goodObj.activityType == ACTIVITY_TYPE.MANUALEXCHANGE && this.goodObj.expandMap?.present == true) {
                  this.getBeiManualExchangeNum()
                }
                //获取限购信息
                if (
                  this.goodObj.activityType == ACTIVITY_TYPE.SPECIAL ||
                  this.goodObj?.activityType == ACTIVITY_TYPE.NEARTERM ||
                  this.goodObj?.activityType == ACTIVITY_TYPE.GROUPBUY ||
                  (this.goodObj?.activityType == ACTIVITY_TYPE.MANUALEXCHANGE && this.goodObj.expandMap?.present == true)
                ) {
                  //润划算、近效期、拼团、手动换购品
                  this.getValidPurchaseNum()
                } else {
                  //原品限购
                  this.getGoodLimit()
                }
                // 获取购物车起配包邮信息
                this.getPromotionInfo()
                this.cartSelectedProductList = this.selectedProductList
              }
            })
            .catch((error) => {
              console.log('error', error)
            })
        }

        console.log('获取商品信息', this.goodObj)
      }
    }
  },
  computed: {
    isDisabled() {
      return this.isAddCarStatus || this.isLoading || !this.originalPrice || this.goodObj.singleLimitNum == 0 || this.goodObj.collect
    },
    isFullExchangeGood() {
      // 是否是被换购商品
      return (
        (this.goodObj.activityType == ACTIVITY_TYPE.AUTOEXCHANGE || this.goodObj.activityType == ACTIVITY_TYPE.MANUALEXCHANGE) &&
        this.goodObj.expandMap?.present == true
      )
    },
    // 缺货登记
    showOutOfStockRegBtn() {
      return !this.goodObj.availableStock || this.goodObj.availableStock == 0
    },
    isShowOriginPrice() {
      //是否展示原价
      return (
        (this.goodObj?.activityType == ACTIVITY_TYPE.SPECIAL ||
          this.isFullExchangeGood ||
          this.goodObj?.activityType == ACTIVITY_TYPE.NEARTERM ||
          this.goodObj?.activityType == ACTIVITY_TYPE.GROUPBUY ||
          this.goodObj?.activityType == ACTIVITY_TYPE.FIXED_PRICE) ||
          this.goodObj?.estimatePrice
      )
    },
    // 跳转详情
    handleDetail() {
      this.$emit('goDetail')
    },
    goodPrice() {
      const { activityPrice, currentPrice } = this.goodObj
      if (this.$route.query.activityType === '99') {
        return activityPrice
      }
      if (this.isShowOriginPrice && currentPrice) {
        return Math.min(Number(activityPrice), Number(currentPrice))
      }
      return (activityPrice || currentPrice)
    },
    // 商品的原价 ERP同步后 可能无商品价格
    originalPrice() {
      return this.goodItem?.currentPrice ?? ''
    },
    totalPrice() {
      if ((!this.goodObj.activityPrice && !this.goodObj.currentPrice) || !this.count) {
        return 0
      }
      return this.isShowOriginPrice
        ? this.goodObj.activityPrice && mul(this.goodObj.activityPrice, this.count)
        : this.goodObj.currentPrice && mul(this.goodObj.currentPrice, this.count)
    },
    isGiftGood() {
      //是否是赠品
      return (
        (this.goodObj.activityType == ACTIVITY_TYPE.GIFT_AUTO || this.goodObj.activityType == ACTIVITY_TYPE.GIFT_MANUAL) &&
        this.goodObj.expandMap?.present == true
      )
    },
    isActivityGood() {
      // 满减活动 11循环满减 12阶梯满减 13满折活动
      return (
        this.goodObj.activityType == ACTIVITY_TYPE.CYCLEMINUS ||
        this.goodObj.activityType == ACTIVITY_TYPE.LADDERMINUS ||
        this.goodObj.activityType == ACTIVITY_TYPE.DISCOUNT ||
        this.goodObj.activityType == ACTIVITY_TYPE.SING_CYCLE_MINUS ||
        this.goodObj.activityType == ACTIVITY_TYPE.SING_LADDER_MINUS
      )
    },
    isGroupBuyGood() {
      // 6拼团
      return this.goodObj.activityType == ACTIVITY_TYPE.GROUPBUY
    },
    ...mapState({
      selectedProductList: (state) => state.car.selectedProductList
    }),
    showStock() {
      // 润划算 近效期 拼团 显示实际库存
      if (['2', '5', '6'].includes(String(this.goodObj.activityType))) {
        return this.goodObj.availableStock
      }
      if (+this.goodObj.availableStock > 100) {
        return '>100'
      }
      if (+this.goodObj.availableStock === 0) {
        if (this.stockReady) {
          return '售罄'
        } else {
          return '-'
        }
      }
      return this.goodObj.availableStock
    },
    isNotReady() {
      // 润划算活动是否未开始
      if (this?.goodObj?.marketing?.activityType != ACTIVITY_TYPE.SPECIAL) {
        return false
      }
      // 对比当前时间是否小于活动开始时间
      return new Date().getTime() < this?.goodObj?.marketing?.startTime
    }
  },
  methods: {
    ...mapMutations({
      setSelectedProductList: 'car/SET_SELECTED_PRODUCT_LIST'
    }),
    async getGoodLimit() {
      try {
        const res = await getGoodLimit({ productId: this.goodObj.id })
        this.goodObj = {
          ...this.goodObj,
          singleLimitNum: res?.data?.residualQty < 0 ? 0 : res?.data?.residualQty,
          isSingleLimit: res?.data?.isLimit,
          limitFlag: res?.data?.limitFlag,
          residualQty: res?.data?.residualQty
        }
      } catch (error) {
        this.$Message.warning(error?.data?.message)
      }
    },
    getBeiManualExchangeNum() {
      // 计算手动被换购品采购数量
      let flag = false
      if (this.selectedProductList?.length > 0) {
        for (const [sIndex, sItem] of this.selectedProductList?.entries()) {
          for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
            for (const [gIndex, gItem] of aItem?.productList?.entries()) {
              if (
                this.goodObj.activityId == aItem.activityId &&
                this.goodObj.id == gItem.productId &&
                this.goodObj.expandMap?.present == gItem.expandMap?.present
              ) {
                this.count = gItem.num
                flag = true
              }
            }
          }
        }
      }
      if (!flag) {
        this.count = this.goodObj.startBuyNum
      }
    },
    nothClick() {
      console.log('nothing')
    },
    getPromotionComputed: debounce(function (count) {
      let selectedProductList = []
      if (this.cartSelectedProductList?.length > 0) {
        for (const sItem of this.cartSelectedProductList) {
          for (const aItem of sItem?.activityList) {
            for (const gItem of aItem?.productList) {
              if (gItem.checked) {
                selectedProductList.push({
                  id: gItem.id,
                  productId: gItem.productId,
                  activityId: aItem.activityId,
                  activityType: aItem.activityType,
                  orgId: gItem.orgId,
                  num: gItem.num,
                  actualPrice: gItem.actualPrice,
                  price: gItem.price
                })
              }
            }
          }
        }
      }
      selectedProductList = selectedProductList.filter((item) => item.orgId == this.goodObj.orgId)
      const productList = [
        {
          actualPrice: this.goodPrice,
          num: count,
          orgId: this.goodObj.orgId,
          selected: true,
          productId: this.goodObj.id, // '5948643838139397'
          activityId: this.goodObj.activityId || 0,
          activityType: this.goodObj.activityType || 0,
          price: this.goodObj.currentPrice
        }
      ]
      if (!this.goodPrice) {
        return
      }
      getPromotionComputed({
        addressCode: this.customerObj.receiveDistrictNo,
        customerTypeId: this.customerObj?.customerTypeId,
        orgId: this.goodObj.orgId,
        productList,
        selectedProductList
      })
        .then((res) => {
          this.deliveryFreightInfo = res?.data ?? {}
        })
        .catch((error) => {
          this.$Message.warning(error?.data?.message)
        })
    }, 500),
    iptClick() {
      return ''
    },
    getPromotionInfo() {
      Promise.all([
        getProduct({
          activityId: this.goodObj.activityId || 0,
          activityType: this.goodObj.activityType || 0,
          productId: this.goodObj.id
        }),
        getByCustomerId()
      ])
        .then((res) => {
          this.purchasedNum = res[0]?.data?.num
          const flag = this.goodObj.activityType == ACTIVITY_TYPE.MANUALEXCHANGE && this.goodObj.expandMap?.present == true
          if (!flag) {
            // 非手动换购品 expandMap
            this.count = this.purchasedNum ? this.purchasedNum : this.goodObj.startBuyNum
          }
          // 选中的收货地址
          this.customerObj = res[1]?.data?.receiveAddrList?.find((item) => item.isCheck == 1) || {}
          // 调用算价接口
          this.getPromotionComputed(this.count)
        })
        .catch((error) => {
          this.$Message.warning(error?.data?.message)
        })
    },
    // 校验是否打开弹窗组件
    checkPurchase() {
      const params = {
        products: [
          {
            activeId: this.goodItem.activityId || 0,
            activityType: this.goodItem.activityType || 0,
            productId: this.goodItem.id
          }
        ]
      }
      return new Promise((resolve, reject) => {
        resolve(true)
        // prdControlForClient(params).then((res) => {
        //   if (res?.data?.length > 0) {
        //     this.close()
        //     this.$Message.warning(CONTROL_MSG[res?.data[0].reason])
        //     resolve(false)
        //   } else {
        //     resolve(true)
        //   }
        // }).catch((error) => {
        //   this.close()
        //   reject(error)
        // })
      })
    },
    getValidPurchaseNum() {
      const params = {
        activityId: this.goodObj.activityId,
        productId: this.goodObj.id
      }
      getValidPurchaseNum(params)
        .then((res) => {
          // 0 "不限购" 1 "单笔订单限购"  2 "单个客户限购"
          this.goodObj = { ...this.goodObj, singleLimitNum: res?.data?.purchaseNum < 0 ? 0 : res?.data?.purchaseNum, isSingleLimit: res?.data?.maxPurchaseType }
        })
        .catch((error) => {
          this.isAddCarStatus = false
          this.$Message.warning(error?.data?.message)
        })
    },
    getProductStock() {
      //2023.7.29 zcx 查询库存接口，传参要求：如果活动类型是71、72，传参活动类型、活动ID传0
      if (this.goodObj.activityType == 71 || this.goodObj.activityType == 72) {
        this.goodObj.activityId = 0
        this.goodObj.activityType = 0
      }
      const params = {
        activityId: this.goodObj.activityId || 0,
        activityType: this.goodObj.activityType || 0,
        productId: this.goodObj.id
      }
      getProductLeftStock(params)
        .then((res) => {
          this.isLoading = true
          this.goodObj = { ...this.goodObj, availableStock: res?.data?.availableStock, expireDate: res?.data?.nearEffectDate }
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          this.$Message.warning(error?.data?.message)
        })
        .finally(() => {
          this.stockReady = true
        })
    },
    // 失焦的时候
    inputBlur() {
      this.marginBottom = 'margin-bottom:0'
    },
    // 获取焦点的时候
    inputfocus(e) {
      this.marginBottom = 'margin-bottom:' + e.detail.height * 2 + 'rpx' // 我这里是小程序所以x2了
    },
    addGiftCart() {
      //选择赠品
      if (this.count > this.goodObj.availableStock) {
        this.$Message.warning('库存不足，数量修改失败')
        this.count =
          Number(this.goodObj.availableStock) - ((Number(this.goodObj.availableStock) - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
        if (this.count < 0) {
          this.count = 0
        }
      } else if (this.totalNum + (this.count - this.goodObj.num) > this.giftObj.stagePurchaseLimit) {
        this.$Message.warning(`最多可加购${this.giftObj.stagePurchaseLimit}件`)
        let lefNum = this.count - (this.totalNum + (this.count - this.goodObj.num) - this.giftObj.stagePurchaseLimit)
        this.count = lefNum - ((lefNum - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
        if (this.count < 0) {
          this.count = 0
        }
      } else if (this.count < 0) {
        this.$Message.warning(`${this.goodObj.startBuyNum}${this.goodObj.productUnit}起购`)
        this.count = 0
      }
      this.$emit('computedNum', this.count)
      this.close()
    },
    //处理购物车切换页面后能够命中切换后的促销而不是最优的
    saveGoodInfo(res) {
      if (this.selectedProductList?.length > 0) {
        let selectObj = {}
        for (const [sIndex, sItem] of this.selectedProductList?.entries()) {
          for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
            if (this.goodObj.activityId == aItem.activityId) {
              selectObj = {
                sIndex,
                aIndex
              }
            }
          }
        }
        const productList = this.selectedProductList[selectObj.sIndex]?.activityList[selectObj.aIndex]?.productList || []
        const cIndex = productList?.findIndex((item) => item.productId == this.goodObj.id && item.expandMap?.present == this.goodObj.expandMap?.present)
        if (cIndex != -1) {
          this.selectedProductList[selectObj.sIndex].activityList[selectObj.aIndex].productList.splice(cIndex, 1, {
            productId: this.goodObj.id,
            activityId: this.goodObj.activityId,
            activityType: this.goodObj.activityType,
            orgId: this.goodObj.orgId,
            num: this.count,
            actualPrice: this.goodPrice,
            price: this.goodObj.currentPrice,
            checked: this.selectedProductList[selectObj.sIndex].activityList[selectObj.aIndex].productList[cIndex].checked,
            id: res.data
          })
        } else {
          const obj = {
            productId: this.goodObj.id,
            activityId: this.goodObj.activityId,
            activityType: this.goodObj.activityType,
            orgId: this.goodObj.orgId,
            num: this.count,
            actualPrice: this.goodPrice,
            price: this.goodObj.currentPrice,
            checked: true,
            id: res.data
          }
          this.selectedProductList[selectObj.sIndex]?.activityList[selectObj.aIndex]?.productList?.push(obj)
        }
        this.setSelectedProductList(JSON.parse(JSON.stringify(this.selectedProductList)))
      }
      const cacheObj = {
        productId: this.goodObj.id,
        activityId: this.goodObj.activityId,
        activityType: this.goodObj.activityType,
        orgId: this.goodObj.orgId,
        num: this.count,
        actualPrice: this.goodPrice,
        price: this.goodObj.currentPrice,
        checked: true,
        id: res.data
      }
      const cachedProductList = JSON.parse(localStorage.getItem('cachedProductList') || '[]')
      cachedProductList.push(cacheObj)
      localStorage.setItem('cachedProductList', JSON.stringify(cachedProductList))
    },
    handleBeiExchangeGoods() {
      // 手动加购换购品处理
      if (this.selectedProductList?.length > 0) {
        let selectObj = {}
        for (const [sIndex, sItem] of this.selectedProductList?.entries()) {
          for (const [aIndex, aItem] of sItem?.activityList?.entries()) {
            if (this.goodObj.activityId == aItem.activityId) {
              selectObj = {
                sIndex,
                aIndex
              }
            }
          }
        }
        const productList = this.selectedProductList[selectObj.sIndex]?.activityList[selectObj.aIndex]?.productList || []
        const cIndex = productList?.findIndex((item) => item.productId == this.goodObj.id && item.expandMap?.present == this.goodObj.expandMap?.present)
        if (cIndex != -1) {
          this.selectedProductList[selectObj.sIndex].activityList[selectObj.aIndex].productList.splice(cIndex, 1, {
            productId: this.goodObj.id,
            activityId: this.goodObj.activityId,
            activityType: this.goodObj.activityType,
            orgId: this.goodObj.orgId,
            selected: true,
            num: this.count,
            actualPrice: this.goodPrice,
            price: this.goodObj.currentPrice,
            expandMap: { present: true },
            checked: true
          })
        } else {
          this.selectedProductList[selectObj.sIndex]?.activityList[selectObj.aIndex]?.productList?.push({
            productId: this.goodObj.id,
            activityId: this.goodObj.activityId,
            activityType: this.goodObj.activityType,
            orgId: this.goodObj.orgId,
            selected: true,
            num: this.count,
            actualPrice: this.goodPrice,
            price: this.goodObj.currentPrice,
            expandMap: { present: true },
            checked: true
          })
        }
        this.setSelectedProductList(JSON.parse(JSON.stringify(this.selectedProductList)))
        Message.success('加入购物车成功')
        this.$store.dispatch('car/updateCartNum')
        this.close()
        setTimeout(() => {
          this.$router.push('/car')
        }, 2000)
      }
    },
    addCart: debounce(function () {
      if (this.goodItem.marketing) {
        if (this.goodItem.marketing.startTime > new Date().getTime()) {
          this.$Message.warning('活动未开始不可加购')
          return
        }
        if (this.goodItem.marketing.endTime < new Date().getTime()) {
          this.$Message.warning('活动已结束')
          return
        }
      }
      //   if (this.count > this.goodObj.availableStock) {
      //     this.$Message.warning('库存不足，加购失败')
      //     return
      //   }
      if (this.count > this.goodObj.singleLimitNum && this.goodObj.isSingleLimit > 0) {
        this.$Message.warning(this.getTip(this.goodObj))
        return
      }
      if (this.count < this.goodObj.startBuyNum) {
        this.$Message.warning(`${this.goodObj.startBuyNum}${this.goodObj.productUnit}起购`)
        return
      }
      const val = (this.count - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum)
      if (val != 0) {
        this.$Message.warning(`商家设置加购倍数为${this.goodObj.addBuyNum}`)
        return
      }
      if (this.count > 9999999) {
        this.$Message.warning(`购买件数已达到上限哦`)
        return
      }
      //手动加购换购品处理
      if (this.goodObj.activityType == ACTIVITY_TYPE.MANUALEXCHANGE && this.goodObj.expandMap?.present == true) {
        this.handleBeiExchangeGoods()
        return
      }
      const addCarNum = this.count > this.goodObj.availableStock ? this.goodObj.availableStock : this.count
      this.isAddCarStatus = true
      const params = {
        productId: this.goodObj.id,
        orgId: this.goodObj.orgId,
        num: addCarNum,
        activityId: this.goodObj.activityId || 0,
        activityType: this.goodObj.activityType || 0
      }
      addProduct(params)
        .then((res) => {
          this.isAddCarStatus = false
          this.$store.dispatch('car/updateCartNum')
          //   如果加购数量大于库存数量，就提示缺货登记
          if (this.count > this.goodObj.availableStock) {
            this.$refs.outOfStockReg?.init(
              {
                ...this.goodObj,
                ...this.goodItem,
                productId: this.goodObj.id,
                carNum: addCarNum,
                restNum: this.count - this.goodObj.availableStock
              },
              'car'
            )
          } else {
            Message.success('加入购物车成功')
          }

          this.close()
          updateCartNum().then(() => {
            this.$emit('addProduct', this.count)
          })
          if (this.goodObj.expandMap?.present != true) {
            this.saveGoodInfo(res)
          }
        })
        .catch((err) => {
          // code:1进行中 2预热中 3未开始 4已结束
          this.isAddCarStatus = false
          // 如果当前登录用户必要信息缺失600 用户未绑定客户提示：601
          if (err?.data?.code == '600' || err?.data?.code == '601' || err?.data?.code == '602') {
            this.$Message.warning('当前账号未关联企业，请先关联')
            return
          } else if (err?.data?.code == '603') {
            this.alertMessage('资质审核中，请耐心等待')
            return
          }
          this.$Message.error(err?.data?.message)
        })
    }, 500),
    handleOutOfStockReg() {
      this.$refs.outOfStockReg?.init({
        ...this.goodObj,
        ...this.goodItem,
        productId: this.goodObj.id,
        restNum: this.count
      })
    },
    close() {
      this.marginBottom = 'margin-bottom:0'
      this.show = false
      this.$emit('update:showPopup', this.show)
    },
    getTip(goodObj) {
      if (this.goodObj.activityId != 0) {
        //活动商品限购
        return goodObj.residualQty <= 0 ? `限购已达上限` : `剩余采购${goodObj.singleLimitNum}${goodObj.productUnit}`
      } else {
        //非活动商品限购
        if (goodObj.limitFlag == 'limit_single') {
          return `单笔限购${goodObj.singleLimitNum}`
        } else if (goodObj.limitFlag === 'limit_day') {
          return goodObj.residualQty <= 0 ? `当天限购已达上限` : `当天剩余采购${goodObj.singleLimitNum}`
        } else if (goodObj.limitFlag === 'limit_month') {
          return goodObj.residualQty <= 0 ? `当月限购已达上限` : `当月剩余采购${goodObj.singleLimitNum}`
        } else if (goodObj.limitFlag === 'limit_all') {
          return `商品限销，剩余采购${goodObj.residualQty}`
        } else {
          return ''
        }
      }
    },
    minus(count) {
      if (this.isNotReady) {
        // 润划算未开始
        return
      }
      this.count = Number(count)
      this.count -= Number(this.goodObj.addBuyNum)
      if (this.isGiftGood) {
        return
      }
      if (this.count < this.goodObj.startBuyNum) {
        this.$Message.warning(`${this.goodObj.startBuyNum}${this.goodObj.productUnit}起购`)
        this.count = this.goodObj.startBuyNum
      }
      this.getPromotionComputed(this.count)
    },
    plus(count) {
      if (this.isNotReady) {
        // 润划算未开始
        return
      }
      this.count = Number(count)
      this.count += Number(this.goodObj.addBuyNum)
      if (this.isGiftGood) {
        return
      }
      //   if (this.count > this.goodObj.availableStock) {
      //     this.$Message.warning('库存不足，数量修改失败')
      //     this.count =
      //       Number(this.goodObj.availableStock) - ((Number(this.goodObj.availableStock) - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
      //   }
      if (this.count > this.goodObj.singleLimitNum && this.goodObj.isSingleLimit > 0) {
        this.$Message.warning(this.getTip(this.goodObj))
        this.count =
          Number(this.goodObj.singleLimitNum) - ((Number(this.goodObj.singleLimitNum) - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
      } else if (this.count > 9999999) {
        this.$Message.warning('购买件数已达到上限哦')
        this.count = 9999999 - ((9999999 - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
      }
      if (this.count < this.goodObj.startBuyNum) {
        this.$Message.warning(`${this.goodObj.startBuyNum}${this.goodObj.productUnit}起购`)
        this.count = this.goodObj.startBuyNum
      }
      if (this.count > 0) {
        this.getPromotionComputed(this.count)
      }
    },
    // 加购输入框回车事件
    inputEnter(e) {
      this.isEnter = true
      setTimeout(() => {
        this.isEnter = false
      }, 500)
      if (this.isGiftGood) {
        return
      }
      //   if (this.count > this.goodObj.availableStock) {
      //     this.$Message.warning(`库存不足，数量修改失败`)
      //     this.count =
      //       Number(this.goodObj.availableStock) - ((Number(this.goodObj.availableStock) - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
      //     return
      //   }
      if (this.count > this.goodObj.singleLimitNum && this.goodObj.isSingleLimit > 0) {
        this.$Message.warning(this.getTip(this.goodObj))
        this.count =
          Number(this.goodObj.singleLimitNum) - ((Number(this.goodObj.singleLimitNum) - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
        return
      }
      if (this.count < this.goodObj.startBuyNum) {
        this.$Message.warning(`${this.goodObj.startBuyNum}${this.goodObj.productUnit}起购`)
        this.count = this.goodObj.startBuyNum
        return
      }
      const val = (this.count - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum)
      if ((this.count - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum) != 0) {
        this.$Message.warning(`商家设置加购倍数为${this.goodObj.addBuyNum}`)
        this.count = this.count - val
        return
      } else if (this.count > 9999999) {
        this.$Message.warning('购买件数已达到上限哦')
        this.count = 9999999 - ((9999999 - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
        return
      }
      if (this.count < this.goodObj.startBuyNum) {
        this.$Message.warning(`${this.goodObj.startBuyNum}${this.goodObj.productUnit}起购`)
        this.count = this.goodObj.startBuyNum
        return
      }
      if (this.count > 0) {
        this.addCart()
      }
    },
    // 商品数量改变
    changeNum(count) {
      this.count = count ? Number(count) : ''
      if (this.isGiftGood) {
        return
      }
      setTimeout(() => {
        if (this.isEnter === true) {
          return
        }
        // if (this.count > this.goodObj.availableStock) {
        //   this.$Message.warning(`库存不足，数量修改失败`)
        //   this.count =
        //     Number(this.goodObj.availableStock) - ((Number(this.goodObj.availableStock) - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
        // }
        if (this.count > this.goodObj.singleLimitNum && this.goodObj.isSingleLimit > 0) {
          this.$Message.warning(this.getTip(this.goodObj))
          this.count =
            Number(this.goodObj.singleLimitNum) - ((Number(this.goodObj.singleLimitNum) - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
        }
        if (this.count < this.goodObj.startBuyNum) {
          this.$Message.warning(`${this.goodObj.startBuyNum}${this.goodObj.productUnit}起购`)
          this.count = this.goodObj.startBuyNum
        }
        const val = (this.count - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum)
        if ((this.count - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum) != 0) {
          this.$Message.warning(`商家设置加购倍数为${this.goodObj.addBuyNum}`)
          this.count = this.count - val
        } else if (this.count > 9999999) {
          this.$Message.warning('购买件数已达到上限哦')
          this.count = 9999999 - ((9999999 - Number(this.goodObj.startBuyNum)) % Number(this.goodObj.addBuyNum))
        }
        if (this.count < this.goodObj.startBuyNum) {
          this.$Message.warning(`${this.goodObj.startBuyNum}${this.goodObj.productUnit}起购`)
          this.count = this.goodObj.startBuyNum
        }
        if (this.count > 0) {
          this.getPromotionComputed(this.count)
        }
      }, 300)
    },
    alertMessage(val) {
      Message.success(val)
    },
    // 检查商家建采信息
    checkOrgJcStatus() {
      const orgId = this.goodItem.orgId
      this.$refs.checkOrgJcStatus.checkBuyStatus(orgId)
    }
  }
}
</script>
<style scoped lang="less">
.purchase-wrap {
  .content {
    padding: 16px;
    min-height: 130px;
    margin-top: 0;
    display: block;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);

    .container {
      width: 100%;
      box-sizing: border-box;
    }

    .good-section {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;

      .good-img {
        width: 160rpx;
        height: 160rpx;
        border-radius: 8rpx;
      }

      .right-content {
        width: 100%;
        .title {
          //width: 200px;
          overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // -o-text-overflow:ellipsis;
          max-height: 40px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.9);
          word-break: break-all;
        }

        .price {
          height: 28px;
          overflow: hidden;
          width: 100%;

          .real-price {
            display: inline-block;
            font-weight: 500;
            font-size: 20px;
            color: #cb272d;
            line-height: 28px;

            .no-price {
              font-size: 16px;
            }
          }

          .origin-price {
            margin-left: 4px;
            font-weight: 400;
            font-size: 14px;
            text-decoration-line: line-through;
            display: inline-block;
            color: rgba(0, 0, 0, 0.6);

            .tip {
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }

        .stock {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #666;
          margin-right: 10px;
        }

        .tip {
          font-weight: 400;
          font-size: 12px;
          // display: flex;
          line-height: 16px;
        }
      }
    }

    .expireDate {
      margin-top: 62rpx;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        font-weight: 500;
        font-size: 28rpx;
        line-height: 44rpx;
        color: rgba(0, 0, 0, 0.9);
      }

      .txt {
        margin-left: auto;
        font-weight: 500;
        font-size: 28rpx;
        line-height: 44rpx;
        color: rgba(0, 0, 0, 0.9);
      }
    }

    .buy-num {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2px;

      .label {
        font-weight: 500;
        font-size: 28rpx;
        line-height: 44rpx;
        color: rgba(0, 0, 0, 0.9);
      }

      .tip {
        font-weight: 400;
        line-height: 40rpx;
        color: rgba(0, 0, 0, 0.6);
      }

      .number-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 116px;
        height: 36px;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: hidden;

        .input {
          width: 60px;
          height: 36px;
          text-align: center;
          color: var(--font-black-1, rgba(0, 0, 0, 0.9));
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 36px;
          box-sizing: border-box;
          border: none;
          outline: none;
        }

        // nput[type=number] {
        //     -moz-appearance: textfield;
        // }
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .minus,
        .plus {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 28px;
          height: 36px;
          background: #f5f5f5;
          font-size: 10px;

          &:hover {
            color: #f99d33;
          }
        }

        .minus {
          border-right: 1px solid #dcdcdc;
        }

        .plus {
          border-left: 1px solid #dcdcdc;
        }

        .minus .deal_icon {
          width: 10px;
          height: 10px;
        }

        .plus .deal_icon {
          width: 10px;
          height: 10px;
        }
      }
    }

    .good-total {
      display: flex;
      align-items: center;
      line-height: 16px;
      color: #666;
      font-size: 12px;
    }

    .heig_min {
      min-height: 23px;
      padding-top: 5px;
      margin-bottom: 4px;
    }

    .store-tip {
      width: 100%;
      padding: 16rpx 32rpx;
      box-sizing: border-box;
      height: 72rpx;

      .tip-txt {
        font-weight: 400;
        font-size: 12px;
        color: #666;
        line-height: 16px;

        .need-money {
          color: #f53f3f;
        }
      }
    }

    .reached-tip {
      // margin-top: 5px;
      .tip-txt {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .confirm-btn {
      margin-left: 8px;
      .btn {
        color: #fafafa;
        width: 72px;
        height: 36px;
        background: #f99d33;
        border-radius: 4px;
        cursor: pointer;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 500;
        span {
          z-index: 2;
        }

        .fixed-price-bg {
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0;
        }
      }
      .btn2 {
        background: #f7ba1e;
      }

      .disabledBtn {
        background: #fdda9f;

        &::after {
          border: none;
        }
      }
    }
  }

  .gift-content {
    min-height: auto;
  }
}

.item_drag {
  width: 100%;
  // height: 177px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  // box-shadow: 6px 6px 10px 10px #f7eeee;
}

.dis_start_end {
  display: flex;
  color: #666;
}

.limit {
  color: #ce7720;
  padding-left: 5px;
  margin-left: 5px;
  position: relative;

  &:before {
    content: '';
    height: 12px;
    width: 1px;
    background: #666;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.special-btn {
  margin-left: 8px;
  border: 0;
  width: 72px;
  height: 36px;
  cursor: pointer;
  background: url('~@/assets/productCards/time-special-btn.png') no-repeat;
  background-size: 72px 36px;
  border-radius: 4px;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #fff;
  line-height: 36px;
  padding-right: 6px;
  text-align: right;

  &.disabled {
    background: url('~@/assets/productCards/buy-btn-disabled.png') no-repeat;
    background-size: 72px 36px;
  }
}
.estimated-price {
  align-self: center;
  border-radius: 8px;
  background: #fbebe7;
  height: 22px;
  line-height: 24px;
  padding: 0 8px;
  color: #d4380d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
</style>
