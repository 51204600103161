// 所商品
<template>
  <div class="v-many-product" v-if="list?.length> 0" :id="'nav' + options.id">
    <div class="head">
      <template v-if="hasTitle !== '2'">
        <ContentTitle :title="options?.props?.title"></ContentTitle>
        <div class="more" @click="more">
          更多
          <Icon style="margin-left: 4px;" type="ios-arrow-forward" />
        </div>
      </template>
    </div>
    <div class="row-list">
      <ProductCard
        card
        :goodsItem = "item"
        :isOrgAbbr = "false"
        v-for="(item, index) in list"
        class="list_item"
        :key="index"
        :imgLazy="true"
        :showAddCar="false"
        @click="detaiHandler(item, this.options.props.type)">
      </ProductCard>
    </div>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { cmsLink , getData, detaiHandler} from '@/views/home/index'
import ContentTitle from '@/components/ContentTitle'
// import ProductCard from '@/views/goodsItem'
import ProductCard from '@/components/ProductCards/index.vue'
export default {
  name: "v-many-product",
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      cmsLink: cmsLink.bind(this),
      list: [],
    };
  },
  computed: {
    hasTitle() {
      return this.options?.props?.hasTitle ?? ''
    }
  },
  components: {
    ContentTitle,ProductCard
  },
  created() {
    getData.bind(this)(this.options.props?.styleType).then(() => {
      this.$emit('load', this.options)
    }).catch(() => {
      this.$emit('load', this.options)
    })
  },
  methods: {
    // 跳更多页
    more(data) {
      let cmsModelId = this.cmsId  + "_" + this.options.id
      let type = this.options?.props?.type
      this.$router.push(`/more?type=${type}&cmsModelId=${cmsModelId}`)
    }
  },
  watch: {
    list: {
      handler(val) {
        if( val.length> 0 )
          this.$store.commit('setHomeNav', this.options.id)
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="less">
@import url('../../product.less');
.v-many-product {
  width: 1200px;
  // margin: 0 auto;
}
.row-list {
  display: flex;
  flex-wrap: wrap;
  gap:15px;
  .list_item {
    //margin-right: 15px;
    //margin-top: 24px;
    &:nth-child(-n + 5) {
      margin-top: 0px;
    }
  }
}
.head {
  display: flex;
  align-items: center;
  .more {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #181818;
    cursor: pointer;
    width: 60px;
    margin-bottom: 24px;
  }
}
::v-deep .root {
  margin-top: 0;
}
</style>
