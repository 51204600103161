<template>
  <div>
    <div id="navtop" class="top-menu-wrapper" :style="[topPosition]">
      <div class="top-menu-wrapper_content">
        <div class="left">
          <!-- <span class="location"><Icon class="location-icon" type="ios-pin-outline" />{{ province }}</span> -->
          <span v-if="topConfig.welcomeText" class="welcome">
            {{ topConfig.welcomeText }}
          </span>
          <span v-if="!topConfig.welcomeText" class="welcome">欢迎来到润药商城！</span>
          <span v-show="relateCorp" class="top-shop-name" @click="changeCustom">
            {{ selectCustom.customName || '默认企业' }}
            <Icon :type="!showCustomeList ? 'ios-arrow-down' : 'ios-arrow-up'" />
          </span>

          <span v-if="hasLogin" class="quit" @click="quit">退出</span>
        </div>
        <div class="right">
          <div class="item" @click="goHome">首页</div>
          <div class="item" @click="openPage('/center/index')">个人中心</div>
          <div class="item" @click="myOrderHandler">我的订单</div>
          <div v-if="hasLogin" class="item" @click="openPage('/helperCenter')">帮助中心</div>
          <div v-if="hasLogin" class="item" @click="openPage('/feedback/index')">意见反馈</div>
          <div v-if="topConfig.firstImg || topConfig.secondImg" class="item mobile">
            <!-- <img :src="require('@/assets/login/ipone.png')" alt=""> -->
            <svg-icon name="smartphone" class-name="smartphone-icon" />
            <svg-icon name="smartphone-hover" class-name="smartphone-icon-hover" />
            手机版
            <div class="mobile-content">
              <div v-if="topConfig.firstImg" class="mobile-content-item">
                <img :src="getFilePath('PS_01', topConfig.firstImg)">
              </div>
              <div v-if="topConfig.secondImg" class="mobile-content-item">
                <img :src="getFilePath('PS_01', topConfig.secondImg)">
              </div>
            </div>
          </div>
          <div v-if="topConfig.linkMethod" class="item">客服热线：{{ topConfig.linkMethod }}</div>
          <Dropdown @on-click="handleDownload">
            <div class="item">
              资料下载
              <Icon type="ios-arrow-down" />
            </div>
            <template #list>
              <DropdownMenu>
                <DropdownItem name="doc">首营资料</DropdownItem>
                <DropdownItem name="report">药检报告</DropdownItem>
                <DropdownItem name="invoice">电子发票</DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <div v-show="showCustomList" class="shop-name">
      <div class="list">
        <h3 class="title">选择企业</h3>
        <div class="item-content">
          <RadioGroup v-model="selectedCancleOrder" placement="column" @on-change="confirm">
            <Radio v-for="(item, index) in dataList" :key="index" class="list-item" active-color="#ff9900" :label="item.customerId">
              <div class="content-box">
                <div class="custome-name">
                  {{ item.customName }}
                </div>
                <div class="custome-addr">
                  <span>{{ item.districtName }}</span>
                  <span>{{ item.receiveAddr }}</span>
                </div>
              </div>
              <div v-if="item.addrCount > 1" class="change-address" @click.self.stop="chooseAddress(item.customerId, item.customName, item, $event)">
                切换地址
              </div>
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="showAddress" class="address-wrapper" :style="{ top: `calc(${top}px - 60px)` }">
          <div class="address-content">
            <div class="address-input">
              <Input v-model="inputValue" search placeholder="搜索" clearable enter-button @on-search="searchAddress" @on-clear="onClear" />
            </div>
            <RadioGroup v-model="addressId" placement="column" style="margin-top: 50px" @on-change="confirmAddress">
              <Radio v-for="(item,index) in dataListAddress" :key="index" class="address-content-item" active-color="#ff9900" :label="item.receiveAddrId">
                <div class="content">
                  <div class="address">
                    {{ item.receiveDistrict.provinceName + item.receiveDistrict.cityName + item.receiveDistrict.districtName + item.receiveAddr }}
                  </div>
                  <div class="phone">
                    <div>{{ item.receiveContact + ' ' + item.receiveTel }}</div>
                  </div>
                </div>
              </Radio>
            </RadioGroup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { getAddrListByCustomerId, getLogout, obtainCustomerListAPI, switchReceiveAdd, switchUserIdentityAPI } from '@/common/service'
import { getConfigByCode } from '@/common/service/cms'
import router from '@/router'
import { clearLoginInfo, getStorageSync, setStorageSync } from '@/common/utils'
import { mapMutations } from 'vuex'
export default {
  name: 'TopMenu',
  props: {
    msg: String
  },
  data() {
    return {
      showCustomeList: false,
      selectedCancleOrder: null,
      customerId: null,
      dataList: [],
      customerName: '',
      chooseId: null, // 已选择地址id
      addressId: null,
      dataListAddress: [],
      selectCustom: {},
      showMobile: false,
      relateCorp: false,
      showAddress: false,
      showCustomList: false,
      topConfig: {
        firstImg: '',
        linkMethod: '',
        secondImg: '',
        welcomeText: ''
      },
      top: 0,
      selectedItem: {},
      inputValue: '',
      originData: [] // 客户地址所有数据
    }
  },
  inject: ['topApp'],
  computed: {
    hasLogin() {
      return getStorageSync('accessToken') || null
    },
    province() {
      if (!this.selectCustom?.districtName) {
        return null
      }
      const splitKey = this.selectCustom?.districtName.includes('省') ? '省' : '区'
      let result = this.selectCustom?.districtName?.split(splitKey)[0] || '默认'
      if (splitKey === '区') {
        result = result + '区'
      }
      return result
    },
    topPosition() {
      let position = {
        position: 'sticky'
      }
      if (this.$route.name == 'Home') {
        position = {
          position: 'relative'
        }
      }
      return position
    }
  },
  async created() {
    if (this.hasLogin) {
      await this.obtainCustomerList()
    }
    await this.getTopMenuConfig()
  },
  mounted() {
    const $list = document.querySelector('.shop-name ')
    $list.addEventListener('click', this.bodyClick)
  },
  beforeDestroy() {
    const $list = document.querySelector('.shop-name ')
    $list?.removeEventListener('click', this.bodyClick)
  },
  methods: {
    ...mapMutations({
      setKeyword: 'product/SET_KEYWORD'
    }),
    showAddressFn(e) {
      this.showAddress = false
    },
    handleDownload(val) {
      if (val === 'report') {
        this.openPage('/download/report')
      } else if (val === 'doc') {
        this.openPage('/download/document')
      } else if (val === 'invoice') {
        this.openPage('/download/invoice')
      }
    },
    showCustomListFn(e) {
      this.showCustomList = !this.showCustomList
    },
    changeCustom() {
      this.showCustomList = !this.showCustomList
    },
    bodyClick(e) {
      const left = document.querySelector('.shop-name .list')
      if (left && !left.contains(e.target)) {
        this.showCustomList = false
      }
    },
    getFilePath,
    // 获取企业列表
    async obtainCustomerList() {
      const res = await obtainCustomerListAPI()
      const data = res.data
      this.dataList = data
      // if (!this.dataList || this.dataList.length === 0) {
      if (!this.dataList?.length && this?.topApp?.showAttachCompanyInfo) {
        this.$store.commit('setDisassociationState', false)
        this.toDisassociation()
      } else if (res?.code === '603') {
        this.$store.commit('setDisassociationState', true)
        this.$message.error('资质审核中，请耐心等待')
      } else {
        this.$store.commit('setDisassociationState', true)
        this.relateCorp = true
      }
      this.dataList?.forEach((item) => {
        if (item.inUse) {
          this.selectCustom = item
          this.selectedCancleOrder = this.customerId = item.customerId
        }
      })
    },
    // 去关联
    toDisassociation() {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>请先关联采购主体（药店/医院/诊所/企业）。未关联前无法获取商品全部内容以及采购下单</p>',
        okText: '去关联',
        cancelText: '暂不',
        onOk: () => {
          router.push('/affiliated')
        },
        onCancel: () => {
          // this.$message.info('Clicked cancel')
        }
      })
    },
    myOrderHandler() {
      if (!this.dataList.length && this?.topApp?.showAttachCompanyInfo) {
        this.toDisassociation()
      } else {
        this.openPage('/order')
      }
    },
    // 确定企业
    async confirm(item) {
      let time = 0
      if (item.controlState === 'frozen') {
        time = 2000
        this.$message.error('您的账号已冻结，请联系平台运营人员处理。')
      }
      console.log('name', this.$route.name)
      if (this.$route.name == 'wxPay' || this.$route.name == 'orderPay') {
        this.$Modal.confirm({
          title: '提示',
          content: '<p>订单还未支付，请确定是否取消付款</p>',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            setTimeout(async() => {
              const data = await switchUserIdentityAPI(this.selectedCancleOrder)
              if (data.code === 200) {
                this.selectCustom = this.dataList.find((itemX) => itemX.customerId === item) || {}
                this.$store.commit('setLoginOutRefresh')
                this.$router.push('/')
              } else {
                this.$message.error(data.data)
              }
            }, time)
          },
          onCancel: () => {
            console.log('customerId', this.customerId)
            this.selectedCancleOrder = this.customerId
          }
        })
        return
      }
      setTimeout(async() => {
        const data = await switchUserIdentityAPI(this.selectedCancleOrder)
        if (data.code === 200) {
          this.selectCustom = this.dataList.find((itemX) => itemX.customerId === item) || {}
          this.$store.commit('setLoginOutRefresh')
          location.reload()
        } else {
          this.$message.error(data.data)
        }
      }, time)
    },
    chooseAddress(customerId, customerName, item, event) {
      this.top = event.clientY
      // this.$set(item, 'changeAddress', item.changeAddress === undefined ? true : !item.changeAddress)
      /*      this.dataList.forEach(data => {
        this.$set(data, 'changeAddress', false)
      })
      this.getDataListAddress(customerId, () => {
        this.$set(item, 'changeAddress', true)
      })*/
      this.getDataListAddress(customerId, () => {
        this.showAddress = true
        // this.selectedItem = item
      })
    },
    async getDataListAddress(customerId, successCb) {
      const { data } = await getAddrListByCustomerId(customerId)
      data.forEach((item) => {
        item.fullAddress =
          item.receiveDistrict.provinceName +
          item.receiveDistrict.cityName +
          item.receiveDistrict.districtName +
          item.receiveAddr +
          item.receiveContact +
          item.receiveTel
      })
      this.dataListAddress = data
      this.originData = data
      this.dataListAddress.forEach((item) => {
        if (item.isCheck === 1) {
          this.addressId = this.chooseId = item.receiveAddrId
        }
      })
      successCb && successCb()
      if (this.dataListAddress.length === 0) {
        this.$message.error('尚未找到对应的地址！')
        this.showAddress = false
      }
    },
    async confirmAddress(val) {
      if (this.chooseId === this.addressId) return // chooseId没变化不请求接口
      const data = await switchReceiveAdd(this.addressId)
      if (data.code === 200) {
        /*       this.$refs.toast?.showToast({
          toastTitle: '切换地址成功',
          toastIcon: '/static/images/cart/success_icon.png',
          showStatus: true,
        })*/
        location.reload()
        this.$message.info('切换地址成功')
        // document.querySelector('.top-menu-wrapper_content .list').style.display = 'none'
      } else {
        this.$message.error('切换地址失败！')
        /*        this.$refs.toast?.showToast({
          toastTitle: data.data,
          toastIcon: '/static/images/cart/tip_icon.png',
          showStatus: true,
        })*/
      }
    },
    async getTopMenuConfig() {
      const { data } = await getConfigByCode('top_opt')
      this.topConfig = data?.configContent || {}
    },
    async quit() {
      try {
        this.loading = true
        const res = await getLogout()
        this.loading = false
        if (res.code === 200) {
          clearLoginInfo()
          this.$router.replace('/login')
          // 通知退出登陆
          this.$store.commit('setLoginOutRefresh')
          // 退出登录清空购物车数据
          this.$bus.$emit('clearCarData')
          // 退出成功清除飞速下单商家信息
          localStorage.setItem('selectOrgName', '')
          localStorage.setItem('selectOrgId', '')
        }
        // 推出清空搜索结果
        setStorageSync('history_record', [])
      } catch {
        //  某某某
      }
    },
    openPage(url) {
      window.open(url, '_blank')
    },
    // 返回首页
    goHome() {
      this.setKeyword('')
      if (this.$route.path !== '/') this.$router.push('/')
    },
    searchAddress() {
      this.dataListAddress = this.originData.filter((item) => {
        return item.fullAddress.indexOf(this.inputValue) > -1
      })
    },
    onClear() {
      this.inputvalue = ''
      this.dataListAddress = this.originData
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
//$grayTxt: rgba(0, 0, 0, 0.6);
.top-menu-wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  height: 36px;
  line-height: 36px;
  background: #fafafa;
  z-index: 1000;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);

  .top-menu-wrapper_content {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;

    .left {
      .location {
        color: rgba(0, 0, 0, 0.6);
        margin-right: 16px;

        &-icon {
          margin-right: 4px;
        }
      }

      > span {
        line-height: 36px;
        height: 36px;
      }

      .top-shop-name {
        display: inline-block;
        color: rgba(0, 0, 0, 0.6);
        margin-right: 16px;
        position: relative;
      }

      .welcome {
        color: #f99d33;
        margin-right: 16px;
      }

      .quit {
        color: rgba(0, 0, 0, 0.25);

        &:hover {
          cursor: pointer;
          color: #ce7720;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .item {
        // display: inline-block;
        padding: 0 8px;
        display: flex;
        align-items: center;
        cursor: pointer;

        a {
          color: rgba(0, 0, 0, 0.6);
        }

        &:hover {
          color: #ce7720;
        }

        img {
          margin-right: 2px;
        }
      }

      .mobile {
        position: relative;

        .mobile-content {
          position: absolute;
          padding: 24px 24px 16px 24px;
          background-color: #fff;
          display: none;
          height: 200px;
          top: 32px;
          left: 80%;
          // border: 1px solid rgba(0,0,0,.2);
          transform: translateX(calc(-100% + 70px));
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);

          .mobile-content-item {
            display: flex;
            flex-direction: column;
            text-align: center;

            &:first-child {
              margin-right: 20px;
            }

            &:last-child {
              margin-right: 0 !important;
            }

            img {
              width: 130px;
              height: 160px;
            }

            /**/
          }
        }

        &:hover {
          .mobile-content {
            display: flex;
          }

          .smartphone-icon {
            display: none;
          }

          .smartphone-icon-hover {
            display: block;
          }
        }
      }
    }
  }

  .smartphone-icon,
  .smartphone-icon-hover {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .smartphone-icon-hover {
    display: none;
  }
}

::v-deep .ivu-radio-wrapper {
  margin-right: 0;
}

.shop-name {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
  &:hover {
    .list {
      display: block;
    }
  }

  .address-wrapper {
    background-color: #fff;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 402px;
    top: 0;

    .address-input {
      position: absolute;
      width: 364px;
      padding: 8px 24px 0px 24px;
    }
  }

  .list {
    border-radius: 8px;
    padding-bottom: 8px;
    display: block;
    position: absolute;
    width: 404px;
    background-color: #fff;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
    z-index: 999;
    top: 40px;
    left: 243px;
    .address-content {
      display: flex;
      position: absolute;
      max-height: 330px;
      width: 364px;
      overflow-y: auto;
      box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.2);
      /*          left: calc(100%);*/
      top: 4px;
      z-index: 9999;
      background-color: #fff;

      .address-content-item {
        display: flex;
        padding: 8px 24px;
        margin-bottom: 5px;

        ::v-deep .ivu-radio {
          position: relative;
        }

        &:hover {
          background: #f5f5f5;
        }

        word-wrap: break-word;
        white-space: pre-wrap !important;

        .address {
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);
        }

        .phone {
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .item-content {
      max-height: 400px;
      overflow-y: auto;

      ::v-deep .ivu-radio-group {
        width: 100%;
        word-wrap: break-word;
      }
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 16px 24px;
    }

    .list-item {
      padding: 8px 24px;
      display: flex;

      &:hover {
        background: #f5f5f5;
      }

      .content-box {
        flex: 1;
        word-wrap: break-word;
        max-width: calc(100% - 44px);
        white-space: pre-wrap !important;

        > div {
          max-width: 100%;
          word-break: break-word;
        }
      }

      .custome-name {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
      }

      .custome-addr {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.6);
      }

      .change-address {
        position: relative;
        cursor: pointer;
        max-height: 50px;
        overflow-y: auto;
        color: #3853e2;

        .address-content {
          position: absolute;
          top: 4px;
          z-index: 9999;
          background-color: #fff;

          .address-content-item {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
