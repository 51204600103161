import dayjs from 'dayjs'
import _ from 'lodash'
/**
 * TODO: 价格格式化 方法
 * describe('returnFloat', () => {
 *   test('should return the price with sanitized number', () => {
 *     expect(returnFloat('100.25')).toBe('100.25');
 *     expect(returnFloat('100')).toBe('100.00');
 *     expect(returnFloat('100.5')).toBe('100.50');
 *   });
 *
 *   test('should return the price with appropriate rounding', () => {
 *     expect(returnFloat('100.245', '10.1')).toBe('100.25');
 *     expect(returnFloat('100.244', '10.1')).toBe('100.24');
 *     expect(returnFloat('100.2', '10.1')).toBe('100.20');
 *   });
 *
 *   test('should handle edge cases with rounding', () => {
 *     expect(returnFloat('99.995')).toBe('100.00');
 *     expect(returnFloat('99.994')).toBe('99.99');
 *     expect(returnFloat('99.9')).toBe('99.90');
 *   });
 *
 *   test('should handle invalid input', () => {
 *     expect(returnFloat(null)).toBe('');
 *     expect(returnFloat(undefined)).toBe('');
 *     expect(returnFloat('')).toBe('');
 *     expect(returnFloat('invalid')).toBe('');
 *     expect(returnFloat('100.25.50')).toBe('');
 *   });
 * });
 * 0.1245.toFixed(3) === 0.124
 */
// 清理并转换为数字
const sanitizeNumber = (n) => {
  if (n === 0) {
    return '0'
  }
  if (!n) {
    return ''
  }
  const sanitized = Number(n)
  return Number.isNaN(sanitized) ? '' : String(sanitized)
}
// 获取小数点后有效位数
const getPoint = (_n) => {
  const n = sanitizeNumber(_n)
  if (!n) {
    return 0
  }
  return n.split('.')?.[1]?.length || 0
}
/*
* 格式化价格显示，至少保留两位小数，且不丢失精度
* 如果有关联价格，按照关联价格精度保留小数点位数
*   末尾数 进行精确的四舍五入
* */
const returnFloat = (_price, _linkPrice = '') => {
  const price = sanitizeNumber(_price)
  if (price === '0') {
    return '0.00'
  }
  if (!price) {
    return ''
  }

  // 整数位
  let intPart = price.split('.')[0]
  // 小数位
  const pointPart = price.split('.')[1]
  // 不足两位的数字 进行补0
  if (!pointPart) {
    return `${intPart}.00`
  }
  if (pointPart.length === 1) {
    return `${intPart}.${pointPart}0`
  }
  const linkPrice = sanitizeNumber(_linkPrice)
  // 如果小数点位数大于2，且没有关联的价格 则直接返回
  if (!linkPrice) {
    return `${price}`
  }
  // 获取小数点位数
  const point = Math.max(2, getPoint(linkPrice))

  // 前导 占位 防止中间为0的 位丢失
  let endNum = '1' + pointPart.slice(0, point)

  // 判断是否需要进位 四舍五入
  if (pointPart[point] >= 5) {
    endNum = String(Number(endNum) + 1)
  }
  // 判断 进位到整数位，小数位归0
  if (endNum[0] !== '1') {
    intPart++
    endNum = '00'
  } else {
    endNum = endNum.slice(1)
  }
  return `${intPart}.${endNum}`
}
/**
 * js浮点数精度丢失问题及如何解决js中浮点数计算不精准
 * 处理 0.1 + 0.2 = 0.30000000000000004 的问题
 * 简易处理办法 toFixed， console.log(parseFloat(0.1+0.2).toFixed(1));
*/

/**
 * 加法函数
 * add(0.1,0.2); 输出0.6
 * @param {*} arg1
 * @param {*} arg2
 * @returns
 */
function add(arg1, arg2) {
  let r1, r2

  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const c = Math.abs(r1 - r2)
  const m = Math.pow(10, Math.max(r1, r2))
  if (c > 0) {
    const cm = Math.pow(10, c)
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''))
      arg2 = Number(arg2.toString().replace('.', '')) * cm
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm
      arg2 = Number(arg2.toString().replace('.', ''))
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''))
    arg2 = Number(arg2.toString().replace('.', ''))
  }
  return (arg1 + arg2) / m
}

/**
 * 减法函数
 * sub(0.3,0.1); 输出0.2
 * @param {*} arg1
 * @param {*} arg2
 * @returns
 */
function sub(arg1, arg2) {
  let r1, r2
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  const m = Math.pow(10, Math.max(r1, r2)) // 动态控制精度长度
  const n = (r1 >= r2) ? r1 : r2

  return parseFloat(((arg1 * m - arg2 * m) / m).toFixed(n))
}

/**
 * 乘法函数
 * mul(0.3,0.2); 输出0.06
 * @param {*} arg1
 * @param {*} arg2
 * @returns
 */
function mul(arg1, arg2) {
  let m = 0
  const s1 = arg1.toString()
  const s2 = arg2.toString()
  try {
    m += s1.split('.')[1].length
  } catch (err) {
    //
  }
  try {
    m += s2.split('.')[1].length
  } catch (err) {
    //
  }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

/**
 * 除法函数
 * div(0.6,0.2); 输出3
 * @param {*} arg1
 * @param {*} arg2
 * @returns
 */
function div(arg1, arg2) {
  let t1 = 0
  let t2 = 0
  try {
    t1 = arg1.toString().split('.')[1].length
  } catch (err) {
    //
  }
  try {
    t2 = arg2.toString().split('.')[1].length
  } catch (err) {
    //
  }

  const r1 = Number(arg1.toString().replace('.', ''))
  const r2 = Number(arg2.toString().replace('.', ''))

  return (r1 / r2) * Math.pow(10, t2 - t1)
}
/**
 * 时间格式化
 */
function timeFormat(timestamp = null, fmt = 'yyyy-mm-dd') {
  // yyyy:mm:dd|yyyy:mm|yyyy年mm月dd日|yyyy年mm月dd日 hh时MM分等,可自定义组合
  timestamp = parseInt(timestamp)
  // 如果为null,则格式化当前时间
  if (!timestamp) timestamp = Number(new Date())
  // eslint-disable-next-line eqeqeq
  if (timestamp.toString().length == 10) timestamp *= 1000
  const date = new Date(timestamp)
  let ret
  const opt = {
    'y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'h+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
    // eslint-disable-next-line eqeqeq
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  }
  return fmt
}

const fixNumber = (n, precision = 2, emptyStr = '0.00') => {
  const number = Number(n)
  if (Number.isNaN(number)) {
    return emptyStr
  }
  let result = number.toFixed(precision)
  if (Number(result) > number) {
    result = (Number(result) - Math.pow(10, -precision)).toFixed(precision)
  }
  return result
}
/**
 * 格式化日期，如果超过2100-01-01就返回长期，否则返回自身
 * @param {传入的日期} curDate
 * @returns bool
 */
const formatLongDate = (curDate) => {
  if (!curDate) {
    return ''
  }
  try {
    return new Date(curDate).getTime() >= new Date('2100-01-01').getTime() ? '长期' : dayjs(curDate).format('YYYY-MM-DD')
  } catch (error) {
    return ''
  }
}

const debounce = (fn, delay = 500) => {
  let timer
  return function() {
    const context = this
    const args = arguments
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}
export {
  returnFloat,
  add,
  sub,
  mul,
  div,
  timeFormat,
  fixNumber,
  formatLongDate,
  debounce
}

