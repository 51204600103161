<template>
    <div class="act_time" :class="activityMsg=='结束'? 'green_statr':''" v-show="resObj.displayEndTimeCountDown">
<!--      <div class="time_doc">-->
<!--        距{{ activityMsg }}-->
<!--      </div>-->
      <ul class="time_num">
        <li v-if="days > 0">{{ days }}</li>
        <span v-if="days > 0">天</span>
        <li>{{ ho }}</li>
        <span>:</span>
        <li>{{ mi }}</li>
        <span>:</span>
        <li>{{ so }}</li>
      </ul>
    </div>
</template>
<script>
export default {
  name: '',
  props:{
    resObj:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      timeData: {},
      time:0,
      status:'',//1活动预热前 2活动预热中 3活动进行中 4活动结束
      targetTime1: new Date().getTime() + 3900000,
      days: '',
      ho: '',
      mi: '',
      so: '',
      activityMsg: '',
      isShowTime: false
    }
  },
  mounted(){
    // this.getCountTime()
    this.countTime()
  },
  methods:{
    countTime(){
      // this.$emit('showCountTime', this.isShowTime)
      //获取当前时间
      var now = new Date().getTime()
      let preTime = this.resObj.startTime - this.resObj.preTime * 60 * 60 * 1000
      let leftTime
      if (now > this.resObj.endTime || now < preTime) { //活动结束
        this.isShowTime = false
        this.$emit('preIng', false)
        return
      } else if (now > preTime && now < this.resObj.startTime) { //正在预热
        this.$emit('preIng', false)
        this.activityMsg = '开始'
        leftTime = this.resObj.startTime - now
        if (this.resObj.preTime > 0) {
          this.isShowTime = true
        }
      } else if ( now > this.resObj.startTime && now < this.resObj.endTime) { //活动进行中
        this.activityMsg = '结束'
        leftTime = this.resObj.endTime - now
        if (this.resObj.displayEndTimeCountDown) {
          this.isShowTime = true
        }
      }
      this.$emit('showCountTime', this.activityMsg)
      //定义变量 d,h,m,s保存倒计时的时间
      var d,h,m,s;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24)
        h = Math.floor(leftTime / 1000 / 60 / 60) % 24
        m = Math.floor(leftTime / 1000 / 60 % 60)
        s = Math.floor(leftTime / 1000 % 60)
      }
      this.days = d < 10 ? `0${d}` : d
      this.ho = h < 10? `0${h}`: h
      this.mi = m < 10? `0${m}`: m
      this.so = s < 10? `0${s}`: s
      //递归每秒调用countTime方法，显示动态时间效果
      setTimeout(this.countTime, 1000)
    },
    onRefresh(){
      if(this.pageType=='cart'){
        this.$emit('onRefresh')
      }else{
        this.getCountTime()
      }
    },
    onChange(e) {
      this.timeData = e
    },
    getCountTime(){//活动剩余时间格式
      let nowTime = new Date().getTime()
      let startTime='',endTime = '',preTime='';
      // if(this.startTime&&this.startTime.includes('-')){
      //   startTime=new Date(this.startTime.replace(/-/g, "/")).getTime()
      // }else{
        startTime=this.startTime
      // }
      // if(this.endTime&&this.endTime.includes('-')){
      //   endTime = new Date(this.endTime.replace(/-/g, "/")).getTime()
      // }else{
        endTime=this.endTime
      // }
      if(startTime){
        if(this.preTime>0){
          preTime = startTime - this.preTime * 60 * 60 * 1000
          if(nowTime < preTime){//活动预热前
            this.isShowTime = false
            this.status='1'
            this.$emit('changeStatus',this.status)
          }else if(nowTime > preTime && nowTime <= startTime){//活动预热中
            this.activityMsg='开始'
            if (this.displayCountDown) {
              this.isShowTime = true
            }
            this.time=startTime-nowTime
            this.status='2'
            this.$emit('changeStatus',this.status)
          }else if(nowTime > startTime && nowTime<endTime){//活动进行中
            this.activityMsg='结束'
            if (this.displayCountDown) {
              this.isShowTime = true
            }
            this.time=endTime-nowTime
            this.status='3'
            this.$emit('changeStatus',this.status)
          }else if (nowTime > endTime) { //活动结束
            this.isShowTime = false
            this.status='4'
            this.$emit('changeStatus',this.status)
          }
        }else{
          if(nowTime > startTime && nowTime<endTime){//活动进行中
            this.activityMsg='结束'
            if (this.displayCountDown) {
              this.isShowTime = true
            }
            this.time=endTime-nowTime
            this.status='3'
            this.$emit('changeStatus',this.status)
          }else if (nowTime > endTime) { //活动结束
            this.isShowTime = false
            this.status='4'
            this.$emit('changeStatus',this.status)
          }
        }
      }else{
        if(endTime){
          if(nowTime<endTime){//活动进行中
            this.activityMsg='结束'
            this.isShowTime = true
            this.time=endTime-nowTime
            this.status='3'
            this.$emit('changeStatus',this.status)
          }else if(nowTime > endTime) { //活动结束
            this.isShowTime = false
            this.status='4'
            this.$emit('changeStatus',this.status)
          }
        }
      }
    }
  }
}
</script>
<style scoped lang='less'>
  .act_time {
    //width: 118px;
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    //color: #D3650B;
    color:#1F7C15;
    //margin-left: -6px;
    //margin-top: 7px;
  }
  .time_num {
    display: flex;
    li {
      padding:0;
      margin:0;
      list-style:none;
    }
  }
  .count-time{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .activity-txt{
      font-weight: 400;
      font-size: 20rpx;
      line-height: 28rpx;
      color: #FA541C;
      margin-right:8rpx;
    }
    .time-section{
      .time{
        display:flex;
        align-items: center;
        &__custom {
          padding:5rpx 2rpx 0 2rpx;
          height: 24rpx;
          background: #FFFFFF;
          border: 1rpx solid #FFBB96;
          border-radius: 4rpx;
          display: flex;
          justify-content: center;
          align-items: center;
          &__item {
            font-weight: 500;
            font-size: 20rpx;
            line-height: 20rpx;
            color: #FA541C;
          }
        }
        &__doc {
          width: 10rpx;
          height: 36rpx;
          text-align: center;
          font-weight: 400;
          font-size: 20rpx;
          line-height: 36rpx;
          color: #FA541C;
        }
      }
    }
  }
  .green_statr {
    color: #A35511 !important;
  }
</style>
