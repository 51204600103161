<template>
  <div>
    <DatePicker
      :open="open"
      :model-value="date"
      type="date"
      @on-change="handleChange"
    >
      <div class="time-content" >
        <div  class="time-content">
          <Input class="input" v-model="inputDate" :disabled="isLong" @on-blur="changeDate"></Input>
          <Icon class="icon" @click="handleClick" type="ios-calendar-outline" ></Icon>
        </div>
        <Checkbox class="check" v-model="isLong" @on-change="changeLong"> 长期</Checkbox>
      </div>
    </DatePicker>
  </div>
</template>

<script>
import { timeFormat,isValidDate } from '@/common/utils';
import dayjs from 'dayjs'
export default {
  name: 'ValidDate',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      inputDate:'',
      date:'',
      isLong:false,
      minDate: new Date().getTime(),
      maxDate: new Date().getTime() + 100 * 365 * 24 * 60 * 60 * 1000,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.isLong = new Date(this.value).getTime() >= new Date('2100-01-01').getTime() ? true : false
        if(!this.isLong){
          this.inputDate = this.value
          this.date = this.value
        }
      },
      immediate:true
    },
  },
  methods: {
    changeDate(){
      if(isValidDate(this.inputDate)){
        this.inputDate = dayjs(this.inputDate).format('YYYY-MM-DD')
        this.date = this.inputDate
        this.$emit('input', this.date);
      }
    },

    changeLong(isLong){
      this.isLong = isLong
      this.date = this.isLong ? '2200-02-01' : dayjs(new Date()).format('YYYY-MM-DD')
      this.inputDate = this.date
      this.$emit('input', this.date);
    },
    handleClick() {
      if(this.isLong){
        return
      }
      this.open = !this.open;
    },
    handleChange(date) {
      this.date = date;
      this.inputDate = date
      this.$emit('input', this.date);
    },
  },
};
</script>

<style scoped lang="less">
:deep(.ivu-date-picker){
  width: 100%;
}
:deep(.time-content){
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  flex: 1;

  .check{
    width: 48px;
  }

  .input{
    flex: 1;
  }

  .icon{
    font-size: 20px;
  }
}
</style>
