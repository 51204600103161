import { get, post } from '@/common/utils'
import { api } from '@/common/service/config'
/**
 * 商品信息 (原商品)
 */
export const selectByIdForOut = (data) => post({
  url: `${api.baseUrl}/prd/product/selectByIdForOut?id=${data.id}&activityId=${data.activityId}`,
  isLoading: true
})
// 经营范围
export const getBusinessscope = (data) => post({
  url: `${api.baseUrl}/prd/product/selectListTree`,
  data,
  isLoading: true
})

// 商品库存
export const getProductStock = (data) => post({
  url: `${api.baseUrl}/prd/stock/getAvailableStock`,
  data,
  isLoading: true
})
// 商品活动价
export const selectActiviPriceById = (data) => post({
  url: `${api.baseUrl}/price/pcactivitycusttypeprice/selectActiviPriceById?id=${data.id}&activiId=${data.activiId}&type=${data.type}`,
  isLoading: true
})

export const selectActiviPriceById1 = (data) => post({
  url: `${api.baseUrl}/price/pcactivitycusttypeprice/selectActiviPriceById`,
  data,
  isLoading: true
})

export const fetchPrice = (data) => post({
  url: `${api.baseUrl}/prd/product/fetchPrice`,
  data,
  isLoading: true
})

// 查询建采关系

export const getCustRelByCustIdOrgId = (data) => post({
  url: `${api.baseUrl}/cust/custuserrel/getCustRelByCustIdOrgId`,
  data,
  isLoading: false
})

// 建立建材关系
export const buildCustRelByCustIdOrgId = (data) => post({
  url: `${api.baseUrl}/cust/custuserrel/buildCustRelByCustIdOrgId`,
  data,
  isLoading: true
})

// 活动已售
export const productSaleNum = (data) => post({
  url: `${api.baseUrl}/marketing/sale/getActivityProductSaleNum`,
  data,
  isLoading: true
})

// 商家信息
export const simple = (id) => get({
  url: `${api.baseUrl}/org/info/simple?orgId=${id}`
})

// 商家详细信息
export const detail = (id) => get({
  url: `${api.baseUrl}/org/info/detail?orgId=${id}`
})
// 退换货说明
export const salesReturn = (id) => get({
  url: `${api.baseUrl}/org/info/salesReturn?orgId=${id}`
})

// 公告列表分页
export const findNoticeListPage = (data) => post({
  url: `${api.baseUrl}/wx/notice/findNoticeListPage`,
  data,
  isLoading: true
})

// 公告详情
export const getNoticeDetail = (data) => get({
  url: `${api.baseUrl}/wx/notice/get?id=${data}`
})

// 普通商品销售价
export const queryPriceForOut = (data) => post({
  url: `${api.baseUrl}/price/pcactivitycusttypeprice/queryPriceForOut?id=${data}`,
  isLoading: true
})

// 建超控销
export const prdControlForClient = (data, cancelToken) => post({
  url: `${api.baseUrl}/prd/product/prdControl`,
  data,
  config: { cancelToken }
})

// 收藏商品
export const addProductCollect = (data) => post({
  url: `${api.baseUrl}/prd/product/addProductCollect`,
  data,
  isLoading: true
})

// 参看商品收藏状态
export const selectCollectStatus = (data) => post({
  url: `${api.baseUrl}/prd/product/selectCollectStatus`,
  data,
  isLoading: true
})
// 预估到手价
export const estimatePrice = (data) => post({
  url: `${api.baseUrl}/marketing/activity/product/get/estimatePrice`,
  data
})
// 配送说明
export const selectByorgIds = (data) => get({
  url: `${api.baseUrl}/org/orglogisticsconfig/selectByorgIds?orgIds=${data}`
})
// 倒计时
export const getPreheatInfo = (data) => post({
  url: `${api.baseUrl}/marketing/activity/info/getPreheatInfo?activityId=${data}`,
  isLoading: true
})
// 换购价& 换购库存
export const tradeProduct = (data) => post({
  url: `${api.baseUrl}/marketing/activity/product/get/tradeProduct`,
  isLoading: true,
  data
})
// 开户说明若配置开户说明点击去建采出现该弹窗
export const getOrgOpenAccount = (data) => post({
  url: `${api.baseUrl}/org/info/getOrgOpenAccount?orgId=${data}`
})
// 删除收藏
export const deleteProductCollect = (data) => post({
  url: `${api.baseUrl}/prd/product/deleteProductCollect`,
  data,
  isLoading: true
})

// 查询组合活动接口
export const combineFetch = (data) => post({
  url: `${api.baseUrl}/marketing/activity/combine/fetch`,
  data
})

// 组合活动加入购物车
export const addCombineActivityProducts = (data) => post({
  url: `${api.baseUrl}/order/cart/addCombineActivityProducts`,
  data
})

// 查询拼团活动记录
export const getGroupBuyRecord = (data) => post({
  url: `${api.baseUrl}/order/cust/product/group`,
  data,
  isLoading: true
})

// 生成小程序二维码
export const getWeiXinQRCode = (data) => post({
  url: `${api.baseUrl}/v1/wx/getWeiXinQRCode`,
  data,
  isLoading: true
})
