<template>
  <div class="coupon coupon-box" :class="itemData.noCms && 'bg'" >
    <!-- <p v-if="itemData.couponMaxLimit && itemData.couponMaxLimit!=-1" class="max-full">最高优惠{{itemData.couponMaxLimit}}元</p>
    <div class="left">
      <p class="org-name line-clamp-1">{{ itemData.noCms ? '品牌专属优惠券' : itemData.orgName }}</p>
      <p class="usable-range line-clamp-1">{{ itemData.noCms ? '活动见详情' : getUsableRange() }}</p>
      <div class="join-show">进店领取<Icon style="margin-left: 4px;" type="ios-arrow-forward" /></div>
    </div>
    <div class="right" v-if="!itemData.noCms">     
      <p >
        <span v-if="itemData.couponType != 'coupon_full_discount'" class="unit">¥</span>
        {{ itemData.decrementAmount }}
        <span v-if="itemData.couponType == 'coupon_full_discount'" class="unit">折</span>
      </p>
      <p v-if="itemData.couponThreshold" class="full-num">满{{ itemData.fullAmount }}元</p>
      <p v-else class="full-num"> 无门槛</p>
    </div> -->
    <div class="coupon-left">
      <p class="num-pic">
        <span 
          v-if="itemData.couponType != 'coupon_full_discount'" 
          class="unit"
        >¥</span>{{ itemData.decrementAmount }}<span 
          v-if="itemData.couponType == 'coupon_full_discount'" 
          class="unit"
          >折</span>
      </p>
      <p 
       v-if="itemData.couponThreshold || (itemData.couponMaxLimit && itemData.couponMaxLimit!=-1)" 
       class="full-num"
      >
        <span 
          v-if="itemData.couponThreshold"
        >满{{ itemData.fullAmount }}可用</span>
        <span
          v-if="itemData.couponThreshold && itemData.couponMaxLimit && itemData.couponMaxLimit!=-1"
        >·</span>
        <span 
          v-if="itemData.couponMaxLimit && itemData.couponMaxLimit!=-1"
          class="max-full"
        >最高优惠{{itemData.couponMaxLimit}}元</span>
      </p>
      <p v-else class="full-num">无门槛</p>
      <p class="org-name line-clamp-1">{{ itemData.noCms ? '品牌专属优惠券' : itemData.orgName }}</p>
    </div>
    <div class="coupon-right" @click="handler">
      <span>去</span>
      <span>领</span>
      <span>券</span>
    </div>
  </div>
</template>
<script>
// import { recieveCouponApi } from '@/common/service/coupon'
// import { getStorageSync } from '@/common/utils/storage'
// import { debounce } from 'lodash-es'
import dayjs from 'dayjs'

export default {
  name: 'CouponItem',
  props: {
    itemData: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: '0' // 1, 我的券， 0, 领券中心， 9, 商品详情
    },
    selected: {
      type: Number,
      default: 0
    },
    page:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      couponGoodsType: {
        product: '单品券',
        org_category: '分类券',
        manufactor: '厂家券'
      },
      couponType: {
        'coupon_full_decrement': '满减券',
        'coupon_full_discount': '满折券',
        'coupon_full_rebate': '满返券',
        'coupon_new_customer': '新客券',
        'coupon_postage': '运费券'
      }
    }
  },
  methods: {
    getUsableRange() {
      const types = {
        all: '全部商品可用',
        choose: '部分商品可用'
      }
      return types[this.itemData.productSelection]
    },
    formatEffecitveTime() {
      const { receiveStartTime, receiveEndTime, effectiveStartTime, effectiveEndTime } = this.itemData
      return dayjs(receiveStartTime || effectiveStartTime || '').format('YYYY.MM.DD') + ' - ' + dayjs(receiveEndTime || effectiveEndTime || '').format('YYYY.MM.DD')
    },
    handler() {
      this.$router.push('/home/coupon')
    }
    // recieveCoupon: debounce(function() {
    //   this.$emit('recieveCoupon', { type: 'start', data: this.itemData })
    //   // recieveCouponApi(this.itemData.couponId).then(res => {
    //   //   if (res.code === 200 && res.data === 'C_00') {
    //   //     this.$Message.success('领取成功')
    //   //     if(this.page=='cart'){
    //   //       this.$emit('update:itemData', { ...this.itemData, state: 'received',couponRemainNum:this.itemData.couponRemainNum-1 })
    //   //     }else{
    //   //       this.$emit('update:itemData', { ...this.itemData, state: 'effective_unuse' })
    //   //       this.$emit('recieveCoupon', { type: 'success', data: { ...this.itemData, state: 'effective_unuse' }})
    //   //     }
    //   //   } else {
    //   //     const message = {
    //   //       C_01: '领取失败,数量不足',
    //   //       C_02: '领取失败,已领取',
    //   //       C_07: '领取失败,券签名不一致',
    //   //       C_08: '领取失败,已结束',
    //   //       C_09: '领取异常'
    //   //     }
    //   //     this.$Message.warning(message[res.data] || `领取失败`)

    //   //     this.$emit('recieveCoupon', { type: 'fail', data: this.itemData })
    //   //   }
    //   // }).finally(() => {
    //   //   this.$emit('recieveCoupon', { type: 'end', data: this.itemData })
    //   // })
    // }, 300),
  //   useCoupon: debounce(function() {
  //     this.$router.push({
  //       path: '/coupon/goodslist',
  //       query: {
  //         couponId: this.itemData.couponId,
  //         couponLabel: this.itemData.couponLabel,
  //         orgId: this.itemData.orgId,
  //         activityType: 98
  //       }
  //     })
  //   })
  }
}
</script>
<style scoped lang="less">
  .coupon {
    width: 100%;
    border-radius: 8px;
    // background: rgba(245, 63, 63, 0.05);
    // padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: 8px;
    box-sizing: border-box;
    position: relative;
    &.bg {
      // background: url(~@/assets/home/coupon-bg.png)  no-repeat;
      background-size: 100% 100%;
    }
    .max-full {
      // position: absolute;
      // top: 0;
      // right: 0;
      // font-size: 12px;
      // color: rgba(245, 63, 63, 1);
      // background-color: rgba(255, 236, 232, 1);
      // padding: 0 8px;
      // border-radius: 0 8px;
    }
    .left{
      flex: 1;
      max-width: 136px;
    }
    .org-name {
      color: rgba(0, 0, 0, 0.90);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    .usable-range {
      color: #F53F3F;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12.583px;
      margin-top: 4px;
      margin-bottom: 2px;
    }
    .join-show {
      color: #F53F3F;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .coupon-type{
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      max-width: 210px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .condition{
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 4px;
    }
    .rule{
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 8px;
      img{
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }
    .right{
      text-align: center;
      color: rgba(245, 63, 63, 1);
      font-size: 24px;
      font-weight: 500;
      padding-top: 6px;
      .full-num{
        font-weight: 400;
        font-size: 12px;
      }
    }
    .unit {
      font-size: 14px;
    }

  }
  .coupon-box{
    border-radius: 10px 8px 8px 10px;
    // height: 100%;
    background: linear-gradient(15.93deg, #FC3844 6.6%, #FF7463 95.67%);
    min-height: 76px;
    .coupon-left{
      z-index: 1;
      border-radius: 8px;
      background-color: rgba(255, 245, 245, 1);
      flex: 1;
      height: 100%;
      // max-width: 136px;
      padding: 6px 16px 6px 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-content: space-between;
      color:#F53F3F;
      .num-pic{
        font-family: PingFang SC;
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        line-height: 25px;
      }
      .unit{
        font-weight: 600;
        font-size: 14px;
      }
      .full-num{
        font-size: 12px;
        font-weight: 400;
      }
      .org-name{
        color: rgba(0, 0, 0, 0.60);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

      }

    }
    .coupon-right{
      flex-basis: 36px;
      height: 100%;
      border-radius: 0 8px 8px 0;
      background: linear-gradient(15.93deg, #FC3844 6.6%, #FF7463 95.67%);
      //styleName: medium/12;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      color: #FFFDF9;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      // writing-mode: vertical-rl;
      cursor: pointer;
      span{
        line-height: 18px;
        font-weight: 500;
      }
      &::after{
        content: '';
        background: rgb(255, 82, 82);
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 12px ;
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: -6px;
        z-index: 1;
        transform: translateY(-50%);
      }

    }
  }
</style>
