<template>
  <div
      class="coupon-item"
      :class="{
      'disabled-coupon': !!stateDesc && !['未生效','已领取'].includes(stateDesc),
      selected: selected == 1,
      cart: page === 'cart'
    }"
  >
    <div class="coupon-item-content">
      <div class="info">
        <div class="coupon-name">{{ itemData.couponName }}</div>
        <div class="coupon-desc">
          <template v-if="!isSettlePage || itemData.isOfRule">
            <span>{{ useRange }}</span>
            <template v-if="itemData.couponMaxLimit && itemData.couponMaxLimit!=-1">
              <span style="padding:0 2px">·</span>
              <span>最高减{{ itemData.couponMaxLimit }}元</span>
            </template>
          </template>
          <template v-if="isSettlePage && !itemData.isOfRule">
            <div style="color: rgba(230, 125, 0, 1);">
              {{ itemData.couponGrade == 1 ? '满减/满折/满返券/新客券最多使用1张' : itemData.couponGrade == 2 ? '单个商品最多使用1张商品券' : '最多使用1张新客券' }}
            </div>
          </template>
        </div>

        <div class="coupon-footer">
          <div class="coupon-type" :class="{postage:couponType === '运费券', commodity: couponType === '商品券'}">
            <span>{{ couponType === '商品券' ? '单品券' : couponType }}</span>
          </div>
          <div class="coupon-time">
            {{ couponTime }}
          </div>
          <div v-if="!isSettlePage && couponType!=='运费券'" class="coupon-trigger" :class="{open:isShow}" @click="showDetail">
            <svg-icon class="coupon-trigger-icon" name="coupon-trigger" style="width: 6px;height: 3.5px"></svg-icon>
          </div>
        </div>
      </div>
      <div class="action">
        <div class="action-mark">
          <img v-if="stateDesc==='已使用'" src="../../assets/images/coupon/state/used.png" alt="">
          <img v-if="stateDesc==='已领完'" src="../../assets/images/coupon/state/complete.png" alt="">
          <img v-if="stateDesc==='已过期'" src="../../assets/images/coupon/state/unavailable.png" alt="">
          <img v-if="stateDesc==='未生效'" src="../../assets/images/coupon/state/not-effective.png" alt="">
          <img v-if="stateDesc==='已领取'" src="../../assets/images/coupon/state/received.png" alt="">
        </div>
        <div class="action-info">
          <div class="action-info-content">
            <div class="action-num">
              <span v-if="itemData.couponType != 'coupon_full_discount'" class="unit">¥</span>
              <span>{{ itemData.decrementAmount }}</span>
              <span v-if="itemData.couponType == 'coupon_full_discount'" class="unit">折</span>
            </div>
            <div v-if="couponType!=='运费券'" class="action-condition">
              {{ itemData.couponThreshold ? `满${itemData.fullAmount}元可用` : '无门槛' }}
            </div>
            <div v-if="canReceive" class="action-btn" @click.stop="recieveCoupon">立即领取</div>
            <!--  已领取 未使用-->
            <div v-if="itemData.state === 'effective_unuse'" class="action-btn" @click="useCoupon">去使用</div>
          </div>
        </div>
        <svg-icon class="action-bg" name="coupon-item-action" style="width: 120px;height: 110px"/>
        <svg-icon class="action-bg action-bg-disabled" name="coupon-item-action-disabled"
                  style="width: 120px;height: 110px"/>
        <svg-icon class="action-bg action-bg-selected" name="coupon-item-action-selected"
                  style="width: 120px;height: 110px"/>
        <div v-if="selected" class="selected-line"></div>
      </div>
    </div>
    <div v-show="isShow" class="coupon-detail-line"></div>
    <div v-show="isShow" class="coupon-detail" @click.stop>
      <div class="coupon-detail-item">{{ itemData.couponName }}</div>
      <div class="coupon-detail-item">促销{{ itemData.superimposedActivity ? '可叠加' : '不可叠加' }}</div>

      <div v-if="type==1" class="coupon-detail-item">
        剩余张数：{{ itemData.couponRemainNum == -1 ? '不限' : itemData.couponRemainNum }}
      </div>
    </div>
  </div>
</template>
<script>
import {recieveCouponApi} from '@/common/service/coupon'
import {debounce} from 'lodash'
import dayjs from 'dayjs'

export default {
  name: 'CouponItem',
  props: {
    isSettlePage: {
      type: Boolean,
      default: false
    },
    itemData: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: '1' // 0, 我的券， 1, 领券中心， 9, 商品详情
    },
    selected: {
      type: Number,
      default: 0
    },
    page: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      couponGoodsType: {
        product: '单品券',
        org_category: '分类券',
        manufactor: '厂家券'
      },
      isShow: false,
      isCurrent: false
    }
  },
  computed: {
    // 优惠券使用范围
    useRange() {
      const types = {
        all: '全部商品可用',
        choose: '部分商品可用',
        unchoose: '部分商品可用'
      }
      return types[this.itemData.productSelection]
    },
    // 优惠券类型显示
    couponType() {
      const couponTypeMap = {
        coupon_full_decrement: '满减券',
        coupon_full_discount: '满折券',
        coupon_full_rebate: '满返券',
        coupon_commodity: '商品券',
        coupon_new_customer: '新客券',
        coupon_postage: '运费券'
      }

      return couponTypeMap[this.itemData.couponType]
    },
    // 优惠券显示时间
    couponTime() {
      const {receiveStartTime, receiveEndTime, effectiveStartTime, effectiveEndTime} = this.itemData
      return dayjs(receiveStartTime || effectiveStartTime || '').format('YYYY.MM.DD') + ' - ' + dayjs(receiveEndTime || effectiveEndTime || '').format('YYYY.MM.DD')
    },
    // 优惠券是否可以领取
    canReceive() {
      const {state, couponRemainNum} = this.itemData
      return String(state) === '1' && couponRemainNum && state !== 'effective_unuse'
    },
    // 是否已领完
    isReceiveAll() {
      const {state, couponRemainNum} = this.itemData
      return !couponRemainNum && String(this.type) === '1' && state !== 'effective_unuse'
    },
    state() {
      /*
      * 1 未领取
      *
      * */
      return String(this.itemData.state)
    },
    stateDesc() {
      if (!this.itemData.couponRemainNum && String(this.type) === '1' && this.state !== 'effective_unuse') {
        return '已领完'
      }
      if (this.state === 'received') {
        return '已领取'
      }
      if (this.state === 'not_effective') {
        return '未生效'
      }
      if (this.state === 'expired') {
        return '已过期'
      }
      if (this.state === 'used') {
        return '已使用'
      }
      return ''
    }
  },
  methods: {
    recieveCoupon: debounce(function () {
      this.$bus.$emit('close-coupon')
      this.$emit('recieveCoupon', {type: 'start', data: this.itemData})
      recieveCouponApi(this.itemData.couponId).then(res => {
        if (res.code === 200 && res.data === 'C_00') {
          this.$Message.success('领取成功')
          if (this.page == 'cart') {
            this.$emit('update:itemData', {
              ...this.itemData,
              state: 'received',
              couponRemainNum: this.itemData.couponRemainNum - 1
            })
          } else {
            this.$emit('update:itemData', {...this.itemData, state: 'effective_unuse'})
            this.$emit('recieveCoupon', {type: 'success', data: {...this.itemData, state: 'effective_unuse'}})
          }
        } else {
          const message = {
            C_01: '领取失败,数量不足',
            C_02: '领取失败,已领取',
            C_07: '领取失败,券签名不一致',
            C_08: '领取失败,已结束',
            C_09: '领取异常'
          }
          this.$Message.warning(message[res.data] || `领取失败`)

          this.$emit('recieveCoupon', {type: 'fail', data: this.itemData})
        }
      }).finally(() => {
        this.$emit('recieveCoupon', {type: 'end', data: this.itemData})
      })
    }, 300),
    useCoupon: debounce(function () {
      this.$router.push({
        path: '/coupon/goodslist',
        query: {
          couponId: this.itemData.couponId,
          couponLabel: this.itemData.couponLabel,
          orgId: this.itemData.orgId,
          activityType: 98
        }
      })
    }),
    showDetail() {
      this.isShow = !this.isShow
      if (this.isShow) {
        this.isCurrent = true
      }
    }
  },
  mounted() {
    this.$bus.$on('close-coupon', e => {
      if (this.isCurrent) {
        this.isCurrent = false
        return
      }
      this.isShow = false
    })
  },
  beforeMount() {
    this.$bus.$off('close-coupon')
  }
}
</script>
<style scoped lang="less">

.coupon-item-content {
  height: 110px;
  display: flex;
  position: relative;
  z-index: 2;
  width: 100%;

  .info {
    width: calc(100% - 120px);
    padding: 12px 16px;
    border: 1px solid #FFECE8;
    border-right: 0;
    border-radius: 8px 0 0 8px;
    position: relative;

    .coupon-name {
      overflow: hidden;
      color: var(--font_black-1, rgba(0, 0, 0, 0.90));
      text-overflow: ellipsis;

      /* medium/14 */
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      white-space: nowrap;
    }

    .coupon-desc {
      color: var(--font_black-2, rgba(0, 0, 0, 0.60));
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 2px;
    }

    .coupon-footer {
      display: flex;
      position: absolute;
      left: 12px;
      bottom: 12px;
      align-items: center;

      .coupon-type {
        padding: 0 4px;
        border-radius: 4px;
        background: #FFF4F1;
        color: #F53F3F;
        font-size: 10px;
        line-height: 18px;
        font-style: normal;
        font-weight: 400;
        height: 17px;
        display: flex;
        justify-content: center;
        overflow: hidden;

        &.postage {
          color: #2438BC;
          background: #F1F4FD;
        }

        &.commodity {
          color: #C56000;
          background: #FFF8E8;
        }
      }

      .coupon-time {
        color: rgba(0, 0, 0, 0.60);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-left: 6px;
      }

      .coupon-trigger {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .3s ease;

        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }

  .action {
    width: 120px;
    position: relative;
    border-radius: 0 8px 8px 0;
    overflow: hidden;
  }

  .action-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }

  .action-bg-disabled {
    display: none;
  }

  .action-bg-selected {
    display: none;
  }

  .action-info {
    width: 100%;
    height: 100%;
    z-index: 4;
    position: relative;
    padding-left: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action-num {
    color: #F53F3F;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .unit {
      color: #F53F3F;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .action-condition {
    color: #F53F3F;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
  }

  .action-btn {
    width: 72px;
    margin: 4px auto 0;
    border-radius: 20px;
    background: #F53F3F;
    color: #fff;
    line-height: 24px;
    font-size: 12px;
    transition: .3s ease;
    cursor: pointer;

    &:hover {
      background: #F76560;
    }
  }

  .action-mark {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;

    img {
      display: block;
      width: 42px;
      height: 42px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}

.coupon-item.disabled-coupon {
  .coupon-item-content {
    .info {
      border-color: #E7E7E7;
    }

    .action-bg {
      display: none;
    }

    .action-bg-disabled {
      display: block;
    }

    .action-num {
      color: rgba(0, 0, 0, 0.6);

      .unit {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .action-condition {
      color: rgba(0, 0, 0, 0.6);
    }

    .coupon-type {
      color: rgba(0, 0, 0, 0.6) !important;
      background: #F5F5F5 !important;
    }
  }

  .coupon-detail,.coupon-detail-line {
    border-color: #E7E7E7;
  }
}

.coupon-item {
  position: relative;

  .coupon-detail {
    padding: 12px;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    line-height: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    top: 110px;
    left: 0;
    background: #fff;
    width: 100%;
    border: 0.5px solid #FDCDC5;
    border-top: 0;
    z-index: 3;

    /* shadow/3 */
    box-shadow: 0px 12px 60px 10px rgba(0, 0, 0, 0.03), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 16px 20px -10px rgba(0, 0, 0, 0.04);

    .coupon-detail-item {
      position: relative;
      padding-left: 10px;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #CACACA;
      }
    }
  }
  .coupon-detail-line{
    width: 100%;
    border: 0.5px solid #FDCDC5;
    border-top: 0;
    z-index: 2;
    height: 20px;
    position: absolute;
    bottom: -10px;
    border-bottom: 0;
  }

  &.selected {
    .coupon-item-content {
      .info {
        border: 1px solid #F53F3F;
        border-right: 0;
      }
    }

    .action-bg-selected {
      display: block;
    }
  }

  &.cart{
    margin-top: 10px;
  }
}
.selected-line{
  width: 2px;
  background: #fff;
  height: 103px;
  z-index: 9;
  position: absolute;
  top: 3px;
  left: 0;
}
</style>
