<template>
  <div class="home-center">
    <div class="banner-warp">
      <div class="banner">
        <template v-if="list.length !== 0">
          <swiper
            ref="mySwiper"
            :options="swiperOption"
          >
            <swiper-slide v-for="(item, index) in list" :key="index">
              <img class="swiper" :src="item.img" @click="handelCmsLink(item)">
            </swiper-slide>
            <div slot="button-prev" class="banner-button-prev">
              <Icon type="ios-arrow-back" :size="24" :color="'#fff'" />
            </div>
            <div slot="button-next" class="banner-button-next">
              <Icon type="ios-arrow-forward" :size="24" :color="'#fff'" />
            </div>
            <div v-if="list?.length> 0" slot="pagination" class="swiper-pagination" />
          </swiper>
        </template>
        <template v-else>
          <img style="border-radius: 8px; width: 100%; height: 289px" :src="require('@/assets/images/home/banner.jpg')">
        </template>
      </div>
      <div class="sub-list">
        <img
          v-for="(item, index) in subBanerData"
          :key="index"
          class="sub-item"
          :src="item.img"
          @click="handelCmsLink(item)"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getFilePath } from '@/common/utils/base'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { cmsLink } from '@/views/home/index'

export default {
  name: 'HomeCenter',
  components: {
    swiper,
    swiperSlide
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      cmsLink: cmsLink.bind(this),
      list: [],
      subBanerData: [
        {
          img: require('@/assets/images/home/banner-sub1.png')
        },
        {
          img: require('@/assets/images/home/banner-sub2.png')
        },
        {
          img: require('@/assets/images/home/banner-sub3.png')
        }
      ],
      swiperOption: {
        // 分页器设置
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 左右箭头点击
        navigation: {
          nextEl: '.banner-button-next',
          prevEl: '.banner-button-prev'
        },
        autoHeight: true,
        // notNextTick: true,
        // 循环
        loop: false,
        // 设定初始化时slide的索引
        initialSlide: 0,
        // 自动播放，等同下面autoplay设置
        autoplay: true,
        // 设置轮播切换效果
        effect: 'slide',
        // 轮播切换动速度
        speed: 800,
        // 轮播滑动方向
        direction: 'horizontal',
        // 小手掌抓取滑动
        // 滑动之后回调函数
        on: {
          click: () => {
            // this.cmsLink(this.data?.props?.list?.at(this.swiperActiveIndex)?.link)
          },
          slideChange: () => {
            this.swiperActiveIndex = this.$refs.mySwiper.swiper.realIndex
          }
        }
      },
      swiperActiveIndex: 0
    }
  },

  watch: {
    'data.props': {
      handler(val) {
        const datas = val || {}
        const subList = datas.subList || []
        subList.map((item) => {
          if (item.fileId) {
            item.img = getFilePath('orig', item.fileId)
          }
        })
        this.subBanerData = subList.slice(0, 3)

        const list = datas.list || []
        if (list.length !== 0) {
          this.swiperOption.loop = true
          list.map((item) => {
            if (item.fileId) {
              const img = getFilePath('orig', item.fileId)
              item.img = img
            }
          })
        }
        this.list = list
      },
      deep: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
    },
    handelCmsLink(item) {
      const { link } = item || {}
      if (link) {
        cmsLink.bind(this)(link)
      }
    }
  }
}
</script>
<style scoped lang="less">
.swiper {
width: 100%; height: 289px;border-radius: 8px;cursor: pointer;
}
.home-center {
  width: 652px;
  height: 100%;
  margin: 0 14px;
  background: #fff;
}
.banner-warp {
  // margin-top: 16px;
}
::v-deep .root {
  background: transparent;
}
.banner {
  position: relative;
  width: 652px;
  height: 288px;
}
.sub-list {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.sub-item {
  width: 212px;
  height: 110px;
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
}
::v-deep .root {
  height: unset;
}
::v-deep .content {
  padding-top: unset !important;
}

.banner-button-prev, .banner-button-next {
  position: absolute;
  top: 50%;
  width: 32px;
  height: 32px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #383838;
    border-radius: 50%;
  }
}
.banner-button-prev {
  left: 10px;
  right: auto;
  padding-right: 2px;
}
.banner-button-next {
  right: 10px;
  left: auto;
  padding-left: 2px;
}
::v-deep .swiper-pagination-bullet{
  background: rgba(255, 255, 255, 0.7);
  opacity: unset;
  // width: 6px;
  // height: 6px;
  &-active {
    border: 1px solid #FDDA9F;
  }
}
::v-deep .root {
  margin-bottom: 0 !important;
}
</style>
