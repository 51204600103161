
<template>
  <div class="outer">
    <div class="price" @click="handleDetail">
      <div class="activity-price">{{ `¥${returnFloat(activityPrice, originPrize)}` }}</div>
      <div class="origin-price">{{ originPrize ? `¥${returnFloat(originPrize)}` : '未定价' }}</div>
    </div>
    <div class="title" @click="handleDetail">
      <span v-if="prefix" class="prefix">{{ prefix }}</span>
      <span>{{ productName }}</span>
    </div>
    <div class="group-buy-info"  @click="handleDetail">
      <span>已拼{{ soldOutProductNum || 0 }}{{ data.productUnit }}</span>
      <span class="start-buy">{{ startBuyNum }}{{ data.productUnit }}起拼</span>
    </div>
    <div class="progress"  @click="handleDetail">
      <div v-if="data?.marketing?.shortLabal && rate<100" class="reach-num">{{ data?.marketing?.shortLabal }} {{ data.productUnit }}成团</div>
      <div class="progress-line">
        <div class="progress-bar" :style="{width:`${rate}%`}" />
      </div>
      <div class="rate">{{ rate }}%</div>
    </div>
    <div class="actions">
      <InputNumber
        v-model="num"
        :disabled="!originPrize || disabled"
        class="group-buy-input"
        controls-outside
        :step="addBuyNum"
        :min="startBuyNum"
        :precision="0"
        @on-blur="blur"
      />
      <div v-if="data.availableStock < 1" class="confirm-btn" @click.stop="handleOutOfStockReg">缺货登记</div>
      <button v-else class="btn" :class="{disabled:disabledBuy}" :disabled="disabledBuy" @click="checkOrgJcStatus">立即参团</button>
    </div>
    <!-- 检查商家建采状态 开始 -->
    <CheckOrgJcStatus ref="checkOrgJcStatus" @ok="buy"></CheckOrgJcStatus>
    <!-- 检查商家建采状态 结束 -->
    <OutOfStockReg ref="outOfStockReg" />
  </div>
</template>
<script>
import { setStorageSync } from '@/common/utils'
import { returnFloat } from '@/common/utils/format'
import { getProductLeftStock } from '@/common/service'
import CheckOrgJcStatus from '@/components/CheckOrgJcStatus/index.vue'
import OutOfStockReg from '@/components/OutOfStockReg.vue'
export default {
  name: 'GroupBuyPop',
  components: { CheckOrgJcStatus,OutOfStockReg },
  props: {
    data: {
      type: Object,
      default() {
        return {
          marketing: {}
        }
      }
    },
    productName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      num: null,
      disabled: false,
      stock: null
    }
  },
  computed: {
    startBuyNum() {
      return this.data.marketing.minPurchaseAmountIdentity || 1
    },
    addBuyNum() {
      return this.data.marketing.addBuyNum || 1
    },
    // 活动价
    activityPrice() {
      if (this.originPrize) {
        return Math.min(Number(this.data?.marketing?.customerTypePrice?.[0]?.price), Number(this.originPrize))
      }
      return this.data?.marketing?.customerTypePrice?.[0]?.price
    },
    // 原价
    originPrize() {
      const price = this.data?.customerTypePrice?.[0]?.price
      return price
    },
    // 包邮前缀
    prefix() {
      const { marketing, productUnit } = this.data
      return `${marketing.minPurchaseAmountIdentity}${productUnit}包邮`
    },
    // 已拼数量
    soldOutProductNum() {
      return this.data.marketing.soldOutProductNum
    },
    // 成团进度
    rate() {
      /** V1.31
       * *成团信息，若设置“达成数量后可成团”为是，则展示“XX+单位+成团”提示。成团进度“XX%”提示。同商详页计算逻辑。
       * *若商品已达到成团数量，则隐藏成团信息，展示拼团进度XX%。计算逻辑：XX%=所有客户已拼数量/起拼数量，无小数。
       * */
      // 如果有成团数量 进度 = 已售 ÷ 成团数量 * 100
      if (this.data?.marketing?.shortLabal) {
        const r1 = Math.round(((this.data.marketing.soldOutProductNum || 0 )/ this.data?.marketing?.shortLabal) * 100)
        if (r1 < 100) {
          return r1
        }
      }
      // 如果无成团数量 进度 = 已售 ÷ 起拼数量 * 100
      return Math.round((this.data.marketing.soldOutProductNum || 0 ) / this.startBuyNum * 100)
    },
    // 加入购物车按钮禁用状态判断
    disabledBuy() {
      // 当商品无销售价格 或 无库存 或 手动禁用时 禁止购买
      return !this.originPrize || !this.stock || this.disabled
    }
  },
  mounted() {
    this.init()
    getProductLeftStock({
      activityId: this.data.marketing.activityId,
      activityType: 6,
      productId: this.data.productId
    }).then(res => {
      this.stock = res.data.availableStock
    })
  },
  methods: {
    init() {
      this.num = this.startBuyNum
    },
    show() {
      this.disabled = false
      // 初次加载显示库存
    },
    leave() {
      this.disabled = true
    },
    returnFloat,
    buy() {
      if (!this.num) {
        this.$Message.error('请输入拼团数量！')
        return
      }
      if (!this.stock) {
        return
      }
      if (!this.originPrize) {
        return
      }
      if (this.stock && this.stock < this.num) {
        this.$Message.error('拼团数量不能大于可用库存！')
        return
      }
      const { productId, marketing, orgId } = this.data
      const params = {
        productId,
        activityId: marketing.activityId,
        num: this.num,
        price: this.originPrize,
        actualPrice: this.activityPrice,
        activityType: 6,
        orgId
      }
      setStorageSync('settleProducts', [params])
      this.$router.push('/trade/orderConfirm')
    },
    blur() {
      if (!this.num) {
        this.num = ~~this.num
      }
      if (this.num < this.startBuyNum) {
        this.num = this.startBuyNum
      }
      if (this.num > this.stock) {
        this.num = this.stock
      }
    },
    change() {
      console.log('change')
    },
    // 检查商家建采信息
    checkOrgJcStatus() {
      const orgId = this.data.orgId
      this.$refs.checkOrgJcStatus.checkBuyStatus(orgId)
    },
    // 缺货登记
    handleOutOfStockReg() {
      this.$refs.outOfStockReg?.init({
        ...this.data,
        productId: this.data.productId,
        productSpec: this.data.unitSpec,
        mainPicUrl: this.data.picture, // 商品主图
        restNum: this.num
      })
    },
    // 跳转详情
    handleDetail() {
      this.$emit('goDetail')
    },
  }
}
</script>

<style scoped lang="less">

:deep(.group-buy-input){
  height: 36px;
  width: 116px;
  box-shadow: none;
  border-color:#DCDCDC;
  &:hover{
    border-color:#DCDCDC!important;
    outline: none;
  }
  .ivu-input-number-input{
    height: 36px;
  }

  .ivu-input-number-input-wrap{
    height: 36px;
  }

  .ivu-input-number-controls-outside-btn{
    height: 36px;
    line-height: 34px;
    width: 28px;
    background: #f5f5f5;
    i{
      font-size: 20px;
      line-height: 36px;
      color:#4B4B4B;
      &:before,&:after{
        font-size: 20px!important;
        font-weight: bold;
      }
    }
    &:hover i{
      color: #f99d33;
    }
  }
  .ivu-input-number-input{
    text-align: center;
  }
}
.outer{
  .btn{
    margin-left: 8px;
    border:0;
    width: 72px;
    height: 36px;
    cursor: pointer;
    background: url('~@/assets/productCards/group-buy-btn.png') no-repeat;
    background-size: 72px 36px;
    border-radius: 4px;

    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color:#fff;
    line-height: 36px;
    padding-right: 6px;
    text-align: right;
    &.disabled{
      background: url('~@/assets/productCards/buy-btn-disabled.png') no-repeat;
      background-size: 72px 36px;
    }
  }
}
.actions{
  margin-top: 8px;
  display: flex;
}
.progress{
  padding:7px 0;
  display: flex;
  align-items: center;
  .progress-line{
    height: 6px;
    flex-grow: 1;
    background: #FFF2E8;
    border-radius: 40px;
  }
  .progress-bar{
    height: 100%;
    border-radius: 30px;
    background: linear-gradient(90deg, #FA541C 70%, #FEA278 100%);
    max-width: 100%;
  }
  .reach-num{
    color:  rgba(0, 0, 0, 0.60);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-right: 8px;
  }
  .rate{
    margin-left: 8px;
    color: #D4380D;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
.price{
 overflow: hidden;
  height: 28px;
}
.activity-price{
  display: inline-block;
  height: 28px;
  color:#CB272D;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.origin-price{
  display: inline-block;
  height: 28px;
  color: rgba(0, 0, 0, 0.60);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line:line-through;
  margin-left: 8px;
}
.title{
  line-height: 20px;
  max-height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.prefix{
  position: relative;
  margin-right: 5px;
  padding-right: 5px;
  &::after{
    content: '';
    position: absolute;
    height: 14px;
    width: 1px;
    background: #1A1A1A;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.group-buy-info{
  margin-top: 2px;
  color: var(--font_black-2, rgba(0, 0, 0, 0.60));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.start-buy{
  position: relative;
  padding-left: 5px;
  margin-left: 5px;
  &:before{
    content: '';
    position: absolute;
    height: 12px;
    width: 1px;
    background: rgba(0, 0, 0, 0.60);
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.confirm-btn {
    color: #fafafa;
        width: 72px;
        height: 36px;
        background: #f7ba1e;
        border-radius: 4px;
        cursor: pointer;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 500;
        margin-left: 6px;
    }
</style>
