import {
  post
} from '@/common/utils'
import { api } from '@/common/service/config'
import CryptoJS from 'crypto-js'
import { obtainCustomerListAPI } from './insertCustInfo'
/**
   * 查询订单中心优惠券列表
   */
export async function getCouponCenterListApi(data, cb) {
  return post({
    url: `${api.baseUrl}/coupon/customer/queryCustomerReceiveCenter`,
    data: { businessType: 1, ...data },
    isLoading: true
  })
}
/**
   * 查询我的优惠券列表
   */
export async function getMyCouponListApi(data, cb) {
  return post({
    url: `${api.baseUrl}/coupon/customer/getCustomerCoupons`,
    data,
    isLoading: true
  })
}
/**
   * 领取优惠券
   */
export async function recieveCouponApi(couponId) {
  const res = await obtainCustomerListAPI()
  const customerName = res.data.find(item => item.inUse).customName
  const sign = CryptoJS.AES.encrypt(String(couponId), CryptoJS.enc.Utf8.parse('RYM-COUPON-ID-16'), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
  return post({
    url: `${api.baseUrl}/coupon/customer/receive`,
    data: {
      couponId,
      sign: sign,
      customerName
    },
    isLoading: true
  })
}

/**
   * 查询用户可用优惠券
   */
export async function getUserAvailableCouponApi(data) {
  return post({
    url: `${api.baseUrl}/coupon/customer/queryProductDetailCouponList`,
    data,
    isLoading: true
  })
}
/**
   * 查询优惠券可用商品列表
   */
export async function getCouponZoneProductList(data) {
  return post({
    url: `${api.baseUrl}/coupon/customer/getCouponZoneProductList`,
    data,
    isLoading: true
  })
}
/**
   * 
   */
export async function matchRecommendCoupon(data) {
  return post({
    url: `${api.baseUrl}/coupon/customer/matchRecommendCoupon`,
    data,
    isLoading: true
  })
}

