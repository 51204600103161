<template>
  <div class="v-floor" v-if="list?.length> 0" :id="'nav' + options.id">
    <div v-if="styleType == '8'" class="eight-product">
      <img :src="path" alt="" @click="cmsLink.bind(this)(options.props.link)" class="eight-img">
      <div class="eight-product-wrap">
        <ProductCard
            :goodsItem = "item"
            :isOrgAbbr = "false"
            v-for="(item, index) in list.slice(0, 8)"
            :class="['list_item','c149']"
            :imgLazy="true"
            :showAddCar="false"
            :key="index"></ProductCard>
      </div>
    </div>
    <div v-if="styleType == '6'" class="six-product">
      <img :src="path" alt="" @click="cmsLink(options.props.link)"  class="six-img">
      <div class="six-product-wrap">
        <ProductCard
          :goodsItem = "item"
          :isOrgAbbr = "false"
          v-for="(item, index) in list.slice(0, 8)"
          :class="['list_item','c160']"
          :imgLazy="true"
          :showAddCar="false"
          :key="index"></ProductCard>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * styleType  一图六商品: 5 一图八商品:8
 */
import { getFilePath } from '@/common/utils/base'
import { cmsLink, getData , detaiHandler} from '@/views/home/index'
// import ContentTitle from '@/components/ContentTitle'
// import ProductCard from '@/views/goodsItem'
import ProductCard from '@/components/ProductCards/index.vue'
export default {
  name: "v-floor",
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      cmsLink: cmsLink.bind(this),
      list: []
    };
  },
  components: {
    // ContentTitle,
    ProductCard
  },
  created() {
    getData.bind(this)(this.options?.props?.styleType).then(() => {
      this.$emit('load', this.options)
    }).catch(() => {
      this.$emit('load', this.options)
    })
  },
  computed: {
    // 获取一图地址
    path() {
      let key = 'orig';//this.styleType == '6' ? 'PS_03' : "PS_01"
      return getFilePath(key, this.options?.props?.fileId)
    },
    styleType() {
      return this.options?.props?.styleType
    }
  },
  watch: {
    list: {
      handler(val) {
        if( val.length> 0 ) {
          this.$store.commit('setHomeNav', this.options.id)
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="less">
@import url('../../product.less');
.v-floor {
  width: 1200px;
}
.eight-product-wrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  .product-item{
    border-left:0.5px solid #eee;
    &:nth-child(1),&:nth-child(5){
      border:0;
    }
    &:nth-child(n+4){
      border-top:0.5px solid #eee;
    }
  }
}
.eight-product {
  display: flex;
  .eight-img {
    cursor: pointer;
    width: 264px;
    //height: 540px;
  }
}
.six-product-wrap {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.six-product {
  position: relative;
  .six-img {
    cursor: pointer;
    position: absolute;
    left: 300px;
    width: 600px;
    height: 374px !important;
    }
  .product-item{
    border-left:0.5px solid #eee;
    &:nth-child(1),&:nth-child(2){
      border:0;
    }
    &:nth-child(n+3){
      border-top:0.5px solid #eee;
    }
    &:nth-child(3){
      border-left:0;
    }
  }
}
</style>
