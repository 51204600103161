<template>
  <div>
    <div v-if="data.length > 0" class="product-list">
      <div v-for="item in data" :key="item.name" class="list">
        <ProductCard v-if="type == 3" size="c220x356" :goods-item="item.prdProductEsVo">
          <template v-slot:collection>
            <div class="buy-count">
              买过<span class="text">{{ item.buyCount||0 }}</span>次
            </div>
          </template>
        </ProductCard>
        <ProductCard v-else :goods-item="item" :show-business="type!=='1'" size="c220x356">
          <template v-slot:collection>
            <div v-if="type==='1'" class="collection-price">
              <span class="text">收藏后</span>
              <span v-if="item.cutPriceAmount" class="price">¥{{ item.cutPriceAmount }}<svg-icon name="cut-price" /></span>
              <span v-else class="price-no-change">价格无变化</span>
            </div>
          </template>
        </ProductCard>
      </div>
    </div>
    <div v-else class="default">
      <img v-if="type == 1"  src="@/assets/images/center/collect.png" alt="">
      <img v-if="type == 2"  src="@/assets/images/center/footprint.png" alt="">
      <img v-if="type == 3"  src="@/assets/images/center/footprint.png" alt="">
    </div>
  </div>
</template>
<script>
// import ProductCard from '@/views/goodsItem'
import ProductCard from '@/components/ProductCards/index.vue'
export default {
  name: 'CenterProducts',
  components: {
    ProductCard
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {

    }
  }
}
</script>
<style scoped lang="less">
.default{
  width: 100%;
  text-align: center;
  border: none;
  img{
    width: 216px;
    height: 216px;
    object-fit: contain;
  }
}
.product-list {
  display: flex; gap: 14px; flex-wrap: wrap;
  &>div{
    border: 1px solid #EEE;
    border-radius: 8px;
  }
}
.list{
  position: relative;
  .buy-count{
    position: absolute;
    left:0;
    top:180px;
    width:100%;
    height:24px;
    text-align: center;
    line-height: 24px;
    background: rgba(238, 238, 238, 0.80);
    .text{
      color:#F76560;
    }
  }
}

.collection-price {
  margin-top: 6px;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  transform-origin: left top;
  .text {
    margin-right: 4px;
  }
  .price {
    color: #349425;
  }
  .price-no-change {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.4);
  }
}
</style>
