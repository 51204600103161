import { get,post } from '@/common/utils'
import {api} from "@/common/service/config";
/**
 * 商家店铺首页
 */

// 查询店铺首页装修数据
export const getHomePageData = (params) => get({
	url:`${api.baseUrl}/wx/store/getByCode?pageTypeCode=${params.pageTypeCode}&clientTypeCode=${params.clientTypeCode}&businessId=${params.businessId}`,
})

// 获取商家信息
export const getShopInfo = (params) => get({
	url:`${api.baseUrl}/org/info/simple?orgId=${params.orgId}`,
})

//获取商家详细信息
export const getShopReturnInfo = (id) => get({
	url: `${api.baseUrl}/org/info/salesReturn?orgId=${id}`,
})

//获取商家退换货说明
export const getShopDetail = (id) => get({
	url: `${api.baseUrl}/org/info/detail?orgId=${id}`,
})

//获取商家开户说明
export const getOpenAccount = (id) => post({
	url: `${api.baseUrl}/org/info/getOrgOpenAccount?orgId=${id}`,
})

//获取商家物流信息 
export const shopLogisticsInfo = (orgId) => get({
	url:`${api.baseUrl}/org/orglogisticsconfig/selectByorgIds?orgIds=${orgId}`,
	orgId
})

// 获取商家 Tab 数据
export const getShopTabs = (id) => {
	return get({
		url: `${api.baseUrl}/wx/store/getWxNavigationTabs?businessId=${id}&type=pc`
	})
}

// 获取其他导航页数据
export const getOtherDetails = (id) => {
	return get({
		url: `${api.baseUrl}/wx/store/getWxTabsDetails?pageId=${id}`
	})
}

// 获取商家用户评价数据
export const getUserEvaluate = (params) => {
	return post({
		url: `${api.baseUrl}/order/orderComment/list`,
		data: params
	})
}

// 判断用户和商家是否建立建采关系
export const getProcurementRelation = (param) => post({
	url: `${api.baseUrl}/cust/custuserrel/getCustRelByCustIdOrgId`,
	data: param
})

// 用户和商家建立建采关系
export const buildProcurementRelation = (param) => post({
	url: `${api.baseUrl}/cust/custuserrel/buildCustRelByCustIdOrgId`,
	data: param
})

// 获取商家全部商品
export const getShopAllData = (param) => post({
	url: `${api.baseUrl}/prd/product/listProductByEs`,
	data: param
})

// 获取商家公告
export const getNoticeOfBusiness = (id) => get({
	url: `${api.baseUrl}/wx/orgNotice/getRecent?orgId=${id}&size=3`
})

// 获取商家店铺状态
export const getMyCustomerInfoAPI = () => get({
	url: `${api.baseUrl}/cust/custuser/getMyCustomerInfo`,
  })

// 获取商家店铺状态
export const getSimpleMyCustomerInfo = () => get({
	url: `${api.baseUrl}/cust/custuser/getSimpleMyCustomerInfo`,
  })

  // 判断客户是否被商家加入黑名单
export const judegJoinBlacklist = (orgId) => {
	return get({
		url: `${api.baseUrl}/cust/custuserrel/isBlackListFlag?orgId=${orgId}`
	})
}
//关注店铺
export const addSaveShop = (param) => post({
	url: `${api.baseUrl}/user/orgCollect/add`,
	data: param
})
//查询店铺是否被关注&关注列表
export const queryShopSave = (param) => post({
	url: `${api.baseUrl}/user/orgCollect/query`,
	data: param
})
//取消关注店铺
export const deleteShopSaved = (param) => post({
	url: `${api.baseUrl}/user/orgCollect/delete`,
	data: param
})
//取消关注店铺
export const getOrgCollect = (param) => post({
	url: `${api.baseUrl}/user/orgCollect/queryList`,
	data: param
})

// 获取商家公告列表
export const findOrgNoticeListPage = (data) => post({
	url: `${api.baseUrl}/wx/orgNotice/findNoticeListPage`,
	data,
	isLoading: true
})
// 公告详情
export const getOrgNoticeDetail = (data) => get({
	url: `${api.baseUrl}/wx/orgNotice/get?id=${data}`
})

// 下载资质全部证件
export const downloadCertFile = (data) => get({
	url: `${api.baseUrl}/org/info/download?orgId=${data}`,
	config: { responseType: 'blob' },
})

// 下载开户全部证件
export const downloadOpenAccountFile = (data) => get({
	url: `${api.baseUrl}/org/info/downloadOpenAccountFile?orgId=${data}`,
	config: { responseType: 'blob' },
})