import Vue from 'vue'
import Vuex from 'vuex'
import product from './modules/product.js'
import InfoEnter from './modules/InfoEnter.js'
import order from './modules/order.js'
import cms from './modules/cms'
import cmsModal from './modules/cmsModal'
import common from './modules/common'
import car from './modules/car'
import shopHome from './modules/shopHome'
import global from './modules/global'
import home from './modules/home'
import center from './modules/center'
import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})
Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    product,
    InfoEnter,
    order,
    cms,
    cmsModal,
    common,
    car,
    shopHome,
    global,
    home,
    center
  },
  plugins: [vuexLocal.plugin]
})
