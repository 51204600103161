import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vViewer from 'v-viewer'
// import 'view-design/dist/styles/iview.css'
import viewDesigin from './config/viewDesign.config'
import '@/common/service/interceptor.js'
import '@/assets/css/theme.less'
import '@/assets/css/normalize.css'
import '@/assets/css/common.css'
import '@/components'
import './assets/icons'
import 'viewerjs/dist/viewer.css'
import VueClipBoard from 'vue-clipboard2'
import * as Sentry from '@sentry/vue'
Vue.use(VueClipBoard)

Vue.use(vViewer)

Vue.prototype.$bus = new Vue()

viewDesigin(Vue)
Vue.config.productionTip = false
if (window.location.hostname.indexOf('www.crrysc.com') > -1) {
  Sentry.init({
    dsn: 'https://f600ff67f9cd29b0c892e2bc515adb81@sentry.crpcg.com/16',
    environment: 'production',
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

// 同步vuex数据（多标签之间,目前用于购物车选中状态同步，请勿全局同步）
window.addEventListener('storage', (event) => {
  if (event.key === 'vuex') {
    const obj = JSON.parse(event.newValue || {})
    const str1 = JSON.stringify(store.state.car.selectedProductList || [])
    const str2 = JSON.stringify(obj?.car?.selectedProductList || [])
    if (str1 !== str2) {
      store.commit('car/SET_SELECTED_PRODUCT_LIST', obj?.car?.selectedProductList || [])
    }
    // store.replaceState(JSON.parse(event.newValue || {}));
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
