import CommonRouterView from '@/router/common-router-view'
export default [
  {
    path: '/search/list',
    name: 'searchList',
    component: () => import('@/views/product/search')
  },
  {
    path: '/shop/list',
    name: 'shopList',
    component: () => import('@/views/product/businessList')
  },
  {
    path: '/activity/groupCenter',
    name: 'GroupCenter',
    meta: { title: '拼团中心' },
    component: () => import('@/views/product/ActivityCenter/GroupCenter')
  },
  {
    path: '/activity/giveCenter',
    name: 'GiveCenter',
    meta: { title: '满赠中心' },
    component: () => import('@/views/product/ActivityCenter/GiveCenter')
  },
  {
    path: '/activity/priceDownCenter',
    name: 'PriceDownCenter',
    meta: { title: '润划算中心' },
    component: () => import('@/views/product/ActivityCenter/PriceDownCenter')
  },
]
