<template>
  <div class="box">
    <div class="caption">
      收货地址

      <span class="caption-right" @click="changeInfo">{{ text }}</span>
    </div>
    <div v-if="companyInfo.customTypeChangeState !== 'under_auth'" class="border-bottom-dashed padding-bottom-20 margin-bottom-20" @click="toPage('/center/enterprise/change', 'ReceiverAddress', 'add')">
      <Button class="add-btn" icon="md-add">新增收货地址</Button>
    </div>
    <!-- {{ data }} -->
    <!-- {{ info }} -->
    <div v-for="item in info" :key="item.receiveAddrId">
      <div style="display: flex; align-items: center; gap: 10px;">
        <CustomerTag v-if="isShow" :color="item.state" :name="formatStatus(item.state)" :reason="item.rejectReason" />
        <!-- <CustomerTag v-if="!isShow" :type="'dot'" :color="item.approvalState" :name="item.approvalStateDesc" :reason="item.rejectReason" /> -->
      </div>
      <div class="box-content">
        <CustomerSteps v-if="!isShow" :nodes="item.historyLogList" :reject-reason="item?.rejectReason" />
        <div v-if="!isShow" class="border-right-dashed" />
        <div class="list">
          <div class="list-item">
            <span class="label">收货人</span>
            <span class="value"> {{ item.receiveContact }}</span>
          </div>
          <div class="list-item">
            <span class="label">联系电话</span>
            {{ item.receiveTel }}
          </div>
          <div class="list-item">
            <span class="label">收货地区</span>
            {{ item.receiveDistrict.provinceName + item.receiveDistrict.cityName + item.receiveDistrict.districtName }}
          </div>
          <div class="list-item">
            <span class="label">详细地址</span>
            <span class="value"> {{ item.receiveAddr }}</span>
          </div>
          <div class="list-item">
            <!-- <div class="list-item">
              <Button v-if="isShow && item.approvalState != 'under_auth' && item.approvalState != 'refuse_auth' && refuseNum == 1" @click="toPage('/center/enterprise/change', 'ReceiverAddress', 'edit', item)">变更</Button>
              <Button v-if="isShow && item.approvalState != 'under_auth'" @click="toPage('/center/enterprise/change', 'ReceiverAddress', 'edit', item)">变更</Button>
              <router-link v-if="isShow && item.approvalState != 'under_auth' && refuseNum > 1" to="/center/enterprise/batch">
                <Button>
                  完善
                </Button>
              </router-link> -->

            <!-- <Button v-if="!isShow && item.approvalState != 'under_auth' && item.approvalState != 'refuse_auth' && refuseNum == 1" @click="toPage('/center/enterprise/change', 'ReceiverAddress', 'edit', item)">变更</Button>
              <router-link v-if="!isShow && item.approvalState != 'under_auth' && refuseNum > 1" to="/center/enterprise/batch">
                <Button>
                  完善
                </Button>
              </router-link> -->

            <!-- <Button v-if="!isShow && item.approvalState === 'refuse_auth' && refuseNum < 2" @click="toPage('/center/enterprise/change', 'ReceiverAddress', 'edit', item)">完善</Button>
              <router-link v-if="!isShow && item.approvalState === 'refuse_auth' && refuseNum > 1" to="/center/enterprise/batch">
                <Button>
                  完善
                </Button>
              </router-link> -->

            <!-- <Button v-if="!isShow && item.approvalState != 'under_auth' && item.approvalState == 'auth'" @click="toPage('/center/enterprise/change', 'ReceiverAddress', 'edit', item)">变更</Button>
              <router-link v-if="!isShow &&  item.approvalState != 'under_auth' && item.approvalState === 'refuse_auth'" to="/center/enterprise/batch">
                <Button>
                  完善
                </Button>
              </router-link> -->

            <!-- <Button v-if="item.approvalState !== 'under_auth'"  @click="toEdit(item)">{{ item.approvalState
            === 'auth' ? "去变更" : "去完善" }}</Button> -->
            <!-- </div> -->

            <div v-if="item.approvalState !== 'under_auth' && companyInfo.customTypeChangeState !== 'under_auth'">
              <router-link v-if="refuseNum > 1 && item.approvalState == 'refuse_auth'" to="/center/enterprise/batch">
                <Button class="reBtn">
                  变更
                </Button>
              </router-link>
              <Button v-else class="reBtn" @click="toPage('/center/enterprise/change', 'ReceiverAddress', 'edit', item)">变更

              </button></div>
          </div>
        </div>
      </div>
      <!-- <div class="border-bottom-dashed margin-bottom-20" /> -->
    </div>
  </div>
</template>
<script>
import {
  getApprovalNodeList,
  getApprovalAllAddrInfo
} from '@/common/service'
import CustomerTag from './CustomerTag.vue'
import CustomerSteps from './CustomerSteps.vue'
export default {
  name: 'Address',
  components: {
    CustomerTag,
    CustomerSteps
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    companyInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    refuseNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isShow: true,
      info: [],
      afterChange: null
    }
  },
  computed: {
    text() {
      return this.isShow ? '查看变更' : '返回'
    }
  },
  created() {
    this.info = [...this.data]
    this.getNodeList()
  },
  methods: {
    toPage(path, componentName, scene, data) {
      if (scene === 'edit') {
        this.$store.dispatch('InfoEnter/SET_CONSIGNEE_INFO', {
          receiveAddrId: data.receiveAddrId,
          receiveContact: data.receiveContact, // 收货人
          receiveTel: data.receiveTel, // 联系方式
          receiveAddr: data.receiveAddr, // 注册地址
          receiveDistrictName: data.receiveDistrict.provinceName + data.receiveDistrict.cityName + data.receiveDistrict.districtName, // 省市区
          receiveDistrictCode: data.receiveDistrict.districtCode,
          district: [data.receiveDistrict.provinceCode, data.receiveDistrict.cityCode, data.receiveDistrict.districtCode]
        })
      } else {
        this.$store.dispatch('InfoEnter/SET_CONSIGNEE_INFO', null)
      }
      this.$router.push({
        path,
        query: {
          name: componentName,
          scene
        }
      })
    },
    changeInfo() {
      if (this.isShow) {
        this.$set(this, 'info', this.afterChange)
      } else {
        this.$set(this, 'info', this.data)
      }
      this.isShow = !this.isShow
    },
    // 获取节点
    async getNodeList() {
      const { code, data } = await getApprovalNodeList()
      if (code === 200) {
        this.nodes = data
        this.nodes.forEach(item => {
          item.approvalState = 'under_auth'
          item.approvalName = '待审核'
          item.updateTime = ''
        })
        this.getAllAddrInfo()
      }
    },
    // 获取地址信息
    async getAllAddrInfo() {
      const { code, data } = await getApprovalAllAddrInfo()
      if (code === 200) {
        this.afterChange = data.map(item => {
          item.historyLogList[0].approvalState = 'auth'
          item.historyLogList[0].nodeName = '我'
          item.historyLogList[0].approvalName = '发起申请'
          if (item.historyLogList.length === 1) {
            item.historyLogList.push(this.nodes[1], this.nodes[2])
          }
          if (item.historyLogList.length === 2 && item.historyLogList[1].approvalState !== 'refuse_auth') {
            item.historyLogList.push(this.nodes[2])
          }
          const historyLogList = item.historyLogList.map(ih => {
            ih.title = `${ih.nodeName}.${ih.approvalName ? ih.approvalName : '待审核'}`
            return { ...ih }
          })
          return {
            ...item.approvalReceiveAddrWxVo,
            historyLogList
          }
        })
        console.log('this.afterChange', this.afterChange)
      }
    },
    formatStatus(val) {
      const enumObj = {
        'under_auth': '认证中',
        'auth': '已认证',
        'refuse_auth': '未通过',
        'part_auth': '部分未通过'
      }
      return enumObj[val]
    }
  }
}
</script>
<style scoped lang="less">
.list {
  padding-top: 10px;
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    font-size: 14px;
    .label {
      width: 88px;
      display: inline-block;
      padding-right: 16px;
      color: rgba(0, 0, 0, 0.4);
    }

    .value{
      flex: 1;
    }
  }
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.caption-right:hover{
  color: #3853E2;
  cursor: pointer;
}

.caption-right{
  color: var(--font_black-3, rgba(0, 0, 0, 0.40));
  /* regular/12 */
  font-family: PingFang SC;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
</style>
