<template>
  <!-- <div class="user" @mouseenter="mouseenterFun" @mouseleave.prevent="mouseleaveFun"> -->
  <div class="user">
    <Avatar :size="48" style="margin: 0 auto;" :src="require('@/assets/images/home/avatar.png')" />
    <p class="custom-name line-clamp-2" @click="routerTo('/center/enterprise?customerId=' + coustomData.customerId)">
      <span
        v-if="coustomData?.customName"
        class="type"
        style="white-space: nowrap;"
        :style="{ 'background-color': stateConputed(coustomState)?.backgroundColor, 'color': stateConputed(coustomState)?.color, 'border-color': stateConputed(coustomState)?.borderColor }"
      >{{
          stateConputed(coustomState)?.name }}
      </span>
      {{ coustomData?.customName || '尚未关联采购主体，请先' }}<a
        v-if="!coustomData?.customName"
        @click.prevent.stop="routerTo('/affiliated')"
      >去关联
        <!-- <Icon type="md-arrow-forward" /> -->
      </a>
    </p>
    <!-- <span v-if="!coustomData?.customName" style="font-size: 14px;color: rgba(0, 0, 0, 0.6);line-height: 22px;">关联采购主体并通过平台审核后，方可采购下单</span>
     <Tooltip :content="defaultAddress" placement="top" max-width="200">
      <p class="line-clamp-1 address">
        <svg-icon name="address" className="address"></svg-icon>
        {{defaultAddress}}
      </p>
    </Tooltip> -->
    <!--产品要求注释掉-->
    <!-- <p v-if="coustomData.state == 'under_auth'" style="margin-top: 8px;font-size: 12px;">请耐心等待平台审核，<a @click.prevent.stop="routerTo('/affiliated')">去查看审核进度</a></p> -->
    <!-- <ChangeCustom v-model="showChangeCustom" class="change-custom" @mouseenterHandler = 'mouseenterHandler' @mouseleaveHandler = 'mouseleaveHandler'></ChangeCustom> -->
    <template v-if="state === 1">
      <div class="coupon-list">
        <CouponItem v-for="(item, index) in couponList" :key="index" :item-data="item" />
      </div>
      <Notice :is-show-coupon="couponList?.length > 2 ? true : false" />
    </template>
    <template v-if="state === 2">
      <div>
        <div class="orders">
          <div class="item" @click="routerTo('/order?tab=PAYING')">
            <p>{{ orderStatus.payingCount }}</p>
            <p>待付款</p>
          </div>
          <div class="item" @click="routerTo('/order?tab=TAKING')">
            <p>{{ consignment }}</p>
            <p>待发货</p>
          </div>
          <div class="item" @click="routerTo('/order?tab=DELIVERED')">
            <p>{{ orderStatus.deliveredCount }}</p>
            <p>配送中</p>
          </div>
        </div>
        <div class="collect-business">
          <div class="item" @click="routerTo('/center/collection')">
            <p>{{ collectNum }}</p>
            <p>收藏商品</p>
          </div>
          <div class="item" @click="routerTo('/center/merchant')">
            <p>{{ successAuthNum }}</p>
            <p>建采商家</p>
          </div>
          <div class="item" @click="routerTo('/coupon/index')">
            <p>{{ myCouponNum }}</p>
            <p>我的优惠券</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Notice from '@/views/home/coms/notice.vue'
import {
  getMyCustomerInfoAPI,
  countByStateAPI,
  obtainCustomerListAPI,
  switchUserIdentityAPI,
  cancelWarnCertState
} from '@/common/service/insertCustInfo'
import {
  matchRecommendCoupon,
  getMyCouponListApi
} from '@/common/service/coupon'
import { listCollectProductToEs, getorderStats } from '@/common/service'
// import ChangeCustom from '@/components/ChangeCustom.vue'
import CouponItem from './CouponItem'
import { setStorageSync } from '@/common/utils/storage'
export default {
  name: 'User',
  components: {
    // ChangeCustom,
    Notice, CouponItem
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      coustomData: {},
      defaultAddress: '',
      state: -1,
      stateList: [
        {
          state: 'frozen',
          name: '冻结',
          backgroundColor: '#EEE',
          color: 'rgba(0, 0, 0, 0.60)',
          borderColor: '#777'
        },
        {
          state: 'un_auth',
          name: '未认证',
          backgroundColor: 'rgba(249, 157, 51, 0.08)',
          color: '#A35511',
          borderColor: '#CE7720'
        },
        {
          state: 'under_auth',
          name: '认证中',
          backgroundColor: 'rgba(56, 83, 226, 0.08)',
          color: '#2438BC',
          borderColor: '#3853E2'
        },
        {
          state: 'auth',
          name: '已认证',
          backgroundColor: 'rgba(52, 148, 37, 0.08)',
          color: '#1F7C15',
          borderColor: '#349425'
        }
      ],
      collectNum: '-', // 收藏数
      successAuthNum: '-', // 建采数
      orderStatus: {
        payingCount: '-',
        deliveredCount: '-',
        deliveringCount: null,
        billingCount: null,
        takingCount: null
      },
      showChangeCustom: false, // 切换客户
      showChangeCustomMove: false, // 切换客户
      couponList: [],
      myCouponNum: '-' // 我的优惠券数
    }
  },
  computed: {
    consignment() {
      let data = null
      if (this.orderStatus.deliveringCount != null) {
        data ??= 0
        data += this.orderStatus.deliveringCount
      }
      if (this.orderStatus.billingCount != null) {
        data ??= 0
        data += this.orderStatus.deliveringCount
      }
      if (this.orderStatus.takingCount != null) {
        data ??= 0
        data += this.orderStatus.deliveringCount
      }
      data ??= '-'
      return data
    },
    coustomState() {
      return this.coustomData?.controlState == 'frozen' ? this.coustomData?.controlState : this.coustomData?.state
    }
  },
  watch: {
    showChangeCustom() {
      if (!this.showChangeCustom) {
        this.showChangeCustomMove = false
      }
    },
    data() {
      this.init()
    }
  },
  created() {
    // 用户信息
    this.getUser()
    // 收藏
  },
  methods: {
    async init() {
      const couponDatas = this.data || []
      if (couponDatas.length !== 0) {
        this._getCoupon(couponDatas, (list) => {
          if (list.length !== 0) {
            this.state = 1
            this.couponList = list
          } else {
            this.handelLoadCard()
          }
        })
      } else {
        this.handelLoadCard()
      }
    },
    async handelLoadCard() {
      this.state = 2
      await this.getCollectProductToEs()
      // 获取建采数
      await this.countByState()
      // 订单
      await this.getOrderCount()
      await this.getMyCouponNum()
    },
    async getUser() {
      const { data } = await getMyCustomerInfoAPI()
      this.coustomData = data
      setStorageSync('customerInfo', data)
      const defaultAddressItem = data.receiveAddrList.find((item) => item.isCheck === 1)
      this.defaultAddress = defaultAddressItem.receiveDistrict.provinceName + defaultAddressItem.receiveDistrict.cityName + defaultAddressItem.receiveDistrict.districtName + defaultAddressItem.receiveAddr
      if (this.coustomData.warnState === 1) {
        this.$Modal.confirm({
          title: '温馨提示',
          content: `<div style="text-align: left;color:rgba(0, 0, 0, 0.60);">根据最新药监法规要求，您需要在<span style='color:rgba(0, 0, 0, 0.90);'>${this.coustomData.warnDay}天</span>内补充提交<span style='color:rgba(0, 0, 0, 0.90);'>${this.coustomData.requiredCertNames}</span>。如未及时提交，您的账户可能会被自动冻结。给您带来不便，我们深感抱歉，感谢您的理解和配合。如有问题，请联系平台。</div>`,
          okText: '立即提交',
          cancelText: '暂不提交',
          onOk: () => {
            this.$router.push({
              name: 'AddMustCustCert',
              query: {
                name: 'AddMustCustCert'
              }
            })
          },
          onCancel: async() => {
            await cancelWarnCertState()
          }
        })
      }
    },
    stateConputed(val) {
      const returnData = this.stateList.find((item) => {
        return item?.state === val
      })
      return returnData
    },
    routerTo(path) {
      window.open(location.origin + path)
    },
    // 获取收藏商品数
    async getCollectProductToEs() {
      const { data } = await listCollectProductToEs({
        page: {
          pageNumber: 1,
          pageSize: 5
        }
      })
      if (data?.total != null) { this.collectNum = data?.total }
    },
    // 获取建采商家数
    async countByState() {
      const { code, data } = await countByStateAPI()
      if (code === 200) {
        if (data.successAuthNum != null) { this.successAuthNum = data.successAuthNum }
      }
    },
    // 获取订单信息
    async getOrderCount() {
      const { code, data } = await getorderStats()
      if (code === 200) {
        const keys = ['PAYING', 'DELIVERING', 'TAKING', 'BILLING', 'DELIVERED']
        data.forEach((item) => {
          Object.keys(item).map((key) => {
            if (keys.includes(item[key])) {
              this.$set(
                this.orderStatus,
                item[key].toLowerCase() + 'Count',
                item.stateCount
              )
            }
          })
        })
      }
    },
    mouseenterFun() {
      if (this.coustomData?.state == 'auth') {
        this.showChangeCustom = true
        if (this.coustomTimer) { clearTimeout(this.coustomTimer) }
        setTimeout(() => {
          if (this.coustomTimer) { clearTimeout(this.coustomTimer) }
        }, 200)
      }
    },
    mouseleaveFun() {
      if (this.coustomData.state == 'auth') {
        this.coustomTimer = setTimeout(() => {
          if (!this.showChangeCustomMove) { this.showChangeCustom = false }
        }, 200)
      }
    },
    mouseenterHandler() {
      this.showChangeCustomMove = true
    },
    mouseleaveHandler() {
      this.showChangeCustomMove = false
      this.mouseleaveFun()
    },
    _getCoupon(couponDatas, back) {
      matchRecommendCoupon(couponDatas).then(res => {
        back(res?.data || [])
      })
    },
    // 获取我的优惠券数
    async getMyCouponNum() {
      const { code, data } = await getMyCouponListApi()
      if (code === 200) {
        this.myCouponNum = data?.length 
      }
    }
  }
}
</script>
<style scoped lang="less">
.user {
  height: 406px;
  // margin-bottom: 16px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  // align-items: center;
  border-radius: 8px;
  position: relative;
}

.custom-name {
  margin-top: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.9);
  user-select: none;

  cursor: pointer;

  // display: flex;
  // align-items: center;
  .type {
    border-radius: 4px;
    font-weight: 400;
    line-height: 10px;
    padding: 4px 4px 3px;
    margin-right: 4px;
    display: inline-block;
    box-sizing: border-box;
    border: 1px solid;
    font-size: 10px;
    box-sizing: border-box;
    vertical-align: text-top;
  }

}

.address {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}

.orders {
  display: flex;
  // justify-content: space-between;
  margin-top: 8px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & p:nth-child(1) {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
    }

    & p:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
    }

    &:nth-child(1) p:nth-child(1) {
      color: #F99D33;
    }

    &:nth-child(2) p:nth-child(1) {
      color: #3853E2;
    }

    &:nth-child(3) p:nth-child(1) {
      color: #1F7C15;
    }
  }
}

.item {
  width: 73px;
  height: 54px;

  &:hover {
    background: #F5F5F5;
  }
}

.collect-business {
  display: flex;
  // background: #FAFAFA;
  margin-top: 8px;
  width: max-content;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;

    & p:nth-child(1) {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
    }

    & p:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.change-custom {
  left: -412px;
  top: 78px;
}

.svg-icon.address {
  width: 12px;
  height: 12px;
  margin-bottom: 1px;
}

::v-deep .ivu-tooltip {
  height: 22px;
}

.coupon-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
}

.user{
  ::v-deep .ivu-avatar{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .ivu-avatar > img{
    vertical-align: middle;
  }
}
</style>
