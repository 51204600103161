// 分类卡片
<template>
  <div v-if="show" :id="'nav' + options.id" class="v-product-card">
    <ContentTitle :title="options?.props?.title" />
    <div class="card-list">
      <div v-for="item in data" :key="item.id" class="card">
        <div class="head" @click="cmsLink.bind(this)(item.link)">
          <span class="title">{{ item.title }}</span>
          <div class="more" @click.stop="more(item)">
            更多
            <Icon style="margin-left: 4px;" type="ios-arrow-forward" />
          </div>
        </div>
        <div class="card-wrap">
          <ProductCard
            v-for="(product, index) in item.records"
            :key="index"
            size="c120"
            :goods-item="product"
            :is-org-abbr="false"
            :img-lazy="true"
            :show-add-car="false"
            class="list_item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * styleType  一图四商品: 4 一图五商品: 5 六图八商品:8
 */
import { getFilePath } from '@/common/utils/base'
import { cmsLink, getData, detaiHandler } from '@/views/home/index'
import ContentTitle from '@/components/ContentTitle'
import ProductCard from '@/components/ProductCards/index.vue'
export default {
  name: 'VProductCard',
  components: {
    ContentTitle, ProductCard
  },
  props: {
    options: Object,
    cmsId: String
  },
  data() {
    return {
      getFilePath,
      detaiHandler,
      cmsLink: cmsLink.bind(this),
      list: [],
      show: false,
      data: []
    }
  },
  computed: {
    // 获取一图地址
    path() {
      return getFilePath('PS_05', this.options?.props?.fileId)
    },
    styleType() {
      return this.options?.props?.styleType
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const list = this.options?.props?.productsList
      list?.forEach((item) => {
        getData.bind(this)(3, item.id, item.type).then((data) => {
          if (data?.length > 0) {
            item.records = data
            if (!this.show) {
              this.$store.commit('setHomeNav', this.options.id)
              this.show = true
            }
            this.setData()
          }
          this.$emit('load', this.options)
        })
      })
    },
    setData() {
      this.data = this.options?.props?.productsList?.filter((item) => item.records?.length > 0) || []
    },
    // 跳更多页
    more(data) {
      const cmsModelId = data.id
      const type = data.type
      this.$router.push(`/more?type=${type}&cmsModelId=${cmsModelId}`)
    }
  }
}
</script>
<style scoped lang="less">
@import url('../../product.less');
.v-product-card {
  width: 1200px;
}
.card-list {
  display: flex;
  flex-wrap: wrap;
  .head {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 16px;
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      color: #181818;
    }
    .more {
      margin-left: auto;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 26px;
      color: #181818;
      cursor: pointer;
    }
  }
  .card-wrap {
    display: flex;
    height: 100%;
    .product-item:nth-child(1){
      border:0;
    }
    .product-item{
      height: 100%!important;
    }
  }
  .card {
    width: 594px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    &:nth-child(1) {
      margin: 0 12px 0 0;
    }
    &:nth-child(2) {
      margin: 0 0 0 0;
    }
    &:nth-child(3) {
      margin: 12px 12px 0 0;
    }
     &:nth-child(3) {
      margin: 12px 0 0 0;
    }
    &:nth-child(4) {
      margin: 12px 0 0 12px;
    }
    &:nth-child(1) .head {
      background: linear-gradient(90.02deg, #F5EBDB 6.18%, #EDD2A9 68.41%), #FFFFFF;
    }
    &:nth-child(2) .head {
      background: linear-gradient(90.13deg, #D6E8C9 5.2%, rgba(168, 209, 127, 0.56) 75.6%);
    }
    &:nth-child(3) .head {
      background: linear-gradient(90.13deg, #CBE8E1 5.2%, rgba(141, 207, 203, 0.56) 75.6%);
    }
    &:nth-child(4) .head {
      background: linear-gradient(90.23deg, #DCE9F5 6.25%, #CDDEF7 75.88%);
    }
  }
}

.c120{
  border-left:0.5px solid #eee;
}
</style>
