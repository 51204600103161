import { render, staticRenderFns } from "./GroupBuyPop.vue?vue&type=template&id=515a188c&scoped=true&"
import script from "./GroupBuyPop.vue?vue&type=script&lang=js&"
export * from "./GroupBuyPop.vue?vue&type=script&lang=js&"
import style0 from "./GroupBuyPop.vue?vue&type=style&index=0&id=515a188c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "515a188c",
  null
  
)

export default component.exports