// 全部商品分类
<template>
  <div class="all-product-class">
    <p class="left">
      <span @mouseenter="hoverHandler" @mouseleave="mouseleaveHandler()" >
        <svg-icon name="layout-grid" className="layout-grid-icon" ></svg-icon>全部商品分类<Icon type="ios-arrow-forward" class="ios-arrow-forward-cion" />
      </span>
    </p>
    <div class="right">
      <Tabs :list="tabList" @change="tabsChangehandler" :selectedType="selectedType"></Tabs>
    </div>
     <div class="modal" v-show="showModal" @mouseleave="modalMouseleaveHandler" @mouseenter="modalHoverHandler()">
      <div class="first">
        <div 
          class="first-item" 
          :class="detailId == item.value && 'hover'"
          @mouseleave="firstMouseleaveHandler" @mouseenter="firstHoverHandler( item.value)"
          v-for="item in categoryList" :key="item.value">
          <p class="first-name" @click="routerTo('/search/list?categoryId=' + item.value)">{{item.title}} </p>
          <Icon type="ios-arrow-forward" class="first-name-cion" />
        </div>
      </div>
      <div class="modal-warp">
        <div class="two-item" v-for="detail in detailData" :key="detail.id">
          <span class="two-name" @click="routerTo('/search/list?categoryId=' + detail.id)">{{detail.categoryName}} <Icon type="ios-arrow-forward" /></span>
          <div class="three-warp">
            <div class="three-item" 
              v-for="threeData in detail.children" 
              @click="routerTo('/search/list?categoryId=' + threeData.id)"
              :key="threeData.id" >
              {{threeData.categoryName}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPcTabData } from '@/common/service/home'
import { cmsLink } from '@/views/home/index'
import Tabs from "@/components/Tabs";
import { getClassification, categoryClassificationSon } from '@/common/service/cate'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'AllProductClass',
  data() {
    return {
      tabList: [],
      detailData: null,
      showModal: false,
      detailName: '',
      detailId: ''
    }
  },
  props: {
    selectedType: {
      type: String,
      default: 'home'
    }
  },
  components: {
    Tabs
  },
  created() {
    this.list = {}
    if (sessionStorage.getItem('tabList')) {
      this.tabList = JSON.parse(sessionStorage.getItem('tabList')).tabList
    } else {
      this.getPcTabData()
    }
  },
  computed: {
    ...mapState({
      categoryList: (state) => state.home.classData
    }),
  },
  methods: {
    tabsChangehandler(data) {
      if( data?.name == '全部商品' ) {
        this.$router.push('/search/list?type=homeShop')
      }else {
        cmsLink.bind(this)(data.link)
      }
    },
    async getPcTabData() {
      const { data } = await getPcTabData()
      this.tabList = [...data.configContent.defaultList, ...data.configContent.list]
      sessionStorage.setItem('tabList', JSON.stringify({ tabList: this.tabList }))
    },
    async hoverHandler() {
      this.timer && clearTimeout(this.timer)
      this.showModal = true
    },
    mouseleaveHandler() {
      this.timer = setTimeout(()=> {
        this.showModal = false
      }, 200)
    },
    async getClassification(id) {
      const { data } = await getClassification({
        id
      })
      this.list[id] = data
      if(id == this.detailId ) {
        this.detailData = data
      }
    },
    modalMouseleaveHandler() {
      this.mouseleaveHandler()
      
    },
    modalHoverHandler() {
      this.timer && clearTimeout(this.timer)
    },
    firstMouseleaveHandler() {

    },
    firstHoverHandler(id) {
      this.detailId = id
      this.detailName = name
      this.detailData = null
      if( !this.list[id] ) {
        this.getClassification(id)
      } else {
        this.detailData = this.list[id]
      }
    },
    routerTo(path) {
      this.$router.push(path)
    },
  },
  mounted() {
    this.categoryList.length > 0 && (this.getClassification(this.categoryList[0].value), this.detailId = this.categoryList[0].value)
  },
  watch: {
    categoryList(data) {
      data?.forEach(async (item)=> {
        this.getClassification(item.value)
      });
      this.detailId = data?.[0]?.value || ''
    }
  }
}
</script>
<style scoped lang="less">
.all-product-class {
  width: 1200px;
  height: 40px;
  margin: 0 auto;
  padding-top: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  display: flex;
  color: rgba(0, 0, 0, 0.60);
  cursor: pointer;
  position: relative;
}
.left {
  width: 274px;
}
.right {
  margin-left: 12px;
  flex: 1;
}
::v-deep .root {
  height: 100% !important;
  margin-bottom: 0px !important; 
  & .tabs-list *:nth-child(2) {
    display: none;
  }
  & .content .tabs .tabs-list *:nth-child(1) {
   margin: 0 40px 0 0 !important;
  }
}
::v-deep .content {
  width: auto !important;
  padding-top: 0px !important; 
}
::v-deep .content .tabs {
  .tabs-list .selectde-tab {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.90) !important;
    font-weight: 500 !important;
    position: relative;
    &::before {
      content: ' ';
      position: absolute;
      bottom: -13px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 70%;
      height: 4px;
      border-radius: 10px;
      background-color: #F99D33;
    }
  }
  .tabs-item {
    font-weight: 400 !important;
  }
}
.layout-grid-icon {
  margin-right: 8px;
}
.ios-arrow-forward-cion {
  transform: rotate(90deg);
  margin-left: 4px;
}
.modal {
  width: 826px;
  // height: 520px;
  overflow-y: auto;
  background: #FFFFFF;
  border-radius: 8px;
  position: absolute;
  z-index: 4;
  left: 0;
  top: 56px;
  box-shadow: 5px 5px 10px -4px rgba(0, 0, 0, 0.12), 5px -5px 10px -4px rgba(0, 0, 0, 0.12);
  display: flex;
  min-width: 0;
  .first {
    width: 150px;
    height: 424px;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(#EEE);
    background: var(#FFF);
    box-shadow: 0px 16px 20px -10px rgba(0, 0, 0, 0.04), 0px 32px 48px 4px rgba(0, 0, 0, 0.02), 0px 12px 60px 10px rgba(0, 0, 0, 0.03);
    overflow-y: auto;
    .first-item {
      padding: 8px;
      box-sizing: border-box;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover, &.hover {
        background-color: #F5F5F5;
        .first-name-cion {
          display: block;
        }
      }
    }
    .first-name {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.90);
      &:hover {
        color: #CE7720;
      }
    }
    .first-name-cion {
      display: none;
    }
  }
  .modal-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.9);
    height: 56px;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
  .modal-warp {
    padding: 8px 24px;
    flex: 1;
    height: 424px;
    overflow-y: auto;
  }
  .two-name {
    width: 82px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
    &:hover {
      color: #CE7720;
    }
  }
  .two-item {
    padding: 16px 0 10px ;
    display: flex;
    border-style: solid;
    border-color: #E7E7E7;
    border-width: 0 0 1px 0;
    &:last-child {
      border-bottom-width: 0;
    }
  }
  .three-warp {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  .three-item {
    margin: 0 16px 6px 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6);
    &:hover {
      color: #CE7720;
    }
  }
}
</style>
