import axios from 'axios'
import {
  getStorageSync
} from '@/common/utils/storage'
const requestWhiteList = [
  'v1/wx/register',
  'v1/wx/login',
  'register/obtainVCode',
  'register/validateVCode',
  'register/submit',
  'forgot/obtainVCode',
  'forgot/validateVCode',
  'forgot/submit',
  'agr/obtainLatestAgreement',
  '/order/cust/othersPayDetail',
  '/order/cust/queryPayState',
  '/opt/valueSetItem/list',
  '/v1/wx/code',
  '/slider/generate',
  '/slider/check',
  '/v1/wx/login',
  '/wx/page/findAgreeMentList',
  '/wx/config/getConfigByCode',
  '/v1/wx/scheme'
]
const reqInterceptor = async function(config) {
  const token = getStorageSync('accessToken')
  const requestWhiteStatus = requestWhiteList.some(item => config.url.includes(item))
  // 万一有用呢   token只加非白名单
  if (!requestWhiteStatus) {
    config.headers.authorization = token
  }
  return config
}
const resInterceptor = function(res) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  const code = res?.data?.code
  // 兼容json文件
  const responseURL = res?.request?.responseURL
  if (
    responseURL &&
    (responseURL.indexOf('.json') > -1 || responseURL.indexOf('.js') > -1)
  ) {
    return res.data
  }
  if (parseInt(code) === 610001) { // 登录时账号冻结单独处理
    return res.data
  } else if (parseInt(code) === 400) {
    // clearLoginInfo()
  } else if (parseInt(code) !== 200) {
    return res.data
  }
  return res.data
}

// Add a request interceptor
axios.interceptors.request.use(reqInterceptor, function(error) {
  return Promise.reject(error)
})

// Add a response interceptor
axios.interceptors.response.use(resInterceptor, function(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const { data, status } = error.response
    const token = getStorageSync('accessToken')
    const requestWhiteStatus = requestWhiteList.some(item => error.config.url.includes(item))
    if (!requestWhiteStatus && String(status) === '401') {
      localStorage.removeItem('token')
      const hasLoginPage = window.location.href.includes('/login')
      if (!hasLoginPage) {
        location.replace('/login?redirect=' + encodeURIComponent(location.href))
      }
    }
    return Promise.reject(data)
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // window.Message(error.message);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message)
    // window.Message(error.message);
  }
  return Promise.reject(error)
})
