<template>
  <!-- Tab切换 -->
  <div class="root tabs">
    <div class="content">
      <div class="tabs">
        <span v-for="(item, index) in list" :key="index" class="tabs-list" @mouseenter="mouseenterHandler(item, $event)" @mouseleave="mouseleaveHandler(item, $event)" @click="e => tabsChang(item, e)">
          <span v-if="item.tabType === 2" :class="['tabs-item', { 'selectde-tab': item.id === selectedTab , 'tabs-speed': item.name === '飞速下单'}]">
            {{ item.name }}
            <Icon v-if="item.type === 'commodity_classify'" type="ios-arrow-down" />
          </span>
          <img v-else :src="getFilePath('orig', item.bgImage)" alt="">
          <img v-if="item.isShowIcon" :class="{ 'icon-direction': item.isShowIcon }" width="6px" height="10px" src="@/assets/images/common/icon-direction.png">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getFilePath } from '@/common/utils/base'
export default {
  name: 'PTabs',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    selectedType: {
      type: String,
      default: ''
    },
    defaultJump: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedTab: this.defaultJump ? 0 : null,
      getFilePath
    }
  },
  watch: {
    list: {
      handler(list) {
        this.formatData(list)
        // eslint-disable-next-line eqeqeq
        const selectItem = list?.find(item => item.type == this.selectedType)
        // eslint-disable-next-line eqeqeq
        if (selectItem && this.selectedTab != selectItem.id) {
          this.selectedTab = selectItem.id
        } else {
          if (this.defaultJump) {
            this.tabsChang(list.filter(item => item.type === 'home' || item.name === '首页')[0])
          }
        }
      },
      deep: true
    },
    selectedType: {
      handler() {
        // eslint-disable-next-line eqeqeq
        const selectItem = this.list?.find(item => item.type == this.selectedType)
        // eslint-disable-next-line eqeqeq
        if (selectItem && this.selectedTab != selectItem.id) {
          this.selectedTab = selectItem.id
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations({
      setShowShopClassify: 'product/SET_SHOW_SHOP_CLASSIFY'
    }),
    tabsChang(item, e) {
      if (!item) return
      this.selectedTab = item.id
      if (item.type !== 'commodity_classify') {
        this.setShowShopClassify(false)
      }
      if (item.type === 'allGoods') {
        this.$router.push('/search/list?type=homeShop')
      }
      // eslint-disable-next-line eqeqeq
      if (item.type === 'home' && this.$route.name != 'shopHome' && e) {
        this.$router.push('/')
      }
      if (item.type === 'groupCenter') {
        this.$router.push('/activity/groupCenter')
      }
      if (item.type === 'addGiftCenter') {
        this.$router.push('/selectGift')
      }
      if (item.type === 'allOrgs') {
        this.$router.push('/shop-all')
      }
      this.$emit('change', item, e)
      // eslint-disable-next-line eqeqeq
      if (item.type == 'couponCenter') {
        this.$router.push('/home/coupon')
      }
    },
    formatData(list) {
      list?.forEach(item => {
        if (!item.tabType) {
          item.tabType = item.type
          item.name = item.text
          if (!item.id) {
            item.id = Symbol('')
          }
        }
      })
    },
    mouseenterHandler(item, e) {
      this.$emit('mouseenterHandler', item, e)
    },
    mouseleaveHandler(item, e) {
      this.$emit('mouseleaveHandler', item, e)
    }
  }
}
</script>

<style lang="less" scoped>
.root {
  width: 100%;
  background-color: #fff;
  margin-bottom: 16px;
  .content {
    width: 1200px;
    padding: 12px 0;
    height: 100%;
    margin: 0 auto;
    position: relative;
    .tabs {
      width: auto;
      height: 22px;
      display: flex;
      .tabs-list {
        cursor: pointer;
        display: inline-block;
        // border-right: 2px #EEEEEE solid;
        height: 22px;
        position: relative;
        align-items: center;
        display: flex;
        .tabs-item {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.9);
          margin-right: 40px;
          align-items: center;
        }
        .tabs-item:hover {
          color: #ce7720;
          font-weight: bold;
        }
        .tabs-speed {
          font-weight: 600 !important;
          background-image: linear-gradient(180deg, #FF9900 0%, #FF4004 100%);
          color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .icon-direction {
          transform: rotate(90deg);
          margin: 0 8px 0 -4px;
        }
        img {
          max-width: 100%;
          max-height: 24px;
          margin: 0 8px !important;
          // display: inline-block;
          padding: 0 !important;
        }
      }
      // .tabs-list:last-child {
      //     border: none !important;
      // }
      .selectde-tab {
        color: #ce7720 !important;
        font-weight: bold;
      }
    }
  }
}
</style>
